import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
} from "@ionic/react";
import {
  calendarOutline,
  desktopOutline,
  personCircle,
  personOutline,
} from "ionicons/icons";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";

import { Storage } from "@ionic/storage";

import Auth from "./../services/auth.service";

const Startup: React.FC = () => {
  const storage = new Storage();
  const { setState, state } = useGlobalState();

  const ionPopoverRef = useRef<HTMLIonPopoverElement>(null);

  useEffect(() => {
    async function CreateStorage() {
      await storage.create();
    }
    async function GetToken() {
      await CreateStorage();

      const token = await storage.get("token");

      // if (Boolean(token)) {
      //   setState((prev) => ({
      //     ...prev,
      //     ...{
      //       token: token,
      //       logged: true,
      //       firstname: "Imię Nazwisko",
      //       lastname: "Nazwisko",
      //     },
      //   }));
      // } else {
      //   setState((prev) => ({
      //     ...prev,
      //     ...{ token: undefined, logged: false },
      //   }));
      // }
    }

    GetToken();
  }, []);

  const assingUser = async () => {

    const user = await Auth.getCurrentUser();

      setState((prev) => ({
        ...prev,
        ...{
          role: user?.role,
        },
      }));

  }

  useEffect(() => {

    assingUser();
    
  }, [])


  return <></>;
};

export default Startup;
