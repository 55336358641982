import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Input, localePl, Popup, setOptions } from "@mobiscroll/react";
import {
  Box,
  debounce,
  Grid,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

type Props = {
  step?: number;
  min?: number;
  max?: number;
  setMin?: React.Dispatch<React.SetStateAction<number>>;
  setMax?: React.Dispatch<React.SetStateAction<number>>;
};

const RangeSlider: React.FC<Props> = ({
  setMin,
  setMax,
  step = 1,
  min = 0,
  max = 100,
}) => {
  const [value, setValue] = React.useState<number[]>([min, max]);

  useEffect(() => {
    setValue([min, max]);
  }, [min, max]);

  const handleChangeUpdate = useMemo(() => {
    return debounce((newValue) => {
      const _newValue = newValue as number[];
      if (setMin) {
        setMin(_newValue[0]);
      }
      if (setMax) {
        setMax(_newValue[1]);
      }
    }, 500);
  }, []);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const _newValue = newValue as number[];
    setValue(_newValue);
    handleChangeUpdate(newValue);
  };

  const handleInputChange0 = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = value;
    val[0] = event.target.value === "" ? 0 : Number(event.target.value);

    setValue(val);
    if (setMin) {
      setMin(val[0]);
    }
  };

  const handleInputChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = value;
    val[1] = event.target.value === "" ? 0 : Number(event.target.value);

    setValue(val);
    if (setMax) {
      setMax(val[1]);
    }
  };

  const handleBlur = () => {
    if (value[0] < min) {
      let val = value;
      val[0] = min;
      setValue(val);
    } else if (value[0] > max) {
      let val = value;
      val[0] = max;
      setValue(val);
    }
  };

  return (
    <Box
      sx={{
        padding: "10px 20px",
      }}
    >
      {" "}
      <Typography id="input-slider" gutterBottom>
        Koszt kary
      </Typography>
      <IonRow>
        <IonCol size="12">
          <Slider
            max={max}
            min={min}
            value={value}
            onChange={handleChange}
            aria-labelledby="input-slider"
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="5">
          <TextField
            fullWidth
            value={value[0].toString()}
            size="small"
            onChange={handleInputChange0}
            onBlur={handleBlur}
            inputProps={{
              step: step,
              min: min,
              max: max,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </IonCol>
        <IonCol>
          <div
            style={{
              borderTop: "3px solid #c7c7c7",
              borderRadius: "10px",
              marginTop: "calc(50%)",
              marginLeft: "4px",
              marginRight: "4px",
            }}
          ></div>
        </IonCol>
        <IonCol size="5">
          <TextField
            fullWidth
            value={value[1].toString()}
            size="small"
            onChange={handleInputChange1}
            onBlur={handleBlur}
            inputProps={{
              step: step,
              min: min,
              max: max,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </IonCol>
      </IonRow>
      {/* <Grid container spacing={2} alignItems="center">
        <Grid item></Grid>
        <Grid item xs></Grid>
        <Grid item></Grid>
      </Grid> */}
    </Box>
  );
};

export default RangeSlider;
