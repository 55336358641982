import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonPage,
  IonPopover,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import "./theme/Darek.scss";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "./ionicCSS/padding.css";
// import "./ionicCSS/float-elements.css";
// import "./ionicCSS/text-alignment.css";
// import "./ionicCSS/text-transformation.css";
// import "./ionicCSS/flex-utils.css";
// import "./ionicCSS/display.css";

/* Theme variables */
import "./theme/variables.css";
//import "./theme/dashlite.scss";
import "./theme/fonts.css";
// import "./theme/font-awesome.min.css";
import "./font-awesome/css/all.min.css";

import "./theme/animate.scss";

import "./Global.css";
import "./GlobalC.css";
import "./Global.scss";
import "./Global_desktop.css";
import "./Global_mobile.css";
import "./Global_mobile.scss";

import "./dashlite/dashlite.css";

import Home from "./pages/Home";
import Drivers from "./pages/Drivers";
import RoutePage from "./pages/Route";
import SingleRoute from "./pages/SingleRoute";

import Photos from "./pages/Photos";
import Reports from "./pages/Reports";
import DriverUsers from "./pages/DriverUsers";

import { Roles } from "./components/Roles";

//import { SignalR } from "./components/Common";

import { setupIonicReact } from "@ionic/react";

import logo from "./zlecenia_svg/logo.svg";

import Startup from "./components/Startup";
import CsvList from "./components/CsvList";
import { createContext, useContext, useMemo, useState } from "react";
import {
  createTheme,
  PaletteMode,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import Mode from "./theme/Mode";

import { analytics } from "ionicons/icons";
import Analytics from "./pages/Analytics";
import Payments from "./pages/Payments";
import IssueDiet from "./pages/IssueDiet";

setupIonicReact({
  mode: "md",
});

const ColorModeContext = createContext({ toggleColorMode: () => {} });

type MyAppProps = {
  setMode: React.Dispatch<React.SetStateAction<PaletteMode>>;
};

const MyApp: React.FC<MyAppProps> = ({ setMode }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  return (
    <>
      <Mode setMode={setMode} />
      <IonApp>
        <IonReactRouter>
          <BrowserRouter
          // id="main"
          // animated={isPlatform("mobile")}
          // animation={isPlatform("mobile") ? mobileAnimation : undefined}
          // mode={"md"}
          >
            <Route path="/login" component={Home} exact={true} />

            <Route path="/" component={Drivers} exact={true} />

            <Route path="/DriverUsers" component={DriverUsers} exact={true} />

            <Route path="/Photos" component={Photos} exact={true} />

            <Route path="/Route/:userId" component={RoutePage} exact={true} />
            <Route
              path="/Route/:userId/:routeId"
              component={SingleRoute}
              exact={true}
            />

            <Route path="/Reports" component={Reports} exact={true} />
            <Route path="/Analytics" component={Analytics} exact={true} />

            <Route path="/Payments" component={Payments} exact={true} />
            <Route path="/IssueDiets" component={IssueDiet} exact={true} />
          </BrowserRouter>
        </IonReactRouter>

        <Startup />
      </IonApp>
    </>
  );
};

const App: React.FC = () => {
  const [mode, setMode] = useState<PaletteMode>("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <MyApp setMode={setMode} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
