import { PaletteMode } from "@mui/material";
import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";

export interface GlobalStateInterface {
  hideMenu: boolean;

  firstname: string;
  lastname: string;
  age: string;
  logged: Boolean;
  token: string;

  role: "Admin" | "User" | "Driver" | "Office" | "Suboffice" | "CustomMariusz" | "GStatsAdmin" | "LStatsClient" | "Viewer" | "CustomSzczecin";

  cart: [
    {
      category: string;
      name: string;
      price: string;
    }
  ];

  listInterval: NodeJS.Timeout;

  mode: PaletteMode;
}

const GlobalStateContext = createContext({
  state: {} as Partial<GlobalStateInterface>,
  setState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>,
});

const GlobalStateProvider = ({
  children,
  value = {} as GlobalStateInterface,
}: {
  children: React.ReactNode;
  value?: Partial<GlobalStateInterface>;
}) => {
  const [state, setState] = useState(value);
  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context;
};

export { GlobalStateProvider, useGlobalState };
