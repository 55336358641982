import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  IconButton,
  Box,
  List,
  ListItem,
  Popover,
  Typography,
  debounce,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";

import {
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  useIonAlert,
  useIonLoading,
  useIonViewWillEnter,
} from "@ionic/react";
import { Container } from "@mui/system";

import api from "./../../services/api";
import { useEffect, useMemo, useRef, useState } from "react";
import DriversAutocomplete from "./DriversAutocomplete";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  chatboxEllipses,
  closeOutline,
  createOutline,
  trash,
} from "ionicons/icons";
import Add from "@mui/icons-material/Add";
import PunishmentsAutocomplete from "./PunishmentsAutocomplete";
import DriverUsersAutocomplete from "./DriverUsersAutocomplete";
import RoutesAutocomplete from "./RoutesAutocomplete";
import RouteAddressesAutocomplete, {
  DriverDataResponse,
} from "./RouteAddressesAutocomplete";

import { NumericFormat } from "react-number-format";
import DeliveryDate from "./DeliveryDate";
import { NumberToMoneyString } from "../Common";
import { User } from "../../services/userProps";

import Auth from "./../../services/auth.service";

import EditIcon from "@mui/icons-material/Edit";

import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {
  GridColDef,
  gridExpandedRowCountSelector,
  GridFilterModel,
  GridLogicOperator,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

import { LoadingButton } from "@mui/lab";

import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import DriverNameAutocomplete from "../DriverNameAutocomplete";
import PunishmentCostAutocomplete from "../PunishmentCostAutocomplete";
import PunishmentTitleSelect from "../PunishmentTitleSelect";
import PunishmentDescriptionTextfield from "../PunishmentDescriptionTextfield";
import PunishmentAddressAutocomplete from "../PunishmentAddressAutocomplete";
import PunishmentCommentTextfield from "../PunishmentCommentTextfield";
import PunishmentAddedByAutocomplete from "../PunishmentAddedByAutocomplete";
import PunishmentConfirmationsSelect from "../PunishmentConfirmationSelect";
import PunishmentConfirmationSelect from "../PunishmentConfirmationSelect";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
import RangeSlider from "../RangeSlider";

import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import { group } from "console";
import DateOfIncident from "./DateOfIncident";
import DescriptionTextField from "./DescriptionTextField";
import AddNoteForm from "./AddNoteForm";

type DriversNotesTableCommentProps = {
  id: number;
  addDate: string;
  description: string;
  userAddedBy: string;
};

type DriversNotesTableConfirmationProps = {
  id: number;
  name: string;
  confirmed: boolean;
  roleId: number;
  userFirstname: string;
};

type DriversNotesTableProps = {
  id: number;
  userDriverId: string;
  userDriver: string;
  routeAddress?: DriverDataResponse;
  confirmations: DriversNotesTableConfirmationProps[];
  punishmentCost: number;
  isEditablePunishmentCost: boolean;
  editPunishmentCost: number;
  description: string;
  title: string;
  date: string;
  dateOfIncident: string | undefined;
  userCreatedBy: string;
  comments: DriversNotesTableCommentProps;

  noteAssignmentId: 1 | 2 | 3;
  noteAssignmentName: string;
};

type ContainerProps = {
  driverName: string;
};

const Main: React.FC = () => {
  const [_rows, _setRows] = useState<DriversNotesTableProps[]>([]);
  const [rows, setRows] = useState<DriversNotesTableProps[]>([]);

  const [popoverComment, setPopoverComment] = useState("");

  const [containerKey, setContainerKey] = useState("1");

  const columns: GridColDef[] = [
    {
      field: "userDriver",
      headerName: "Kierowca",
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "punishmentCost",
      type: "number",
    },
    {
      field: "noteAssignmentName",
      headerName: "Typ",
      renderCell: (params: GridRenderCellParams<any>) => {
        let textColor = "red";

        if (params.row.noteAssignmentId == 2) {
          textColor = "green";
        } else if (params.row.noteAssignmentId == 3) {
          textColor = "blue";
        }

        return (
          <>
            <span style={{ fontWeight: 600, color: textColor }}>
              {params.row.noteAssignmentName}
            </span>
          </>
        );
      },
    },
    {
      field: "punishmentCostField",
      headerName: "Koszt",
      minWidth: 100,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        let textColor = "red";

        if (params.row.noteAssignmentId == 2) {
          textColor = "green";
        } else if (params.row.noteAssignmentId == 3) {
          textColor = "blue";
        }

        if (params.row.noteAssignmentId == 3) {
          return <></>;
        }

        return (
          <>
            <span style={{ color: textColor }}>
              {NumberToMoneyString(params.row.punishmentCost)}
            </span>

            <>
              {params.row.isEditablePunishmentCost ? (
                <></>
              ) : (
                params.row.confirmations.map(
                  (k: DriversNotesTableConfirmationProps) => {
                    return k.roleId == user?.confirmationRole ? (
                      <PopupState
                        variant="popover"
                        popupId="cost-popup-popover"
                      >
                        {(popupState) => (
                          <div>
                            <IconButton
                              {...bindTrigger(popupState)}
                              className="show-on-hover"
                              // onClick={() => {
                              //   setRows(
                              //     rows.map((e) =>
                              //       e.id == params.row.id
                              //         ? { ...e, isEditablePunishmentCost: true }
                              //         : e
                              //     )
                              //   );
                              // }}
                              color="primary"
                              aria-label="change punishment cost"
                              component="label"
                            >
                              <EditIcon />
                            </IconButton>
                            <Popover
                              style={{
                                padding: "30px",
                              }}
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "left",
                              }}
                            >
                              <div
                                style={{
                                  margin: "15px",
                                  display: "inline-flex",
                                }}
                              >
                                <NumericFormat
                                  InputProps={{
                                    endAdornment: <span>zł</span>,
                                  }}
                                  label="Koszt kary"
                                  customInput={TextField}
                                  onValueChange={(values) => {
                                    const floatValue = values.floatValue;

                                    if (floatValue != undefined) {
                                      setRows(
                                        rows.map((e) =>
                                          e.id == params.row.id
                                            ? {
                                                ...e,
                                                editPunishmentCost: floatValue,
                                              }
                                            : e
                                        )
                                      );
                                    }
                                  }}
                                  value={params.row.punishmentCost}
                                  variant="outlined"
                                  decimalScale={2}
                                  fixedDecimalScale
                                />
                                <div style={{ margin: "auto" }}>
                                  <IconButton
                                    onClick={() => {
                                      // setRows(
                                      //   rows.map((e) =>
                                      //     e.id == params.row.id
                                      //       ? { ...e, isEditablePunishmentCost: false }
                                      //       : e
                                      //   )
                                      // );
                                      popupState.close();
                                    }}
                                    color="error"
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      presentLoading();

                                      api
                                        .patch(
                                          "stats/driver/note/" +
                                            params.row.id +
                                            "/punishment-cost",
                                          {
                                            cost: params.row.editPunishmentCost,
                                          }
                                        )
                                        .then((response) => {
                                          api
                                            .get(
                                              "stats/drivers/notes"
                                              // {
                                              //   params: {
                                              //     DriverId: ""
                                              //   },
                                              // }
                                            )
                                            .then((response) => {
                                              _setRows(response.data);
                                            })
                                            .finally(async () => {
                                              // setRows(
                                              //   rows.map((e) =>
                                              //     e.id == params.row.id
                                              //       ? { ...e, isEditablePunishmentCost: false }
                                              //       : e
                                              //   )
                                              // );

                                              popupState.close();

                                              await dismissLoading();
                                            });
                                        })
                                        .catch(async () => {
                                          setRows(
                                            rows.map((e) =>
                                              e.id == params.row.id
                                                ? {
                                                    ...e,
                                                    isEditablePunishmentCost:
                                                      false,
                                                  }
                                                : e
                                            )
                                          );

                                          await dismissLoading();
                                        });
                                    }}
                                    color="success"
                                  >
                                    <DoneIcon />
                                  </IconButton>
                                </div>
                              </div>
                            </Popover>
                          </div>
                        )}
                      </PopupState>
                    ) : (
                      <></>
                    );
                  }
                )
              )}
            </>
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "Tytuł",
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Opis",
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "17px",
              padding: "8px 0",
            }}
          >
            {params.row.description}
          </Box>
        );
      },
    },
    {
      field: "comments",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.comments.length > 0
          ? params.row.comments.map(
              (e: DriversNotesTableCommentProps) => e.description + " "
            )
          : "",
    },
    {
      field: "commentsField",
      headerName: "Komentarze",
      minWidth: 300,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <List sx={{ listStyleType: "disc" }}>
            {params.row.comments.map((k: DriversNotesTableCommentProps) => {
              return (
                <ListItem style={{ padding: "3px" }}>
                  <div>
                    {k.description}{" "}
                    <span style={{ fontStyle: "italic", marginLeft: "3px" }}>
                      <strong>{k.userAddedBy}</strong> o {k.addDate}
                    </span>
                  </div>
                </ListItem>
              );
            })}
          </List>
        );
      },
    },
    {
      field: "address",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.routeAddress?.street +
        " " +
        params.row.routeAddress?.houseNumber,
    },
    {
      field: "addressField",
      headerName: "Adres dostawy",
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "text-center",
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <div
            style={{
              padding: "8px 0",
            }}
          >
            {params.row.routeAddress ? (
              <div>
                <div>
                  {params.row.routeAddress?.postCode}{" "}
                  {params.row.routeAddress?.city}
                  <br />
                  {params.row.routeAddress?.street}{" "}
                  {params.row.routeAddress?.houseNumber}
                </div>
                <div
                  style={{
                    marginRight: "5px",
                  }}
                >
                  {params.row.routeAddress.image ? (
                    <IconButton
                      style={{
                        padding: "3px",
                      }}
                      color="primary"
                      aria-label="pokaż zdjęcie"
                      component="span"
                      onClick={() => {
                        setModalImage(params.row.routeAddress?.image as string);
                        setShowModalImage(true);
                      }}
                    >
                      <PhotoCamera />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <>-</>
            )}
          </div>
        );
      },
    },
    {
      field: "userCreatedBy",
    },
    {
      field: "date",
    },
    {
      field: "addedField",
      headerName: "Dodano",
      minWidth: 100,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "text-center",
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <div>
            <div>{params.row.date}</div>
            <div>
              przez{" "}
              <span style={{ fontWeight: "500" }}>
                {params.row.userCreatedBy}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: "dateOfIncident",
      headerName: "Zdarzenie",
      minWidth: 100,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "text-center",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "isConfirmed",
      type: "boolean",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.confirmations.every(
          (e: DriversNotesTableConfirmationProps) => e.confirmed
        ),
    },
    {
      field: "confirmations",
      headerName: "Potwierdzenia",
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "text-center",
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <List sx={{ listStyleType: "disc" }}>
            {params.row.confirmations.map(
              (k: DriversNotesTableConfirmationProps) => {
                return (
                  <ListItem style={{ padding: "3px" }}>
                    <div
                      className={"text-" + (k.confirmed ? "success" : "danger")}
                      // style={{ marginBottom: "10px" }}
                    >
                      {k.name} {k.confirmed ? k.userFirstname : ""}
                    </div>
                  </ListItem>
                );
              }
            )}
          </List>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <div>
            {params.row.confirmations.map(
              (k: DriversNotesTableConfirmationProps) => {
                return k.roleId == user?.confirmationRole && !k.confirmed ? (
                  <Button
                    onClick={() => {
                      presentLoading();

                      api
                        .patch(
                          "stats/driver/note/" + params.row.id + "/confirm"
                        )
                        .then((response) => {
                          api
                            .get("stats/drivers/notes")
                            .then((response) => {
                              _setRows(response.data);
                            })
                            .finally(() => {
                              dismissLoading();
                            });
                        })
                        .catch(() => {
                          dismissLoading();
                        });
                    }}
                    style={{
                      display: "block",
                      margin: "auto",
                      marginBottom: "5px",
                    }}
                  >
                    Zatwierdź
                  </Button>
                ) : (
                  <></>
                );
              }
            )}

            <div
              style={{
                marginBottom: "10px",
                display: "inline-flex",
              }}
            >
              {params.row.confirmations.map(
                (k: DriversNotesTableConfirmationProps) => {
                  return k.roleId == user?.confirmationRole ? (
                    <Button
                      style={{
                        minWidth: "10px",
                        height: "28px",
                        display: "block",
                        margin: "auto",
                        marginBottom: "5px",
                      }}
                      color="error"
                      variant="contained"
                      onClick={() => {
                        presentAlert({
                          // header: "Alert",
                          subHeader: "Czy na pewno chcesz usunąć tę uwagę?",
                          // message: "This is an alert!",
                          buttons: [
                            {
                              text: "Anuluj",
                              role: "cancel",
                              handler: () => {
                                setHandlerMessage("Alert canceled");
                              },
                            },
                            {
                              text: "Usuń",
                              role: "confirm",
                              handler: () => {
                                presentLoading();

                                api
                                  .delete("stats/driver/note/" + params.row.id)
                                  .then((response) => {
                                    api
                                      .get(
                                        "stats/drivers/notes"
                                        // {
                                        //   params: {
                                        //     DriverId: ""
                                        //   },
                                        // }
                                      )
                                      .then((response) => {
                                        _setRows(response.data);
                                      })
                                      .finally(() => {
                                        dismissLoading();
                                      });
                                  })
                                  .catch(() => {
                                    dismissLoading();
                                  });
                              },
                            },
                          ],
                        });
                      }}
                    >
                      <IonIcon src={trash} />
                    </Button>
                  ) : (
                    <></>
                  );
                }
              )}

              <PopupState variant="popover" popupId="comment-popup-popover">
                {(popupState) => (
                  <div>
                    <Button
                      {...bindTrigger(popupState)}
                      style={{
                        minWidth: "10px",
                        height: "28px",
                        display: "block",
                        marginLeft: "10px",
                      }}
                      color="primary"
                      variant="contained"
                    >
                      <IonIcon src={chatboxEllipses} />
                    </Button>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      {/* <Typography sx={{ p: 2 }}>
                        The content of the Popover.
                      </Typography> */}
                      <div
                        style={{
                          margin: "15px",
                          minHeight: "300px",
                        }}
                      >
                        <IonRow>
                          <IonCol size="12">
                            <Typography variant="h6" gutterBottom>
                              Komentarz dla <span>{params.row.userDriver}</span>
                            </Typography>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="12">
                            <TextField
                              fullWidth
                              multiline
                              rows={2}
                              label="Komentarz"
                              value={popoverComment}
                              onChange={(event) =>
                                setPopoverComment(event.target.value!)
                              }
                            />
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                          <IonCol size="12">
                            <Button
                              disabled={!popoverComment}
                              style={{
                                margin: "auto",
                                display: "block",
                              }}
                              variant="contained"
                              onClick={async () => {
                                await presentLoading();

                                api
                                  .patch(
                                    "stats/driver/note/" +
                                      params.row.id +
                                      "/add-comment",
                                    {
                                      comment: popoverComment,
                                    }
                                  )
                                  .then(async (response) => {
                                    setPopoverComment("");
                                    popupState.close();

                                    await getData();

                                    await dismissLoading();
                                  })
                                  .catch(async () => {
                                    await dismissLoading();
                                  });
                              }}
                            >
                              Dodaj
                            </Button>
                          </IonCol>
                        </IonRow>
                      </div>
                    </Popover>
                  </div>
                )}
              </PopupState>
            </div>
          </div>
        );
      },
    },
  ];

  const [date, setDate] = useState<string>();

  const [availableDays, setAvailableDays] = useState<string[]>();

  const [presentLoading, dismissLoading] = useIonLoading();

  const [presentAlert] = useIonAlert();

  const [handlerMessage, setHandlerMessage] = useState("");

  const [dateOfIncident, setDateOfIncident] = useState("");

  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (!user) {
      const _setUser = async () => {
        const user = await Auth.getCurrentUser();
        setUser(user);
      };
      _setUser();
    }
  }, []);

  const getData = async () => {
    await presentLoading();

    api
      .get(
        "stats/drivers/notes"
        // {
        //   params: {
        //     DriverId: ""
        //   },
        // }
      )
      .then((response) => {
        _setRows(response.data);

        const data = response.data as DriversNotesTableProps[] | undefined;

        if (data) {
          const maxValue = Math.max(...data.map((o) => o.punishmentCost));

          _setPunishmentCostMax(maxValue);
          setPunishmentCostMax(maxValue);
        }
      })
      .finally(async () => {
        await dismissLoading();
      });
  };

  useEffect(() => {
    getData();
  }, [date]);

  useEffect(() => {
    if (!availableDays) {
      api.get("stats/drivers/notes/available-days").then((response) => {
        setAvailableDays(response.data);
      });
    }
  }, []);

  const [showOrderPhoto, setShowOrderPhoto] = useState(false);
  const [orderImage, setOrderImage] = useState("");

  const [search, setSearch] = useState("");

  const [modalImage, setModalImage] = useState<string>("");
  const [showModalImage, setShowModalImage] = useState(false);

  useEffect(() => {
    let tempRows = _rows;

    // const lowerSearch = search.toLowerCase();

    // if (search) {
    //   tempRows = tempRows.filter(
    //     (e) =>
    //     e.region.toLowerCase().includes(lowerSearch) ||
    //     e.postCode.toLowerCase().includes(lowerSearch) ||
    //     (e.street + " " + e.houseNumber).toLowerCase().includes(lowerSearch) ||
    //     e.diets.some(s => s.name.toLowerCase().includes(lowerSearch))
    //   );
    // }

    setRows(tempRows);
  }, [search, _rows]);

  const calendarRef = useRef<HTMLIonDatetimeElement>(null);

  type AnalyticsReportResponse = {
    allDeliveries: number;
    allDeliveriesSameAddress: number;
    allDeliveriesDone: number;
    allDeliveriesUndelivered: number;
    routesAddresses: AnalyticsReportAddressResponse[];
  };

  type AnalyticsReportAddressResponse = {
    id: string;
    deliveries: AnalyticsReportAddressDataResponse[];
    street: string;
    houseNumber: string;
    city: string;
    region: string;
    postCode: string;
    count: number;
    deliveryDoneCount: number;
    undeliveryCount: number;
  };

  type AnalyticsReportAddressDataResponse = {
    id: number;
    // deliveryDate: string;
    imageCreated: boolean;
  };

  const [driverNames, setDriverNames] = useState<string[]>([]);
  const [punishmentCosts, setPunishmentCosts] = useState<string[]>([]);
  const [punishmentTitles, setPunishmentTitles] = useState<string[]>([]);
  const [punishmentConfirmation, setPunishmentConfirmation] =
    useState<string>("Wszystkie");
  const [punishmentDescription, setPunishmentDescription] =
    useState<string>("");
  const [punishmentComment, setPunishmentComment] = useState<string>("");
  const [punishmentAddresses, setPunishmentAddresses] = useState<string[]>([]);
  const [punishmentAddedBys, setPunishmentAddedBys] = useState<string[]>([]);

  const [punishmentCostMin, setPunishmentCostMin] = useState(0);
  const [punishmentCostMax, setPunishmentCostMax] = useState(0);

  const [_punishmentCostMin, _setPunishmentCostMin] = useState(0);
  const [_punishmentCostMax, _setPunishmentCostMax] = useState(0);

  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });

  const apiRef = useGridApiRef();

  const [datagridVisibleItemsCount, setDatagridVisibleItemsCount] = useState(0);
  const [isExcelExportButtonLoading, setIsExcelExportButtonLoading] =
    useState(false);

  useEffect(() => {
    if (apiRef.current) {
      setDatagridVisibleItemsCount(gridExpandedRowCountSelector(apiRef));
    }
  }, [rows, filterModel?.items]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (driverNames.length > 0) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("userDriver")
        );

        tempFilterModelItems.push({
          id: "userDriver",
          field: "userDriver",
          operator: "isAnyOf",
          value: driverNames,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("userDriver")
          ),
        });
      }
    }
  }, [driverNames]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (punishmentTitles.length > 0) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("punishmentTitle")
        );

        tempFilterModelItems.push({
          id: "punishmentTitle",
          field: "title",
          operator: "isAnyOf",
          value: punishmentTitles,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("punishmentTitle")
          ),
        });
      }
    }
  }, [punishmentTitles]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (punishmentDescription) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("punishmentDescription")
        );

        tempFilterModelItems.push({
          id: "punishmentDescription",
          field: "description",
          operator: "contains",
          value: punishmentDescription,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("punishmentDescription")
          ),
        });
      }
    }
  }, [punishmentDescription]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (punishmentComment) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("punishmentComment")
        );

        tempFilterModelItems.push({
          id: "punishmentComment",
          field: "comments",
          operator: "contains",
          value: punishmentComment,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("punishmentComment")
          ),
        });
      }
    }
  }, [punishmentComment]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (punishmentConfirmation == "Wszystkie") {
        console.log("Wszystkie");
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("punishmentConfirmation")
          ),
        });
      } else if (punishmentConfirmation == "Potwierdzone") {
        console.log("Potwierdzone");
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("punishmentConfirmation")
        );

        tempFilterModelItems.push({
          id: "punishmentConfirmation",
          field: "isConfirmed",
          operator: "is",
          value: "true",
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else if (punishmentConfirmation == "Brak potwierdzenia") {
        console.log("Brak potwierdzenia");
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("punishmentConfirmation")
        );

        tempFilterModelItems.push({
          id: "punishmentConfirmation",
          field: "isConfirmed",
          operator: "is",
          value: "false",
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        console.log("else");
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("punishmentConfirmation")
          ),
        });
      }
    }
  }, [punishmentConfirmation]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (punishmentAddresses.length > 0) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("punishmentAddress")
        );

        tempFilterModelItems.push({
          id: "punishmentAddress",
          field: "address",
          operator: "isAnyOf",
          value: punishmentAddresses,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("punishmentAddress")
          ),
        });
      }
    }
  }, [punishmentAddresses]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (punishmentAddedBys.length > 0) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("punishmentAddedBy")
        );

        tempFilterModelItems.push({
          id: "punishmentAddedBy",
          field: "userCreatedBy",
          operator: "isAnyOf",
          value: punishmentAddedBys,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("punishmentAddedBy")
          ),
        });
      }
    }
  }, [punishmentAddedBys]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      let tempFilterModelItems = filterModel.items.filter(
        (e) => !(e.id as string).startsWith("punishmentCostMin")
      );

      tempFilterModelItems.push({
        id: "punishmentCostMin",
        field: "punishmentCost",
        operator: ">=",
        value: punishmentCostMin,
      });

      console.log("punishmentCostMin");
      console.log(punishmentCostMin);

      setFilterModel({
        ...filterModel,
        items: tempFilterModelItems,
      });
    }
  }, [punishmentCostMin]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      let tempFilterModelItems = filterModel.items.filter(
        (e) => !(e.id as string).startsWith("punishmentCostMax")
      );

      tempFilterModelItems.push({
        id: "punishmentCostMax",
        field: "punishmentCost",
        operator: "<=",
        value: punishmentCostMax,
      });

      setFilterModel({
        ...filterModel,
        items: tempFilterModelItems,
      });
    }
  }, [punishmentCostMax]);

  const [filterBarChartData, setFilterBarChartData] = useState<any>([
    {
      name: "Revenue",
      data: [
        76, 85, 101, 98, 87, 105, 91, 114, 94, 85, 101, 98, 87, 105, 91, 114,
        94, 85, 101, 98, 87, 105, 91, 114, 94, 85, 101, 98, 87, 105, 91, 114,
        94, 85, 101, 98, 87, 105, 91, 114, 94,
      ],
    },
  ]);

  // useEffect(() => {
  //   if (rows) {
  //     let tempRows: DriversNotesTableProps[] = [];

  //     for (const n of rows) {
  //       let elementIndex = tempRows.findIndex(
  //         (e) => e.userDriverId == n.userDriverId
  //       );

  //       if (elementIndex >= 0) {
  //         tempRows[elementIndex].punishmentCost += n.punishmentCost;
  //       } else {
  //         tempRows.push(n);
  //       }

  //       console.log(tempRows);
  //     }
  //   }
  // }, [rows]);

  const barChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        // endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: rows
        .map((e) => e.userDriver)
        .filter((value, index, array) => array.indexOf(value) === index)
        .slice(0, filterBarChartData[0].data.length),
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  };

  const [filterDonutChartData, setFilterDonutChartData] = useState<any>([
    44, 55, 41, 17, 15,
  ]);

  const donutChartOptions: ApexCharts.ApexOptions = {
    chart: {
      width: 380,
      // height: 430,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    title: {
      text: "Gradient Donut with custom Start-angle",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <Container maxWidth="xl" style={{ marginTop: "15px" }}>
      <IonModal
        className="modal-image"
        isOpen={showOrderPhoto}
        onIonModalDidDismiss={() => setShowOrderPhoto(false)}
      >
        <IonFab vertical="top" horizontal="end" slot="fixed">
          <IonFabButton
            onClick={() => {
              setShowOrderPhoto(false);
            }}
            color="danger"
          >
            <IonIcon icon={closeOutline} />
          </IonFabButton>
        </IonFab>
        <IonImg src={orderImage} />
      </IonModal>

      <AddNoteForm
        key={containerKey}
        onFinally={() => {

          setContainerKey(containerKey + "1");

          api
            .get(
              "stats/drivers/notes"
              // {
              //   params: {
              //     DriverId: ""
              //   },
              // }
            )
            .then((response) => {
              _setRows(response.data);
            })
            .finally(async () => {
              await dismissLoading();
            });
        }}
      />

      {/* <IonRow className="ion-justify-content-between" key={containerKey}> */}

      {/* <IonCol size="12" sizeMd="4">
          <IonRow className="ion-justify-content-center">
            <IonCol size="8">
              <Button
                disabled={!date}
                fullWidth
                variant="contained"
                onClick={() => {
                  setDate(undefined);
                  calendarRef.current?.reset();
                }}
              >
                Wyczyść kalendarz
              </Button>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {availableDays ? (
                <IonDatetime
                  ref={calendarRef}
                  firstDayOfWeek={1}
                  style={{
                    position: "sticky",
                    top: "125px",
                    margin: "auto",
                  }}
                  value={date}
                  presentation="date"
                  mode="ios"
                  className="janek-shadow"
                  onIonChange={(e) => {
                    if (e.target.value) {
                      setDate(e.target.value as string);
                    }
                  }}
                  isDateEnabled={(e) => {
                    // let today = new Date();
                    // let tomorrow = new Date();
                    // tomorrow.setDate(today.getDate() + 1);

                    const valueDate = new Date(e);

                    if (
                      availableDays.includes(
                        valueDate.toISOString().replace(".000Z", "")
                      )
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                />
              ) : (
                <></>
              )}
            </IonCol>
          </IonRow>
        </IonCol> */}
      {/* </IonRow> */}

      <IonModal
        style={{
          "--height": "auto",
          "--width": "auto",
        }}
        isOpen={showModalImage}
        onIonModalDidDismiss={() => setShowModalImage(false)}
      >
        <IonImg style={{ height: "90vh" }} src={modalImage} />
      </IonModal>
      <IonRow>
        <IonCol></IonCol>
      </IonRow>
      {/* <IonRow style={{ marginTop: "35px" }}>
        <IonCol size="2">
          <DriverNameAutocomplete
            setDriverNames={setDriverNames}
            multiple
            options={[...new Set(rows.map((item) => item.userDriver))].sort()}
          />
        </IonCol>

        <IonCol size="2">
          <PunishmentTitleSelect
            punishmentTitle={punishmentTitles}
            setPunishmentTitles={setPunishmentTitles}
            multiple
            options={[...new Set(rows.map((item) => item.title))].sort()}
          />
        </IonCol>
        <IonCol size="2">
          <PunishmentDescriptionTextfield
            option={punishmentDescription}
            setPunishmentDescription={setPunishmentDescription}
          />
        </IonCol>
        <IonCol size="2">
          <PunishmentCommentTextfield
            option={punishmentComment}
            setPunishmentComment={setPunishmentComment}
          />
        </IonCol>
        <IonCol size="2">
          <PunishmentAddressAutocomplete
            setPunishmentAddresses={setPunishmentAddresses}
            multiple
            options={[
              ...new Set(
                rows.map((item) =>
                  item.routeAddress
                    ? item.routeAddress.street +
                      " " +
                      item.routeAddress.houseNumber
                    : ""
                )
              ),
            ].sort()}
          />
        </IonCol>
        <IonCol size="2">
          <PunishmentAddedByAutocomplete
            setAddedBys={setPunishmentAddedBys}
            multiple
            options={[
              ...new Set(rows.map((item) => item.userCreatedBy)),
            ].sort()}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="2">
          <PunishmentConfirmationSelect
            punishmentConfirmation={punishmentConfirmation}
            setPunishmentConfirmation={setPunishmentConfirmation}
            options={["Wszystkie", "Potwierdzone", "Brak potwierdzenia"]}
          />
        </IonCol>
      </IonRow> */}
      <IonRow
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IonCol size="2">
          <RangeSlider
            min={0}
            max={_punishmentCostMax}
            setMin={setPunishmentCostMin}
            setMax={setPunishmentCostMax}
            step={5}
          />
        </IonCol>
        <IonCol size="10">
          <IonRow className="filters-row">
            <IonCol size="3">
              <DriverNameAutocomplete
                setDriverNames={setDriverNames}
                multiple
                options={[
                  ...new Set(rows.map((item) => item.userDriver)),
                ].sort()}
              />
            </IonCol>
            <IonCol size="3">
              <PunishmentTitleSelect
                punishmentTitle={punishmentTitles}
                setPunishmentTitles={setPunishmentTitles}
                multiple
                options={[...new Set(rows.map((item) => item.title))].sort()}
              />
            </IonCol>
            <IonCol size="3">
              <PunishmentDescriptionTextfield
                option={punishmentDescription}
                setPunishmentDescription={setPunishmentDescription}
              />
            </IonCol>
            <IonCol size="3">
              <PunishmentCommentTextfield
                option={punishmentComment}
                setPunishmentComment={setPunishmentComment}
              />
            </IonCol>
            <IonCol size="3">
              <PunishmentAddressAutocomplete
                setPunishmentAddresses={setPunishmentAddresses}
                multiple
                options={[
                  ...new Set(
                    rows.map((item) =>
                      item.routeAddress
                        ? item.routeAddress.street +
                          " " +
                          item.routeAddress.houseNumber
                        : ""
                    )
                  ),
                ].sort()}
              />
            </IonCol>
            <IonCol size="3">
              <PunishmentAddedByAutocomplete
                setAddedBys={setPunishmentAddedBys}
                multiple
                options={[
                  ...new Set(rows.map((item) => item.userCreatedBy)),
                ].sort()}
              />
            </IonCol>
            <IonCol size="3">
              <PunishmentConfirmationSelect
                punishmentConfirmation={punishmentConfirmation}
                setPunishmentConfirmation={setPunishmentConfirmation}
                options={["Wszystkie", "Potwierdzone", "Brak potwierdzenia"]}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      {/* <IonRow>
        <IonCol size="12">
          <div id="chart" style={{}}>
            <ReactApexChart
              options={barChartOptions}
              series={filterBarChartData}
              type="bar"
              height={430}
            />
          </div>
        </IonCol>
      </IonRow> */}

      <div className="janek-shadow mb-5">
        <IonRow>
          <IonCol size="auto" style={{ marginLeft: "auto" }}>
            <LoadingButton
              onClick={() => {
                setIsExcelExportButtonLoading(true);

                api
                  .post(
                    "Stats/drivers/notes/generate-excel",
                    {
                      rowsId:
                        gridPaginatedVisibleSortedGridRowIdsSelector(apiRef),
                    },
                    {
                      responseType: "blob",
                    }
                  )
                  .then((response) => {
                    console.log(response);

                    const link = document.createElement("a");
                    link.href = response.data;
                    link.setAttribute(
                      "download",
                      response.headers["file-name"]
                    );
                    document.body.appendChild(link);
                    link.click();
                  })
                  .finally(() => {
                    setIsExcelExportButtonLoading(false);
                  });
              }}
              loading={isExcelExportButtonLoading}
              variant="contained"
              endIcon={<FileDownloadIcon />}
            >
              Eksportuj {datagridVisibleItemsCount} pozycji do excela
            </LoadingButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" style={{ padding: "0" }}>
            <DataGridPro
              columnVisibilityModel={{
                comments: false,
                punishmentCost: false,
                address: false,
                userCreatedBy: false,
                isConfirmed: false,
                date: false,
              }}
              // rowHeight={100}
              apiRef={apiRef}
              filterModel={filterModel}
              getRowHeight={() => "auto"}
              // className="janek-shadow"
              // isRowSelectable={(params: GridRowParams) => !!params.row.routeId && !params.row.active}
              // onSelectionModelChange={(newSelectionModel, details) => {
              //   setSelectionModel(newSelectionModel);
              // }}
              // selectionModel={selectionModel}
              sx={{
                height: "1000px",
                fontSize: "16px",
                ".MuiDataGrid-row": {
                  fontSize: "14px",
                },
              }}
              rows={rows}
              columns={columns}
              hideFooter
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
              // experimentalFeatures={{ newEditingApi: true }}
            />
          </IonCol>
        </IonRow>
      </div>
    </Container>
  );
};

export default Main;
