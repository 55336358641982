import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Input, localePl, Popup, setOptions } from "@mobiscroll/react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { ChangeEvent, forwardRef, ReactNode, SetStateAction, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import api from "../../services/api";

import { v4 as uuidv4, v4 } from "uuid";

type Props = {
  setStreet: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
  region: string;
  city: string;
  street: string;
}

const StreetsAutocomplete: React.FC<Props> = ({ setStreet, disabled, region, city, street }) => {

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly string[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }


    api.get("autocomplete/streets", {
      params: {
        region: region,
        city: city,
      }
    }).then((response) => {
      setOptions(response.data);
    });
    
    

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      value={street}
      disabled={disabled}
      onFocus={(event) => {
        const target = event.target as HTMLInputElement;
        if(target.autocomplete)
        {
          target.autocomplete = "whatever";
        }
      }}

      noOptionsText="Nie znaleziono"
      id={v4()}
      sx={{ width: 500, marginLeft: "30px" }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => {
        return option
      }}
      onChange={(event, value) => {
        setStreet(value ?? "");
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          type={"search"}
          className={(disabled ? "" : "shadow-mui")}
          {...params}
          
          label="Adres"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );

};

export default StreetsAutocomplete;