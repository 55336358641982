import {
  IonCol,
  IonDatetime,
  IonGrid,
  IonModal,
  IonRow,
  isPlatform,
} from "@ionic/react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { NumericFormat } from "react-number-format";
import { GenerateGUID, NumberToMoneyString } from "./Common";

import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";

import api from "./../services/api";

type Props = {
  onChange: (val: number) => void;
  value: number | undefined;
  dataType: "money" | "days" | "none";
  disabled?: boolean;
};

const FONT_SIZE = 9;
const DEFAULT_INPUT_WIDTH = 40;

const GetDataTypeEndorment = (dataType: "money" | "days" | "none") => {
  if (dataType == "money") {
    return "zł";
  }
  if (dataType == "days") {
    return "dni";
  }
  if (dataType == "none") {
    return "";
  }
};

const GetDataTypeScale = (dataType: "money" | "days" | "none") => {
  if (dataType == "money") {
    return 2;
  }
  if (dataType == "days") {
    return 0;
  }
  if (dataType == "none") {
    return 0;
  }
};

const GetDataTypeOutput = (
  dataType: "money" | "days" | "none",
  value: number | undefined
) => {
  if (value == undefined) {
    return "";
  }
  if (dataType == "money") {
    return NumberToMoneyString(value);
  }
  if (dataType == "days") {
    return value + " dni";
  }
  if (dataType == "none") {
    return value;
  }
};

const NumericFormatEditable: React.FC<Props> = ({
  onChange,
  value,
  dataType,
  disabled = false,
}) => {
  const [editable, setEditable] = useState(false);
  const [numericValue, setNumericValue] = useState(value);

  return (
    <>
      {editable ? (
        <>
          <div>
            <NumericFormat
              InputProps={{
                endAdornment: <span>{GetDataTypeEndorment(dataType)}</span>,
              }}
              // label="Koszt kary"
              customInput={TextField}
              onValueChange={(values) => {
                if (values.floatValue == undefined) {
                  setNumericValue(0);
                } else {
                  setNumericValue(values.floatValue);
                }
              }}
              value={numericValue}
              variant="standard"
              decimalScale={GetDataTypeScale(dataType)}
              fixedDecimalScale
            />
          </div>
          <div>
            <IconButton
              onClick={() => {
                setNumericValue(value);
                setEditable(false);
              }}
              color="error"
            >
              <ClearIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                if (numericValue == undefined) {
                  onChange(0);
                } else {
                  onChange(numericValue);
                }
                setEditable(false);
              }}
              color="success"
            >
              <DoneIcon />
            </IconButton>
          </div>
        </>
      ) : (
        <>
          {GetDataTypeOutput(dataType, value)}
          {disabled ? (
            <></>
          ) : (
            <IconButton
              style={{ paddingRight: 0, paddingTop: 0 }}
              onClick={() => {
                setEditable(true);
              }}
              color="primary"
              aria-label="change punishment cost"
              component="label"
            >
              <EditIcon />
            </IconButton>
          )}
        </>
      )}
    </>
  );
};

export default NumericFormatEditable;
