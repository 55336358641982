import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { GenerateGUID } from "../components/Common";

import api from "./../services/api";

// import { v4 as uuidv4, v4 } from "uuid";

type Props = {
  setPunishmentConfirmation?: React.Dispatch<React.SetStateAction<string>>;
  setPunishmentConfirmations?: React.Dispatch<React.SetStateAction<string[]>>;
  punishmentConfirmation: string | string[];
  options: string[];
  multiple?: boolean;
};

const PunishmentConfirmationSelect: React.FC<Props> = ({
  setPunishmentConfirmation,
  setPunishmentConfirmations,
  punishmentConfirmation,
  options,
  multiple = false,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleChange = (
    event: SelectChangeEvent<typeof punishmentConfirmation>
  ) => {
    const {
      target: { value },
    } = event;
    if (setPunishmentConfirmation) {
      const val = value as string | null;
      setPunishmentConfirmation(val ?? "");
    }
    if (setPunishmentConfirmations) {
      const val = value as string[] | null;
      setPunishmentConfirmations(val ?? []);
    }
  };

  const loading = open && options.length === 0;

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="punishment-title-select">Potwierdzenia</InputLabel>
      <Select
        labelId="punishment-title-select"
        input={<OutlinedInput label="Potwierdzenia" />}
        multiple={multiple}
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "auto",
          },
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={handleChange}
        value={
          setPunishmentConfirmation
            ? (punishmentConfirmation as string)
            : (punishmentConfirmation as string[])
        }
      >
        {options.map((e) => {
          return (
            <MenuItem key={e} value={e}>
              {e}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default PunishmentConfirmationSelect;
