import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonReorderGroup,
  IonReorder,
  IonModal,
  IonProgressBar,
  useIonLoading,
  useIonAlert,
  IonRow,
  IonCol,
} from "@ionic/react";
import "./DriverUsers.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { RouteComponentProps, useHistory } from "react-router";

import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";
import Header from "../components/Header";

import { v4 as uuidv4 } from "uuid";
import {
  colorPalette,
  colorPaletteOutline,
  ellipsisHorizontal,
  ellipsisHorizontalCircleOutline,
  lockClosed,
  searchOutline,
  trash,
} from "ionicons/icons";

import { useDropzone } from "react-dropzone";
import api from "./../services/api";

import TextField from "@mui/material/TextField";
import {
  Button,
  Chip,
  debounce,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Select,
  Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NavigationIcon from "@mui/icons-material/Navigation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RegionAutocomplete from "../components/Drivers/RegionAutocomplete";
import MapRead, { MapRefProps } from "../components/MapRead";

import Menu from "@mui/icons-material/Menu";

import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";

import { ChromePicker, SketchPicker } from "react-color";
import CsvList from "../components/CsvList";
import useAxios from "axios-hooks";
import { GenerateGUID } from "../components/Common";
import { useGridApiRef } from "@mui/x-data-grid-pro";

const DriverUsers: React.FC<RouteComponentProps> = ({ match }) => {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const map = useRef<MapRefProps>(null);

  const [presentAlert] = useIonAlert();

  const [showMap, setShowMap] = useState(false);

  const [region, setRegion] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("Wszystko");

  const [presentLoading, dismissLoading] = useIonLoading();

  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const history = useHistory();

  // const [drivers, setDrivers] = useState<DriverUser[]>([]);

  const [isFilesModalVisible, setIsFilesModalVisible] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const [isPickerOpen, setIsPickerOpen] = useState(false);


  const [modalUserEditMode, setModalUserEditMode] = useState(false);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);

  useIonViewDidLeave(() => {
    setShowMap(false);
  });

  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: "id",
      headerName: "Id",
      maxWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "driverType",
      headerName: "Typ kierowcy",
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "username",
      headerName: "Nazwa użytkownika",
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "firstName",
      headerName: "Imię",
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "lastName",
      headerName: "Nazwisko",
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "passwordRaw",
      headerName: "Hasło",
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "phone",
      headerName: "Prywatny numer telefonu",
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "lastLogged",
      headerName: "Ostatnie logowanie na trasę",
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "",
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          <Button
            onClick={() => {
              setModalUser({
                id: params.row.id,
                driverTypeId: params.row.driverTypeId,
                firstName: params.row.firstName,
                lastName: params.row.lastName,
                phone: params.row.phone.replaceAll(" ", "")
              });
              setIsAddUserOpen(true);
              setModalUserEditMode(true);
            }}
            variant="outlined"
            // endIcon={<NavigationIcon />}
          >
            Edytuj
          </Button>
        </>
      ),
    },
  ];

  // useEffect(() => {
  //   if (drivers.length <= 0) {
  //     const temp<Drivers> = [];

  //     for (let i = 0; i < 60; i++) {
  //       tempDrivers.push("");
  //     }

  //     setDrivers(tempDrivers);
  //   }
  // }, []);

  const { setState, state } = useGlobalState();

  type DriverUser = {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    passwordRaw: string;
    driverType: string;
    driverTypeId: string;
    phone: string;
    lastLogged: string;
  };

  type ModalDriverUser = {
    id: string;
    firstName: string;
    lastName: string;
    driverTypeId: number;
    phone: string;
  };

  // const getData = async () => {
  //   presentLoading({
  //     spinner: "crescent",
  //     message: "Pobieranie danych...",
  //     duration: 20000,
  //   });

  //   await api
  //     .get("driverUsers")
  //     .then((response) => {
  //       if (response.data) {
  //         setDrivers(response.data);
  //       }
  //     })
  //     .finally(() => {
  //       dismissLoading();
  //     });
  // };

  // useEffect(() => {
  //   getData();
  // }, [region, status]);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  const [modalUser, setModalUser] = useState<ModalDriverUser>({
    id: "",
    driverTypeId: 1,
    firstName: "",
    lastName: "",
    phone: "",
  });

  const [{ data: drivers = [], loading, error }, refetch] = useAxios<DriverUser[]>({
    url: "driverUsers"
  });

  // useEffect(() => {
  //   setDrivers(data); 
  // }, [data]);


  const [searchValue, setSearchValue] = React.useState("");

  const memoDataGrid = useMemo(() => {
    return (
      <DataGridPro
        filterModel={{
          items: [],
          quickFilterValues: searchValue.split(" ")
        }}
        // isRowSelectable={(params: GridRowParams) => !!params.row.routeId && !params.row.active}
        // onSelectionModelChange={(newSelectionModel, details) => {
        //   setSelectionModel(newSelectionModel);
        // }}
        // selectionModel={selectionModel}
        sx={{
          fontSize: "16px",
        }}
        rows={drivers}
        columns={columns}
        hideFooter
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        // checkboxSelection
        disableRowSelectionOnClick
        rowHeight={52}
        //disableVirtualization
        // experimentalFeatures={{ newEditingApi: true }}
      />
    );
  }, [drivers, selectionModel, searchValue]);

  return (
    <IonPage>
      <div
        id="driver-users-page-controller"
        className={"background-lightgrey slide-transition-leave nk-main"}
        style={{
          overflow: "auto"
        }}
      >
        <IonModal
          style={{
            "--height": "400px",
            // "--wdith": "280px",
          }}
          isOpen={isAddUserOpen}
          onIonModalDidDismiss={() => setIsAddUserOpen(false)}
        >
          <IonContent
            style={{
              "--padding-start": "15px",
              "--padding-end": "15px",
              "--padding-top": "10px",
              "--padding-bottom": "10px",
            }}
          >
            <IonRow>
              <IonCol size="12">
                <h3>Dodaj kierowcę</h3>
              </IonCol>
            </IonRow>
            <IonRow style={{ marginTop: "15px" }}>
              <IonCol size="6">
                <TextField
                  value={modalUser.firstName}
                  onChange={(e) =>
                    {
                      setModalUser({ ...modalUser, firstName: e.target.value });
                    }
                  }
                  fullWidth
                  variant="outlined"
                  label="Imię"
                />
              </IonCol>
              <IonCol size="6">
                <TextField
                  value={modalUser.lastName}
                  onChange={(e) =>
                    setModalUser({ ...modalUser, lastName: e.target.value })
                  }
                  fullWidth
                  variant="outlined"
                  label="Nazwisko"
                />
              </IonCol>
            </IonRow>
            <IonRow style={{ marginTop: "10px" }}>
              <IonCol size="12">
                <TextField
                  value={modalUser.phone}
                  onChange={(e) =>
                    setModalUser({ ...modalUser, phone: e.target.value })
                  }
                  fullWidth
                  variant="outlined"
                  label="Numer telefonu"
                  type={"number"}
                />
              </IonCol>
            </IonRow>
            <IonRow style={{ marginTop: "10px" }}>
              <IonCol size="12">
                <FormControl fullWidth>
                  <InputLabel id="select-driver-type-label">
                    Typ kierowcy
                  </InputLabel>
                  <Select
                    id="select-driver-type"
                    value={modalUser.driverTypeId}
                    onChange={(e) =>
                      setModalUser({
                        ...modalUser,
                        driverTypeId: e.target.value as number,
                      })
                    }
                    labelId="select-driver-type-label"
                    fullWidth
                    label="Typ kierowcy"
                  >
                    <MenuItem value={1}>Stały kierowca</MenuItem>
                    <MenuItem value={2}>Skoczek</MenuItem>
                  </Select>
                </FormControl>
              </IonCol>
            </IonRow>
            <IonRow style={{ marginTop: "30px" }}>
              <IonCol size="12">
                {
                  modalUserEditMode
                  ?
                  <Button
                  onClick={() => {
                    presentLoading("Aktualizowanie kierowcy");

                    api
                      .patch("driverUsers/edit", {
                        ...modalUser,
                      })
                      .then((e) => {
                        const data = e.data;

                        refetch();

                        setIsAddUserOpen(false);
                        dismissLoading();
                      })
                      .catch((e) => {
                        const data = e.response.data.errors;

                        presentAlert("Error: " + JSON.stringify(data));
                      })
                      .finally(() => {
                        dismissLoading();
                      });
                  }}
                  size="large"
                  variant="contained"
                  fullWidth
                >
                  Aktualizuj kierowcę
                </Button>
                :
                <Button
                  onClick={() => {
                    presentLoading("Dodawanie kierowcy");

                    api
                      .post("driverUsers/add", {
                        ...modalUser,
                      })
                      .then((e) => {
                        const data = e.data;

                        refetch();

                        setIsAddUserOpen(false);
                        dismissLoading();
                      })
                      .catch((e) => {
                        const data = e.response.data.errors;

                        presentAlert("Error: " + JSON.stringify(data));
                      })
                      .finally(() => {
                        dismissLoading();
                      });
                  }}
                  size="large"
                  variant="contained"
                  fullWidth
                >
                  Dodaj kierowcę
                </Button>
                }
              </IonCol>
            </IonRow>
          </IonContent>
        </IonModal>

      

        <Header />

        <div className="nk-wrap ">
          {/* main header @s */}

          {/* main header @e */}
          {/* content @s */}
          <div className="nk-content ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">
                          Lista kierowców{" "}
                          <span
                            style={{
                              color: "rgb(63, 84, 255)",
                              fontSize: "34px",
                            }}
                          >
                            {region ? region.toUpperCase() : ""}
                          </span>
                        </h3>
                        {/* <div className="nk-block-des text-soft">
                          <p>You have total 2,595 users.</p>
                        </div> */}
                      </div>
                      {/* .nk-block-head-content */}
                      <div className="nk-block-head-content">
                        <div className="toggle-wrap nk-block-tools-toggle">
                          <a
                            href="#"
                            className="btn btn-icon btn-trigger toggle-expand mr-n1"
                            data-target="pageMenu"
                          >
                            <em className="icon ni ni-menu-alt-r" />
                          </a>
                          {/* <div
                            className="toggle-expand-content"
                            data-content="pageMenu"
                          >
                            <ul className="nk-block-tools g-3">
                              <li>
                                <a
                                  href="#"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-download-cloud" />
                                  <span>Export</span>
                                </a>
                              </li>
                              <li className="nk-block-tools-opt">
                                <div className="drodown">
                                  <a
                                    href="#"
                                    className="dropdown-toggle btn btn-icon btn-primary"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-plus" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <a href="#">
                                          <span>Add User</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Add Team</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Import User</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                        {/* .toggle-wrap */}
                      </div>
                      {/* .nk-block-head-content */}
                    </div>
                    {/* .nk-block-between */}
                  </div>
                  {/* .nk-block-head */}

                  <Paper elevation={0}>
                    <div className="nk-block">
                      <div className="card card-bordered card-stretch">
                        <div className="card-inner-group">
                          <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                              {/* <div className="card-tools">
                                <div className="form-inline flex-nowrap">
                                  <TextField
                                    className="shadow-mui"
                                    style={{
                                      width: "500px",
                                    }}
                                    id="outlined-search"
                                    label="Wyszukaj"
                                    type="search"
                                    onChange={(e) => {
                                      setSearch(e.target.value!);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key == "Enter") {
                                        getData();
                                      }
                                    }}
                                    value={search}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton
                                            onClick={() => getData()}
                                            aria-label="search"
                                          >
                                            <SearchIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />

                                  <RegionAutocomplete setRegion={setRegion} />

                                  <Button
                                    onClick={() => {
                                      getData();

                                      if (showMap) {
                                        setShowMap(false);
                                        setTimeout(() => {
                                          setShowMap(true);
                                        }, 300);
                                      } else {
                                        setShowMap(true);
                                      }
                                    }}
                                    disabled={!region}
                                    className="ml-2"
                                    style={{
                                      height: "55px",
                                    }}
                                    variant="contained"
                                    startIcon={<VisibilityIcon />}
                                  >
                                    Pokaż mapę
                                  </Button>

                                  <FormControl
                                    style={{
                                      marginLeft: "30px",
                                      width: "200px",
                                    }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Aktualna trasa
                                    </InputLabel>
                                    <Select
                                      className="shadow-mui"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={status}
                                      label="Aktualna trasa"
                                      onChange={(e) =>
                                        setStatus(e.target.value!)
                                      }
                                    >
                                      <MenuItem value={"Wszystko"}>
                                        Wszystko
                                      </MenuItem>
                                      <MenuItem value={"Dodano"}>
                                        Dodano
                                      </MenuItem>
                                      <MenuItem value={"Brak"}>Brak</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                
                              </div> */}
                            </div>
                            <div className="card-tools mr-n1">
                              <ul className="btn-toolbar gx-1">
                                <li>
                                  <a
                                    className="btn btn-primary btn-lg text-white"
                                    onClick={() => {
                                      setModalUser({
                                        id: "",
                                        driverTypeId: 1,
                                        firstName: "",
                                        lastName: "",
                                        phone: "",
                                      });
                                      setIsAddUserOpen(true);
                                      setModalUserEditMode(false);
                                    }}
                                  >
                                    {/* <em className="icon ni ni-upload-cloud" />{" "} */}
                                    <span>Dodaj kierowcę</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/* .card-title-group */}
                            <div
                              className="card-search search-wrap"
                              data-search="search"
                            >
                              <div className="card-body">
                                <div className="search-content">
                                  <a
                                    href="#"
                                    className="search-back btn btn-icon toggle-search"
                                    data-target="search"
                                  >
                                    <em className="icon ni ni-arrow-left" />
                                  </a>
                                  <input
                                    type="text"
                                    className="form-control border-transparent form-focus-none"
                                    placeholder="Search by user or email"
                                  />
                                  <button className="search-submit btn btn-icon">
                                    <em className="icon ni ni-search" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* .card-search */}
                          </div>
                          {/* .card-inner */}
                          <div
                            className="card-inner p-0"
                            // style={{ maxHeight: "800px", overflow: "auto" }}
                          >
                            {drivers.length > 0 ? (
                              <Box
                                sx={{
                                  height: 900,
                                  width: "100%",
                                }}
                              >
                                <TextField
                                  id={GenerateGUID()}
                                  autoComplete={GenerateGUID()}
                                  style={{
                                    margin: "10px auto",
                                    width: "500px",
                                    display: "flex",
                                  }}
                                  variant="filled"
                                  value={searchValue}
                                  onChange={e => setSearchValue(e.target.value)}
                                  label="Wyszukaj"
                                  sx={{ mb: 1 }}
                                />
                                {memoDataGrid}
                              </Box>
                            ) : (
                              <></>
                            )}

                            {/* 
                            <div className="nk-tb-list nk-tsb-ulist is-compact">
                              <div className="nk-tb-item nk-tb-head">
                                <div className="nk-tb-col">
                                  <span className="sub-text">Użytkownik</span>
                                </div>
                                <div className="nk-tb-col">
                                  <span className="sub-text">Hasło</span>
                                </div>
                                <div className="nk-tb-col">
                                  <span className="sub-text">ID</span>
                                </div>

                                <div className="nk-tb-col ">
                                  <span className="sub-text">Regiony</span>
                                </div>
                                <div className="nk-tb-col ">
                                  <span className="sub-text">
                                    Aktualna trasa
                                  </span>
                                </div>
                                <div className="nk-tb-col ">
                                  <IconButton
                                    color="primary"
                                    aria-label="pokaż zdjęcia"
                                    component="span"
                                    onClick={() => {
                                      const layers = [
                                        ["route"],
                                        ["points", ["==", "t", "N"]],
                                        ["pointsError", ["==", "t", "E"]],
                                        ["start", ["==", "t", "S"]],
                                        ["end", ["==", "t", "L"]],
                                      ];

                                      const driversTemp = drivers;

                                      const hideEverything =
                                        !driversTemp[0].hidden;

                                      for (const n of layers) {
                                        let tempArray: any = ["all"];
                                        if (n[1]) {
                                          tempArray.push(n[1]);
                                        }

                                        if (hideEverything) {
                                          for (const hiddenDriver of drivers) {
                                            tempArray.push([
                                              "!=",
                                              "driver",
                                              hiddenDriver.id,
                                            ]);
                                          }
                                        }

                                        map.current?.Map.current?.setFilter(
                                          n[0] as string,
                                          tempArray
                                        );
                                      }

                                      let itemIndex = 0;

                                      let items = [...drivers];
                                      for (const item of items) {
                                        item.hidden = hideEverything;
                                        items[itemIndex] = item;
                                        itemIndex++;
                                      }
                                      setDrivers(items);
                                    }}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </div>
                                <div className="nk-tb-col ">
                                  <span className="sub-text">
                                    Przetworzone punkty
                                  </span>
                                </div>

                                <div className="nk-tb-col ">
                                  <span className="sub-text">
                                    Zdjęcia
                                  </span>
                                </div>



                                <div className="nk-tb-col ">
                                  <span className="sub-text"></span>
                                </div>

                                <div className="nk-tb-col nk-tb-col-tools text-right">
                                  
                                </div>
                              </div> */}

                            {/* <IonReorderGroup onIonItemReorder={(event) => event.detail.complete(true)} disabled={false} style={{ display: "table-row-group" }} > */}

                            {/* {drivers.map((e, i) => {
                                return (
                                  <>
                                    <div className="nk-tb-item" key={e.id}>
                                      <div className="nk-tb-col">
                                        <div className="user-card">
                                          <div
                                            className="user-avatar xs"
                                            style={{
                                              height: "37px",
                                              width: "37px",
                                              fontSize: "13px",
                                              paddingTop: "2px",
                                              background: "#" + e.color,
                                              borderColor: "#" + e.colorLight,
                                              borderWidth: "3px",
                                              borderStyle: "solid",
                                              letterSpacing: 0,
                                            }}
                                          >
                                            <span>{e.name.toUpperCase()}</span>
                                          </div>
                                          <div className="user-name">
                                            <div
                                              className="tb-lead"
                                              style={{
                                                letterSpacing: "0.5px",
                                                fontSize: "19px",
                                              }}
                                            >
                                              Pojazd{" "}
                                              <span style={{ fontWeight: 700 }}>
                                                {e.name.toUpperCase()}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="nk-tb-col ">
                                        <span>{e.password}</span>
                                      </div>
                                      <div className="nk-tb-col ">
                                        <span>{e.id}</span>
                                      </div>
                                      <div className="nk-tb-col ">
                                        <span>{e.region}</span>
                                      </div>

                                      <div className="nk-tb-col">
                                        {e.status ? (
                                          <span className="tb-status text-success">
                                            Dodano
                                            {e.active ? (
                                              <></>
                                            ) : (
                                              <>
                                                <span
                                                  className="text-warning"
                                                  style={{ fontWeight: 700 }}
                                                >
                                                  {" "}
                                                  - wersja robocza
                                                </span>
                                              </>
                                            )}
                                          </span>
                                        ) : (
                                          <span className="tb-status text-danger">
                                            Brak
                                          </span>
                                        )}
                                      </div>

                                      <div className="nk-tb-col">
                                        <IconButton
                                          color="primary"
                                          aria-label="pokaż zdjęcie"
                                          component="span"
                                          onClick={() => {
                                            const layers = [
                                              ["route"],
                                              ["points", ["==", "t", "N"]],
                                              ["pointsError", ["==", "t", "E"]],
                                              ["start", ["==", "t", "S"]],
                                              ["end", ["==", "t", "L"]],
                                            ];

                                            if (e.hidden) {
                                              for (const n of layers) {
                                                let tempArray: any = ["all"];
                                                if (n[1]) {
                                                  tempArray.push(n[1]);
                                                }

                                                for (const hiddenDriver of drivers.filter(
                                                  (s) => s.hidden
                                                )) {
                                                  if (hiddenDriver.id != e.id) {
                                                    tempArray.push([
                                                      "!=",
                                                      "driver",
                                                      hiddenDriver.id,
                                                    ]);
                                                  }
                                                }

                                                map.current?.Map.current?.setFilter(
                                                  n[0] as string,
                                                  tempArray
                                                );
                                              }
                                            } else {
                                              for (const n of layers) {
                                                let tempArray: any = ["all"];
                                                if (n[1]) {
                                                  tempArray.push(n[1]);
                                                }
                                                tempArray.push([
                                                  "!=",
                                                  "driver",
                                                  e.id,
                                                ]);

                                                for (const hiddenDriver of drivers.filter(
                                                  (s) => s.hidden
                                                )) {
                                                  tempArray.push([
                                                    "!=",
                                                    "driver",
                                                    hiddenDriver.id,
                                                  ]);
                                                }

                                                map.current?.Map.current?.setFilter(
                                                  n[0] as string,
                                                  tempArray
                                                );
                                              }
                                            }

                                            const driverIndex =
                                              drivers.findIndex(
                                                (s) => s.id == e.id
                                              );
                                            let items = [...drivers];
                                            let item = {
                                              ...drivers[driverIndex],
                                            };
                                            item.hidden = !item.hidden;
                                            items[driverIndex] = item;
                                            setDrivers(items);
                                          }}
                                        >
                                          {e.hidden ? (
                                            <VisibilityOffIcon color="error" />
                                          ) : (
                                            <VisibilityIcon />
                                          )}
                                        </IconButton>
                                      </div>

                                      <div className="nk-tb-col">
                                        <span
                                          className="tb-status text-success"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                          }}
                                        >
                                          {e.successPoints}
                                        </span>

                                        <span
                                          className="tb-status text-warning"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                          }}
                                        >
                                          {e.warningPoints}
                                        </span>

                                        <span
                                          className="tb-status text-danger"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                          }}
                                        >
                                          {e.errorPoints}
                                        </span>
                                      </div>

                                      <div className="nk-tb-col">

                                        <span
                                          className="tb-status text-primary"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                            fontWeight: 700
                                          }}
                                        >
                                          {e.donePoints}
                                        </span>

                                        <span
                                          className="tb-status text-primary"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                          }}
                                        >
                                          {e.proccessingPoints}
                                        </span>

                                        <span
                                          className="tb-status text-medium"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                          }}
                                        >
                                          {e.undonePoints}
                                        </span>

                                      </div>


                                      <div className="nk-tb-col"></div>
                                    
                                      <div className="nk-tb-col nk-tb-col-tools">
                                      

                                        <Button
                                          onClick={() => {
                                            const newWindow = window.open(
                                              "/route/" + e.id,
                                              "_blank",
                                              "noopener,noreferrer"
                                            );
                                            if (newWindow)
                                              newWindow.opener = null;
                                          }}
                                          variant="outlined"
                                          endIcon={<NavigationIcon />}
                                        >
                                          Trasy
                                        </Button>
                                      </div>

                                      <div></div>
                                      <div></div>
                                    </div>
                                  </>
                                );
                              })} */}

                            {/* </div> */}
                          </div>

                          {/* .card-inner */}
                          {/* <div className="card-inner">
                          <ul className="pagination justify-content-center justify-content-md-start">
                            <li className="page-item">
                              <a className="page-link" href="#">
                                Prev
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <span className="page-link">
                                <em className="icon ni ni-more-h" />
                              </span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                6
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                7
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                Next
                              </a>
                            </li>
                          </ul>
                        </div> */}
                          {/* .card-inner */}
                        </div>
                        {/* .card-inner-group */}
                      </div>
                      {/* .card */}
                    </div>
                  </Paper>

                  {/* .nk-block */}
                </div>
              </div>
            </div>
          </div>
          {/* content @e */}
        </div>
      </div>
    </IonPage>
  );
};

export default DriverUsers;
