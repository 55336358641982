import {
  IonCol,
  IonGrid,
  IonRow,
  isPlatform,
  useIonLoading,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  RefObject,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import mapboxgl, { GeoJSONSource, GeoJSONSourceRaw } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import "./Map.css";
import { Button, Divider, List, ListItem, Paper } from "@mui/material";

import { Storage } from "@capacitor/storage";
import api from "./../services/api";

import MapAutocomplete from "./Map/MapAutocomplete";
import axios from "axios";

type GeolocateAddress = {
  id: string;
  address1: string;
  address2: string;
};

type MapChanges = {
  id: string;
  address: string;
  lat: number;
  lng: number;
};

type Props = {
  routeId: string;
  GetData: (loading: boolean) => Promise<void>;
};

type PointsRequest = {
  Id: number;
  Lng: number;
  Lat: number;
  Type: string;
};

export type MapRefProps = {
  UpdateGeojson: () => Promise<void>;
  Map: React.MutableRefObject<mapboxgl.Map | null>;
  geojsonPoints: React.MutableRefObject<any>;
};

const Map: ForwardRefRenderFunction<MapRefProps, Props> = (props, ref) => {
  const [_lng, _setLng] = useState(20.7810167);
  const [_lat, _setLat] = useState(52.2326063);

  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [lng, setLng] = useState(20.7810167);
  const [lat, setLat] = useState(52.2326063);
  const [zoom, setZoom] = useState(6);

  const geojsonLines = useRef<any>({});
  const geojsonPoints = useRef<any>({});
  let canvas: HTMLElement;
  let draggedPointId: string = "";
  const dictionary = useRef<any>({});

  const distance = useRef<any>({});

  const [geolocationPointId, setGeolocationPointId] = useState<string>();

  const [showGeolocation, setShowGeolocation] = useState(false);
  const [geolocationAddress, setGeolocationAddress] =
    useState<GeolocateAddress>();

  useEffect(() => {
    if (_lng != 20.7810167 && _lng != 52.2326063) {
      map.current?.flyTo({
        center: {
          lat: _lat,
          lng: _lng,
        },
        zoom: 19,
        duration: 1000,
      });
    }
  }, [_lng, _lat]);

  const [presentLoading, dismissLoading] = useIonLoading();

  const onMove = (e: any) => {
    const coords = e.lngLat;

    if (canvas) {
      // Set a UI indicator for dragging.
      canvas.style.cursor = "grabbing";

      for (const point of geojsonPoints.current.features) {
        if (point.properties.id == draggedPointId) {
          point.geometry.coordinates = [coords.lng, coords.lat];

          if (dictionary.current) {
            dictionary.current[point.properties.id] = [
              coords.lng,
              coords.lat,
              point.properties.t,
            ];
          }

          break;
        }
      }

      (map.current?.getSource("points") as GeoJSONSource).setData(
        geojsonPoints.current
      );
    }
  };

  const onUp = (e: any) => {
    // console.log((map.current?.getSource("points") as any)._data.features)

    console.log(dictionary);

    // dictionary = {};

    // const mapDictionary: MapChanges[] = [];
    let htmlString = "";

    for (const stop of (map.current?.getSource("points") as any)._data
      .features) {
      if (Object.keys(dictionary.current).includes(stop.properties.id)) {
        console.log(stop.properties.id);
        // mapDictionary.push({
        //   address: stop.properties.address1,
        //   id: stop.properties.id,
        //   lng: stop.geometry.coordinates[0],
        //   lat: stop.geometry.coordinates[1]
        // });
        if (stop.properties.t == "S") {
          htmlString += "<li>PUNKT STARTOWY</li>";
        } else if (stop.properties.t == "L") {
          htmlString += "<li>PUNKT KOŃCOWY</li>";
        } else {
          htmlString += "<li>" + "- " + stop.properties.address1 + "</li>";
        }
      }
    }

    const element = document.getElementById("map-list");
    if (element) {
      element.innerHTML = htmlString;
    }

    // setMapChanges(mapDictionary);

    // _mapChanges = [];

    if (canvas) {
      const coords = e.lngLat;
      canvas.style.cursor = "";

      // Unbind mouse/touch events
      map.current?.off("mousemove", onMove);
      map.current?.off("touchmove", onMove);
    }
  };

  useIonViewDidLeave(() => {
    setTimeout(() => {
      map.current?.remove();
      map.current = null;
      if (mapContainer.current) {
        mapContainer.current.innerHTML = "";
      }
    }, 750);
  });

  useEffect(() => {
    map.current?.remove();
    map.current = null;
    if (mapContainer.current) {
      mapContainer.current.innerHTML = "";
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (map.current == null) {
        api
          .get("routes/" + props.routeId + "/geojson")
          .then(async (response) => {
            await Storage.set({
              key: "geojsonPointsStatic",
              value: response.data.geoJSONStops,
            });

            try {
              const elementDistance = document.getElementById("distance");
              if (elementDistance) {
                elementDistance.innerHTML = response.data.distance;
              }

              const elementDriverName = document.getElementById("driver-name");
              if (elementDriverName) {
                elementDriverName.innerHTML = response.data.name;
              }
            } catch (error) {}

            geojsonLines.current = JSON.parse(response.data.geoJSONMain);
            geojsonPoints.current = JSON.parse(response.data.geoJSONStops);

            const accessTokenRequest = await axios.get(
              "https://broccoli.z16.web.core.windows.net/MAPBOX_TOKEN.txt?stamp=" +
                new Date().getTime()
            );
            const accessToken = await accessTokenRequest.data;

            if (!map.current && mapContainer.current) {
              map.current = new mapboxgl.Map({
                accessToken: accessToken,
                fadeDuration: 0,
                container: mapContainer.current,
                style: "mapbox://styles/broccoliapi/cl31ma4fq000h14plphvz5ti9",
                attributionControl: false,
                // style: {
                //   version: 8,
                //   sources: {
                //     osm: {
                //       type: "raster",
                //       tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
                //       tileSize: 256,
                //     },
                //   },
                //   glyphs: location.protocol + "//fonts.openmaptiles.org/{fontstack}/{range}.pbf",
                //   layers: [
                //     {
                //       id: "osm",
                //       type: "raster",
                //       source: "osm",
                //     },
                //   ],
                // },
                bounds: [
                  [response.data.points.sw.lng, response.data.points.sw.lat],
                  [response.data.points.ne.lng, response.data.points.ne.lat],
                ],
                // center: [lng, lat],
                zoom: zoom,
              });

              canvas = map.current.getCanvasContainer();

              map.current.on("load", () => {
                map.current?.loadImage(
                  "https://broccolihot.z16.web.core.windows.net/markers/error.png",
                  (error, image) => {
                    if (error) throw error;

                    map.current?.addImage(
                      "error-marker",
                      image as HTMLImageElement | ImageBitmap
                    );

                    map.current?.loadImage(
                      "https://broccolihot.z16.web.core.windows.net/markers/start.png",
                      (error, image) => {
                        if (error) throw error;

                        map.current?.addImage(
                          "start-marker",
                          image as HTMLImageElement | ImageBitmap
                        );

                        map.current?.loadImage(
                          "https://broccolihot.z16.web.core.windows.net/markers/end.png",
                          (error, image) => {
                            if (error) throw error;

                            map.current?.addImage(
                              "end-marker",
                              image as HTMLImageElement | ImageBitmap
                            );

                            map.current?.loadImage(
                              "https://broccolihot.z16.web.core.windows.net/markers/" +
                                response.data.color +
                                ".png",
                              (_error, _image) => {
                                if (_error) throw _error;

                                map.current?.addImage(
                                  response.data.color + "-marker",
                                  _image as HTMLImageElement | ImageBitmap
                                );

                                map.current?.addLayer({
                                  id: "route",
                                  type: "line",
                                  source: "lines",
                                  layout: {
                                    "line-join": "round",
                                    "line-cap": "round",
                                  },
                                  paint: {
                                    "line-color": "#" + response.data.color,
                                    "line-width": 5,
                                  },
                                  filter: ["==", "$type", "LineString"],
                                });

                                // Add a symbol layer
                                map.current?.addLayer({
                                  id: "points",
                                  type: "symbol",
                                  source: "points",
                                  layout: {
                                    "icon-allow-overlap": true,
                                    "icon-ignore-placement": true,
                                    "icon-offset": [0, -20],
                                    "icon-image":
                                      response.data.color + "-marker",
                                    // get the title name from the source's "title" property
                                    "text-field": ["get", "order"],
                                    "text-font": ["Open Sans Bold"],
                                    "text-offset": [0, -2],
                                    "text-anchor": "top",
                                  },
                                  filter: ["==", "t", "N"],
                                  paint: {
                                    "text-color": "rgb(255, 255, 255)",
                                  },
                                });

                                map.current?.addLayer({
                                  id: "pointsError",
                                  type: "symbol",
                                  source: "points",
                                  layout: {
                                    "icon-allow-overlap": true,
                                    "icon-ignore-placement": true,
                                    "icon-offset": [0, -20],
                                    "icon-image": "error-marker",
                                    // get the title name from the source's "title" property
                                    "text-font": ["Open Sans Bold"],
                                    "text-offset": [0, -2],
                                    "text-anchor": "top",
                                  },
                                  filter: ["==", "t", "E"],
                                  paint: {
                                    "text-color": "rgb(255, 255, 255)",
                                  },
                                });

                                map.current?.addLayer({
                                  id: "start",
                                  type: "symbol",
                                  source: "points",
                                  layout: {
                                    "icon-allow-overlap": true,
                                    "icon-ignore-placement": true,
                                    "icon-offset": [0, -20],
                                    "icon-image": "start-marker",
                                    // get the title name from the source's "title" property
                                    "text-font": ["Open Sans Bold"],
                                    "text-offset": [0, -2],
                                    "text-anchor": "top",
                                  },
                                  filter: ["==", "t", "S"],
                                  paint: {
                                    "text-color": "rgb(255, 255, 255)",
                                  },
                                });

                                map.current?.addLayer({
                                  id: "end",
                                  type: "symbol",
                                  source: "points",
                                  layout: {
                                    "icon-allow-overlap": true,
                                    "icon-ignore-placement": true,
                                    "icon-offset": [0, -20],
                                    "icon-image": "end-marker",
                                    // get the title name from the source's "title" property
                                    "text-font": ["Open Sans Bold"],
                                    "text-offset": [0, -2],
                                    "text-anchor": "top",
                                  },
                                  filter: ["==", "t", "L"],
                                  paint: {
                                    "text-color": "rgb(255, 255, 255)",
                                  },
                                });

                                map.current?.on(
                                  "click",
                                  ["points", "pointsError", "start", "end"],
                                  (e: any) => {
                                    const features = e.features[0];
                                    const reponseData = features.properties;

                                    // Copy coordinates array.
                                    const coordinates =
                                      features.geometry.coordinates.slice();
                                    const description =
                                      "<div>" +
                                      "<div><strong>Adres</strong></div>" +
                                      "<div>" +
                                      reponseData.address1 +
                                      "</div><div class='mb-1'>" +
                                      reponseData.address2 +
                                      "</div>" +
                                      "<div><strong>Telefon</strong></div>" +
                                      "<div class='mb-1'>" +
                                      reponseData.phone +
                                      "</div>" +
                                      "<div><strong>Diety</strong></div>" +
                                      "<div>" +
                                      reponseData.diets
                                        ?.split(";")
                                        .join("</br>") +
                                      "</div>" +
                                      "<button id='popup-reset' data-dLt='" +
                                      features.properties.dLt +
                                      "' data-dLg='" +
                                      features.properties.dLg +
                                      "' >Resetuj</button>" +
                                      "<button id='popup-geolocate' data-dLt='" +
                                      features.properties.dLt +
                                      "' data-dLg='" +
                                      features.properties.dLg +
                                      "' >Zmień</button>" +
                                      "</div>";

                                    // Ensure that if the map is zoomed out such that multiple
                                    // copies of the feature are visible, the popup appears
                                    // over the copy being pointed to.
                                    while (
                                      Math.abs(e.lngLat.lng - coordinates[0]) >
                                      180
                                    ) {
                                      coordinates[0] +=
                                        e.lngLat.lng > coordinates[0]
                                          ? 360
                                          : -360;
                                    }

                                    if (map.current) {
                                      const mapboxPopup = new mapboxgl.Popup()
                                        .setOffset([0, -33])
                                        .setLngLat(coordinates)
                                        .setHTML(description)
                                        .addTo(map.current);

                                      const geolocateButton =
                                        document.getElementById(
                                          "popup-geolocate"
                                        );

                                      if (geolocateButton) {
                                        geolocateButton.onclick = (
                                          popupButtonProp
                                        ) => {
                                          
                                          setGeolocationPointId(draggedPointId);

                                          setGeolocationAddress({
                                            address1: reponseData.address1,
                                            address2: reponseData.address2,
                                            id: draggedPointId,
                                          });

                                          setShowGeolocation(true);

                                          mapboxPopup.remove();
                                        };
                                      }

                                      const popupButton =
                                        document.getElementById("popup-reset");

                                      if (popupButton) {
                                        popupButton.onclick = (
                                          popupButtonProp
                                        ) => {
                                          const _popupButton =
                                            popupButtonProp.target as HTMLButtonElement;

                                          if (_popupButton) {
                                            if (
                                              map.current &&
                                              _popupButton.dataset["dlt"] &&
                                              _popupButton.dataset["dlg"]
                                            ) {
                                              if (canvas) {
                                                for (const point of geojsonPoints
                                                  .current.features) {
                                                  if (
                                                    point.properties.id ==
                                                    draggedPointId
                                                  ) {
                                                    point.geometry.coordinates =
                                                      [
                                                        _popupButton.dataset[
                                                          "dlg"
                                                        ],
                                                        _popupButton.dataset[
                                                          "dlt"
                                                        ],
                                                      ];

                                                    if (dictionary.current) {
                                                      dictionary.current[
                                                        point.properties.id
                                                      ] = [
                                                        _popupButton.dataset[
                                                          "dlg"
                                                        ],
                                                        _popupButton.dataset[
                                                          "dlt"
                                                        ],
                                                        point.properties.t,
                                                      ];
                                                    }

                                                    break;
                                                  }
                                                }

                                                (
                                                  map.current?.getSource(
                                                    "points"
                                                  ) as GeoJSONSource
                                                ).setData(
                                                  geojsonPoints.current
                                                );
                                              }

                                              mapboxPopup.remove();

                                              map.current.panTo({
                                                lat: Number(
                                                  _popupButton.dataset["dlt"]
                                                ),
                                                lng: Number(
                                                  _popupButton.dataset["dlg"]
                                                ),
                                              });
                                            }
                                          }
                                        };
                                      }
                                    }
                                  }
                                );

                                // When the cursor enters a feature in
                                // the point layer, prepare for dragging.
                                map.current?.on(
                                  "mouseenter",
                                  ["points", "pointsError", "start", "end"],
                                  async (e) => {
                                    if (
                                      document.getElementById("map-list")
                                        ?.innerHTML == "" &&
                                      Object.keys(dictionary.current).length > 1
                                    ) {
                                      if (dictionary.current) {
                                        dictionary.current = {};
                                      }

                                      const storageValue = (
                                        await Storage.get({
                                          key: "geojsonPointsStatic",
                                        })
                                      ).value;

                                      if (storageValue) {
                                        geojsonPoints.current =
                                          JSON.parse(storageValue);
                                      }
                                    }

                                    if (canvas) {
                                      const tempCanvas = canvas;
                                      tempCanvas.style.cursor = "move";
                                      canvas = tempCanvas;
                                    }
                                  }
                                );

                                map.current?.on(
                                  "mouseleave",
                                  ["points", "pointsError", "start", "end"],
                                  () => {
                                    if (canvas) {
                                      const tempCanvas = canvas;
                                      tempCanvas.style.cursor = "";
                                      canvas = tempCanvas;
                                    }
                                  }
                                );

                                map.current?.on(
                                  "mousedown",
                                  ["points", "pointsError", "start", "end"],
                                  (e) => {
                                    // Prevent the default map drag behavior.
                                    e.preventDefault();

                                    if (e.features) {
                                      draggedPointId =
                                        e.features[0].properties?.id;
                                    }

                                    if (canvas) {
                                      const tempCanvas = canvas;
                                      tempCanvas.style.cursor = "grab";
                                      canvas = tempCanvas;
                                    }

                                    map.current?.on("mousemove", onMove);
                                    map.current?.once("mouseup", onUp);
                                  }
                                );

                                map.current?.on(
                                  "touchstart",
                                  ["points", "pointsError", "start", "end"],
                                  (e) => {
                                    if (e.points.length !== 1) return;

                                    // Prevent the default map drag behavior.
                                    e.preventDefault();

                                    map.current?.on("touchmove", onMove);
                                    map.current?.once("touchend", onUp);
                                  }
                                );
                              }
                            );

                            map.current?.addSource("lines", {
                              type: "geojson",
                              data: JSON.parse(response.data.geoJSONMain),
                            });

                            map.current?.addSource("points", {
                              type: "geojson",
                              data: JSON.parse(response.data.geoJSONStops),
                            });

                            // map.current?.flyTo({
                            //   center: {
                            //     lat: response.data.initPoint.lat,
                            //     lng: response.data.initPoint.lng,
                            //   },
                            //   zoom: 12,
                            // });

                            // map.current?.fitBounds([[response.data.points.sw.lng, response.data.points.sw.lat], [response.data.points.ne.lng, response.data.points.ne.lat]]);
                          }
                        );
                      }
                    );
                  }
                );
              });
            }
          })
          .finally(() => {});
      }
    }, 250);
  }, []);

  const _updateGeojson = async () => {
    const res = await api.get("routes/" + props.routeId + "/geojson");

    const response = await res;

    try {
      const elementDistance = document.getElementById("distance");
      if (elementDistance) {
        elementDistance.innerHTML = response.data.distance;
      }

      const elementDriverName = document.getElementById("driver-name");
      if (elementDriverName) {
        elementDriverName.innerHTML = response.data.name;
      }
    } catch (error) {}

    await Storage.set({
      key: "geojsonPointsStatic",
      value: response.data.geoJSONStops,
    });

    geojsonLines.current = JSON.parse(response.data.geoJSONMain);
    geojsonPoints.current = JSON.parse(response.data.geoJSONStops);

    (map.current?.getSource("points") as GeoJSONSource).setData(
      geojsonPoints.current
    );

    (map.current?.getSource("lines") as GeoJSONSource).setData(
      geojsonLines.current
    );

    dictionary.current = {};

    const element = document.getElementById("map-list");
    if (element) {
      element.innerHTML = "";
    }
  };

  useImperativeHandle(ref, () => ({
    UpdateGeojson: _updateGeojson,
    Map: map,
    geojsonPoints: geojsonPoints,
  }));

  return (
    <>
      <Paper
        elevation={2}
        style={{
          position: "absolute",
          zIndex: 1,
          left: "15px",
          top: "15px",
          padding: "5px 30px 20px",
        }}
      >
        <List
          style={{
            marginBottom: "15px",
            padding: "7px 25px 0 0",
          }}
        >
          <ListItem
            style={{
              fontSize: "23px",
              fontWeight: 600,
            }}
          >
            Twoje zmiany
          </ListItem>
          <Divider />
          <ul id="map-list"></ul>
          {/* {mapChanges.map((e) => {
                            return (
                              <ListItem
                                style={{
                                  fontSize: "17px",
                                }}
                              >
                                <ListItemIcon>
                                  <SendIcon style={{ minWidth: "40px" }} />
                                </ListItemIcon>
                                <ListItemText primary={e.address} />
                              </ListItem>
                            );
                          })} */}
        </List>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            if (Object.keys(dictionary.current).length > 0) {
              let dictionaryToApi: PointsRequest[] = [];

              for (const dict of Object.keys(dictionary.current)) {
                dictionaryToApi.push({
                  Id: Number(dict),
                  Lng: dictionary.current[dict][0],
                  Lat: dictionary.current[dict][1],
                  Type: dictionary.current[dict][2],
                });
              }

              presentLoading({
                spinner: "crescent",
                message: "Aktualizowanie punktów i optymalizacja trasy...",
                duration: 20000,
              });

              api
                .patch("points", {
                  Points: dictionaryToApi,
                  RouteId: props.routeId,
                })
                .then(async () => {
                  api
                    .post("routes/" + props.routeId + "/optimize")
                    .finally(async () => {
                      if (map.current) {
                        await _updateGeojson();
                        await props.GetData(false);
                      }

                      await dismissLoading();
                    });
                });
            }
          }}
        >
          Zapisz i zoptymalizuj
        </Button>

        <Button
          className="mt-2"
          onClick={async () => {
            dictionary.current = {};

            const element = document.getElementById("map-list");
            if (element) {
              element.innerHTML = "";
            }

            const storageValue = (
              await Storage.get({ key: "geojsonPointsStatic" })
            ).value;

            if (storageValue) {
              geojsonPoints.current = JSON.parse(storageValue);

              (map.current?.getSource("points") as GeoJSONSource).setData(
                JSON.parse(storageValue)
              );
            }
          }}
          fullWidth
          variant="outlined"
          color="error"
        >
          Odrzuć
        </Button>
      </Paper>

      <div
        ref={mapContainer}
        className="map-container"
        style={{ height: "850px" }}
      />

      {showGeolocation ? (
        <div className="map-modal-background">
          <Paper
            elevation={2}
            style={{
              width: "600px",
              margin: "auto",
              marginTop: "50px",
            }}
          >
            <div className="map-modal py-3 px-5">
              <h4>Geolokalizacja adresu:</h4>
              <h3
                className="pt-3"
                style={{
                  textAlign: "center",
                }}
              >
                {geolocationAddress?.address1}
              </h3>
              <h3
                className="pb-3"
                style={{
                  textAlign: "center",
                }}
              >
                {geolocationAddress?.address2}
              </h3>

              <MapAutocomplete
                setLng={_setLng}
                setLat={_setLat}
                map={map}
                onclick={(lat: number, lng: number) => {

                  if (map.current) {
                    console.log(geolocationPointId);
                      for (const point of geojsonPoints.current.features) {
                        if (point.properties.id == geolocationPointId) {
                          point.geometry.coordinates = [lng, lat];

                          if (dictionary.current) {
                            dictionary.current[point.properties.id] = [
                              lng,
                              lat,
                              point.properties.t,
                            ];
                          }

                          break;
                        }
                      }

                      (
                        map.current?.getSource("points") as GeoJSONSource
                      ).setData(geojsonPoints.current);
                  }

                  onUp(null);

                }}
              />

              <div className="mt-5">
                <Button
                  size="large"
                  variant="contained"
                  fullWidth
                  onClick={() => setShowGeolocation(false)}
                >
                  Wyjdź
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      ) : (
        <></>
      )}

      <Paper
        elevation={2}
        style={{
          position: "absolute",
          zIndex: 1,
          right: "15px",
          top: "15px",
        }}
      >
        <MapAutocomplete setLng={_setLng} setLat={_setLat} map={map} />
      </Paper>

      <Paper
        elevation={2}
        style={{
          position: "absolute",
          zIndex: 1,
          left: "15px",
          bottom: "15px",
          padding: "10px 25px 10px",
          fontSize: "23px",
        }}
      >
        Dystans: <strong id="distance" style={{ fontWeight: 700 }}></strong>
      </Paper>
      <Paper
        elevation={2}
        style={{
          position: "absolute",
          zIndex: 1,
          left: "15px",
          bottom: "75px",
          padding: "10px 25px 10px",
          fontSize: "23px",
        }}
      >
        Pojazd <strong id="driver-name" style={{ fontWeight: 700 }}></strong>
      </Paper>
    </>
  );
};

export default forwardRef(Map);
