import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { GenerateGUID } from "../Common";

import api from "./../../services/api";

// import { v4 as uuidv4, v4 } from "uuid";

type Props = {
  setMonth: React.Dispatch<React.SetStateAction<string>>;
  setMonthId: React.Dispatch<React.SetStateAction<Date | undefined>>;
  width?: string;
  fullWidth?: boolean;
};

type Option = {
  id: Date;
  value: string;
}

const PaymentsMonthsSelect: React.FC<Props> = ({
  setMonth,
  setMonthId,
  width,
  fullWidth = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Option[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {

    api.get("autocomplete/payments-months").then((response) => {
      let data = response.data as Option[];
      setOptions(data);

      _setMonth(data[1].value);
      setMonth(data[1].value);
      setMonthId(data[1].id);
    });

  }, [])

  // useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  const [_month, _setMonth] = React.useState("");

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="issue-type-select">Miesiąc do wypłat</InputLabel>
      <Select
        labelId="issue-type-select"
        input={<OutlinedInput label="Miesiąc do wypłat" />}
        sx={{
          width: 350,
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event) => {

          const val = options.find(e => e.value == event.target.value);

          if(val)
          {
            _setMonth(val.value);
            setMonth(val.value);
            setMonthId(val.id as Date);
          }
          
        }}  
        value={_month}
      >
        {options.map((e) => {
          return (
            <MenuItem key={e.value.toString()} value={e.value.toString()}>
              {e.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default PaymentsMonthsSelect;
