import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { GenerateGUID } from "../components/Common";

// import { v4 as uuidv4, v4 } from "uuid";

import {v4} from 'uuid';

type Props = {
  options: string[];
  multiple?: boolean;
  onChange: (event: any, value: string | string[] | null) => void;
  label: string;
  disabled?: boolean;
  size?: string;
};

const CustomAutocomplete: React.FC<Props> = ({
  options,
  onChange,
  label,
  size,
  disabled = false,
  multiple = false,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Autocomplete
      disabled={disabled}
      multiple={multiple}
      noOptionsText="Nie znaleziono"
      // id={v4()}
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: size ? size : "auto",
        },
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => {
        return option;
      }}
      onChange={(event, value) => {
        onChange(event, value);
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          type={"search"}
          className="shadow-mui"
          {...params}
          id={v4()}
          autoComplete={v4()}
          label={label}
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default CustomAutocomplete;
