import { localePl, Popup, setOptions } from "@mobiscroll/react";
import React, {
  createRef,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Header.css";

import auth from "./../services/auth.service";
import { useHistory } from "react-router";

import api from "./../services/api";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import RegionAutocomplete from "./Drivers/RegionAutocomplete";

import {
  DataGrid,
  GridApi,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { Button, IconButton } from "@mui/material";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";

import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import Auth from "./../services/auth.service";

type DescriptionType = {
  id: number;
  address: string;
  createDate: string;
  description: string;
  region: string;
  status: "REQUESTED" | "COMPLETED";
  driverNickname: string;
};

const Header: React.FC = () => {
  const { state, setState } = useGlobalState();

  const history = useHistory();

  const [descriptions, setDescriptions] = useState<DescriptionType[]>([]);

  const [descriptionRequestCount, setDescriptionRequestCount] = useState(0);

  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   api.get("ticket/description/new-count").then((response) => {
  //     const data = response.data as any;

  //     setDescriptionRequestCount(data.newCount);
  //   });
  // }, [isOpen]);

  const [region, setRegion] = useState<string>("");

  // useEffect(() => {
  //   if (isOpen) {
  //     api
  //       .get("ticket/description", {
  //         params: {
  //           region: region,
  //         },
  //       })
  //       .then((response) => {
  //         const data = response.data as DescriptionType[];

  //         setDescriptions(data);
  //       });
  //   }
  // }, [isOpen, region]);

  // const columns: GridColDef[] = [
  //   {
  //     field: "createDate",
  //     headerName: "Data utworzenia",
  //     width: 200,
  //     sortable: false,
  //     valueGetter: (params: GridValueGetterParams) =>
  //       `${new Date(params.row.createDate).toLocaleTimeString("pl-PL") || ""} ${
  //         new Date(params.row.createDate).toLocaleDateString("pl-PL") || ""
  //       }`,
  //   },
  //   {
  //     field: "region",
  //     headerName: "Region",
  //     width: 150,
  //     sortable: false,
  //   },
  //   {
  //     field: "driverNickname",
  //     headerName: "Kierowca",
  //     width: 100,
  //     sortable: false,
  //   },
  //   {
  //     field: "address",
  //     headerName: "Adres",
  //     width: 300,
  //     sortable: false,
  //   },
  //   {
  //     field: "description",
  //     headerName: "Opis",
  //     width: 500,
  //     sortable: false,
  //   },
  //   {
  //     field: "action",
  //     headerName: "Action",
  //     sortable: false,
  //     renderCell: (params) => {
  //       const onClick = (e: any) => {
  //         e.stopPropagation(); // don't select this row after clicking

  //         const _api: GridApi = params.api;
  //         const thisRow: Record<string, GridCellValue> = {};

  //         _api
  //           .getAllColumns()
  //           .filter((c) => c.field !== "__check__" && !!c)
  //           .forEach(
  //             (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
  //           );

  //         api.patch("ticket/description/" + params.id).finally(() => {
  //           api.get("ticket/description").then((response) => {
  //             const data = response.data as DescriptionType[];

  //             setDescriptions(data);
  //           });
  //         });

  //         console.log(params.row.as);
  //         return;
  //       };

  //       return params.row?.status == "REQUESTED" ? (
  //         <Button color="success" onClick={onClick}>
  //           Zakończ
  //         </Button>
  //       ) : (
  //         <></>
  //       );
  //     },
  //   },
  // ];

  const HeaderButtons = () => {
    const Trasy = () => (
      <div className="nk-header-news d-none d-xl-block">
        <a
          className="btn btn-primary btn-lg text-white"
          onClick={async () => {
            history.push("/");
          }}
        >
          <em className="icon ni ni-list-round" /> <span>Trasy</span>
        </a>
      </div>
    );

    const Kierowcy = () => (
      <div className="nk-header-news d-none d-xl-block">
        <a
          className="btn btn-primary btn-lg text-white"
          onClick={async () => {
            history.push("/DriverUsers");
          }}
        >
          <em className="icon ni ni-user-alt-fill"></em> <span>Kierowcy</span>
        </a>
      </div>
    );

    const WyszukiwarkaZdjec = () => (
      <div className="nk-header-news d-none d-xl-block">
        <a
          className="btn btn-primary btn-lg text-white"
          onClick={async () => {
            history.push("/Photos");
          }}
        >
          <em className="icon ni ni-camera" /> <span>Wyszukiwarka zdjęć</span>
        </a>
      </div>
    );

    const Wyplaty = () => (
      <div className="nk-header-news d-none d-xl-block">
        <a
          className="btn btn-primary btn-lg text-white"
          onClick={async () => {
            history.push("/Payments");
          }}
        >
          <em className="icon ni ni-coins" /> <span>Wypłaty</span>
        </a>
      </div>
    );

    const Skanowanie = () => (
      <div className="nk-header-news d-none d-xl-block">
        <a
          className="btn btn-primary btn-lg text-white"
          onClick={async () => {
            history.push("/Analytics");
          }}
        >
          <em className="icon ni ni-scan"></em>
          <span>Skanowanie</span>
        </a>
      </div>
    );

    const UwagiKary = () => (
      <div className="nk-header-news d-none d-xl-block">
        <a
          className="btn btn-primary btn-lg text-white"
          onClick={async () => {
            history.push("/Reports");
          }}
        >
          <em className="icon ni ni-notes-alt" /> <span>Uwagi/Kary/Dodatki/Informacje</span>
        </a>
      </div>
    );

    const IssueDiets = () => (
      <div className="nk-header-news d-none d-xl-block">
        <a
          className="btn btn-primary btn-lg text-white"
          onClick={async () => {
            history.push("/IssueDiets");
          }}
        >
          <em className="icon ni ni-alert-fill"></em> <span>Zgłoszone diety</span>
        </a>
      </div>
    );

console.log(state.role)

    if(state.role == "Admin")
    {
      return(
        <>
          <Trasy/>
          <Kierowcy/>
          <WyszukiwarkaZdjec/>
          <Wyplaty/>
          <Skanowanie/>
          <UwagiKary/>
          <IssueDiets/>
        </>
      )
    }
    else if(state.role == "Suboffice")
    {
      return(
        <>
          <Trasy/>
          <Kierowcy/>
          <WyszukiwarkaZdjec/>
          <UwagiKary/>
          <IssueDiets/>
        </>
      )
    }
    else
    {
      return(
        <>
          <Trasy/>
          <Kierowcy/>
          <WyszukiwarkaZdjec/>
          <Wyplaty/>
          <Skanowanie/>
          <UwagiKary/>
          <IssueDiets/>
        </>
      )
    }
  };

  return (
    <div
      className="nk-header nk-header-fixed is-light"
      style={{ position: "unset" }}
    >
      {/* <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        style={{
          "--width": "1400px",
          "--height": "1000px",
        }}
      >
        <IonContent className="ion-padding">
          <div
            className="row justify-content-center"
            style={{
              paddingTop: "20px",
            }}
          >
            <div className="col-auto">
              <RegionAutocomplete setRegion={setRegion} />
            </div>
          </div>
          <div
            className="row"
            style={{
              margin: "0 5px",
            }}
          >
            <div className="col-12">
              <Box sx={{ height: 850, width: "100%", marginTop: "50px" }}>
                <DataGrid
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 400}
                  rows={descriptions}
                  columns={columns}
                  // pageSize={14}
                  pageSizeOptions={[5]}
                  disableRowSelectionOnClick
                  hideFooter
                />
              </Box>
            </div>
          </div>
        </IonContent>
      </IonModal> */}

      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <a
              href="#"
              className="nk-nav-toggle nk-quick-nav-icon"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-menu" />
            </a>
          </div>
          {/* <div className="nk-header-brand d-xl-none">
            <a href="html/index.html" className="logo-link">
              <img
                className="logo-light logo-img"
                src="./images/logo.png"
                srcSet="./images/logo2x.png 2x"
                alt="logo"
              />
              <img
                className="logo-dark logo-img"
                src="./images/logo-dark.png"
                srcSet="./images/logo-dark2x.png 2x"
                alt="logo-dark"
              />
            </a>
          </div> */}
          {/* <div className="nk-header-news d-none d-xl-block">
            <div className="nk-news-list">
              <a className="nk-news-item" href="#">
                <div className="nk-news-icon">
                  <em className="icon ni ni-card-view" />
                </div>
                <div className="nk-news-text">
                  <p>
                    Do you know the latest update of 2022?{" "}
                    <span> A overview of our is now available on YouTube</span>
                  </p>
                  <em className="icon ni ni-external" />
                </div>
              </a>
            </div>
          </div> */}
          {/* .nk-header-news */}

          {/* <div className="nk-header-news d-none d-xl-block">
            <a
              className="btn btn-primary btn-lg text-white"
              onClick={async () => {
                history.push("/IssueDiet");
              }}
            >
              <em className="icon ni ni-alert-c" /> <span>Zgłoszenia</span>
            </a>
          </div> */}
          {/* <div className="nk-header-news d-none d-xl-block">
            <a
              className="btn btn-primary btn-lg text-white"
              onClick={async () => {
                history.push("/Reports");
              }}
            >
              <span>Uwagi kierowców</span>
            </a>
          </div> */}

          {/* <div className="nk-header-news d-none d-xl-block">
            <a
              className={
                "btn btn-lg text-white btn-" +
                (descriptionRequestCount > 0 ? "success" : "primary")
              }
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {descriptionRequestCount > 0 ? (
                <span>
                  Zmiana opisu - {descriptionRequestCount} nowe prośby
                </span>
              ) : (
                <span>Zmiana opisu</span>
              )}
            </a>
          </div> */}

          <HeaderButtons/>

          <div className="nk-header-tools">
            <a
              className="btn btn-secondary btn-lg text-white"
              onClick={async () => {
                auth.logout().finally(() => {
                  setTimeout(() => {
                    history.replace("/login");
                  }, 1);
                });
              }}
            >
              <em className="icon ni ni-undo" /> <span>Wyloguj się</span>
            </a>

            {/* .nk-quick-nav */}
          </div>
          <div>
            <IonButtons slot="end">
              <IconButton
                sx={{ ml: 1 }}
                onClick={() => {
                  const bodyClasses = document.querySelector("body");

                  if (bodyClasses?.classList.contains("dark")) {
                    document.body.classList.remove("dark");
                  } else {
                    document.body.classList.add("dark");
                  }

                  if (bodyClasses?.classList.contains("dark-mode")) {
                    document.body.classList.remove("dark-mode");
                  } else {
                    document.body.classList.add("dark-mode");
                  }

                  setState((prev) => ({
                    ...prev,
                    ...{
                      mode: state.mode === "dark" ? "light" : "dark",
                    },
                  }));
                }}
                color="inherit"
              >
                {state.mode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
            </IonButtons>
          </div>

          {/* .nk-header-tools */}
        </div>
        {/* .nk-header-wrap */}
      </div>
      {/* .container-fliud */}
    </div>
  );
};

export default Header;
