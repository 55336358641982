import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonReorderGroup,
  IonReorder,
  IonModal,
  IonProgressBar,
  useIonLoading,
  useIonAlert,
  IonRow,
  IonCol,
} from "@ionic/react";
import "./Payments.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { RouteComponentProps, useHistory } from "react-router";

import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";
import Header from "../components/Header";

import { v4 as uuidv4 } from "uuid";
import {
  calendar,
  colorPalette,
  colorPaletteOutline,
  ellipsisHorizontal,
  ellipsisHorizontalCircleOutline,
  lockClosed,
  searchOutline,
  trash,
  trashBin,
} from "ionicons/icons";

import { useDropzone } from "react-dropzone";
import api from "./../services/api";

import TextField from "@mui/material/TextField";
import {
  Button,
  Chip,
  debounce,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Popover,
  Select,
  Skeleton,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NavigationIcon from "@mui/icons-material/Navigation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RegionAutocomplete from "../components/Drivers/RegionAutocomplete";
import MapRead, { MapRefProps } from "../components/MapRead";

import Menu from "@mui/icons-material/Menu";

import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridToolbar,
  GridToolbarQuickFilter,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";

import { ChromePicker, SketchPicker } from "react-color";
import CsvList from "../components/CsvList";
import DriverUsersAutocomplete from "../components/DriverReports/DriverUsersAutocomplete";
import ContractTypeAutocomplete, {
  ContractType,
} from "../components/Payments/PaymentProfileModal/ContractTypeAutocomplete";
import { GenerateGUID, NumberToMoneyString } from "../components/Common";
import { NumericFormat } from "react-number-format";
import TextFieldDate from "../components/TextFieldDate";
import DriverUsersWithoutPaymentProfileAutocomplete from "../components/Payments/PaymentProfileModal/DriverUsersWithoutPaymentProfileAutocomplete";
import NumericFormatEditable from "../components/NumericFormatEditable";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { StaticDatePicker } from "@mui/x-date-pickers";
import CustomAutocomplete from "../components/CustomAutocomplete";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import useAxios from "axios-hooks";
import PaymentsMonthsSelect from "../components/Payments/PaymentsMonthsSelect";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";

import dayjs from "dayjs";
import "dayjs/locale/pl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

type PaymentCalculationFuelCostResponse = {
  driverName: string;
  kmPrivateDomesticCost: number;
  kmWork: number;
  kmPrivate: number;
  averageFuelConsumption: number;
  ecologicIndex?: number;
};

type PaymentCalculationStopsTableDeliveryResponse = {
  id: string;
  url: string;
  deliveryDate: string;
  totalPoints: number;
  donePoints: number;
  doneByOthers: number;
  undonePoints: number;
  backupDiets: number;
  duplicates: number;
  compensationPoints: number;
  overall: number;
};

type PaymentCalculationStopsTableResponse = {
  routeName: string;
  deliveries: PaymentCalculationStopsTableDeliveryResponse[];
};

type PaymentDateAndListResponse = {
  date: string;
  list: PaymentResponse[];
};

type PaymentResponse = {
  id: number;
  userName: string;
  contractId: number;
  contractName: string;
  startDate: string;
  endDate: string | undefined;
  personalBonusSalary: number;
  isMonthlyCalculationGenerated: boolean;
  monthlyCalculationGeneratedId: number;
  monthlyCalculationActive: boolean;
  backgroundColor: string;
  textColor: string;
  statusInfo: string;
  canRemove: boolean;
  canEnd: boolean;
  isCaptain: boolean;

  ecologicIndex: number | undefined;
  fuelConsumption: number | undefined;

  attendanceBonus: number;
  workingDaysInMonthCount: number;
  ecologicIndexBonus: number;
  fuelConsumptionBonus: number;

  contractBonusSalary: number;
};

type PaymentCalculationNote = {
  id: number;
  cost: number;
  title: string;
  addDate: string;
  noteAssignmentId: 1 | 2 | 3;
  noteAssignmentName: string;
  description: string;
  comments: PaymentCalculationNoteComment[];
};

type PaymentCalculationNoteComment = {
  id: number;
  addedBy: string;
  createdDate: string;
  description: string;
};

type PaymentCalculationInfo = {
  color:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  description: string;
};

type PaymentCalculation = {
  calculationId: number | undefined;
  profileId: number;
  firstName: string;
  lastName: string;

  workingDaysInMonthCount: number;
  paymentDate: string;
  paydaysCount: number;
  personalBonusSalary: number;
  contractBaseSalary: number;
  contractName: string;
  contractAmountPerPoint: number;
  contractBonusSalary: number;
  vacationPayedCount: number;
  vacationFreeCount: number;
  contractBaseSalaryCalculated: number;

  correctionAmount: number;
  summaryNote: string;

  pointsAmount: number;
  pointsAmountCalculated: number;

  pointsWithBaseSalaryCalculated: number;

  punishmentCost: number;
  punishmentExtraCost: number;

  fuelCost: number;

  salaryCalculated: number;

  paydaysFromDatabase: string[];

  notes: PaymentCalculationNote[];
  infos: PaymentCalculationInfo[];

  stopsTable: PaymentCalculationStopsTableResponse[];

  ecologicIndexBonus: number;
  fuelConsumptionBonus: number;
  regionCaptainBonus: number;
  attendanceBonus: number;
  allowance: number;
};

const ShallowEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
};

const Payments: React.FC<RouteComponentProps> = ({ match }) => {
  const [paymentCalculationLoading, setPaymentCalculationLoading] =
    useState(false);

  const [paymentCalculation, setPaymentCalculation] =
    useState<PaymentCalculation>();

  const [paymentCalculationEcologic, setPaymentCalculationEcologic] =
    useState<PaymentCalculationFuelCostResponse>();

  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [paymentModalVisibleOnlyRead, setPaymentModalVisibleOnlyRead] =
    useState(false);

  const [valueStopsTableTabs, setValueStopsTableTabs] = React.useState("0");

  const handleChangeStopsTableTabs = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setValueStopsTableTabs(newValue);
  };

  const [anchorElStopsTablePopover, setAnchorElStopsTablePopover] =
    React.useState<HTMLButtonElement | null>(null);
  const handleClickStopsTablePopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElStopsTablePopover(event.currentTarget);
  };
  const handleCloseStopsTablePopover = () => {
    setAnchorElStopsTablePopover(null);
  };
  const openStopsTablePopover = Boolean(anchorElStopsTablePopover);
  const idStopsTablePopover = openStopsTablePopover
    ? "StopsTable-popover"
    : undefined;

  const [
    paymentCalculationFuelCostLoading,
    setPaymentCalculationFuelCostLoading,
  ] = useState(false);
  const [
    paymentCalculationFuelCostResponse,
    setPaymentCalculationFuelCostResponse,
  ] = useState<PaymentCalculationFuelCostResponse[]>([]);

  // const [anchorElFuelCostPopover, setAnchorElFuelCostPopover] =
  //   React.useState<HTMLButtonElement | null>(null);
  // const handleClickFuelCostPopover = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   setAnchorElFuelCostPopover(event.currentTarget);
  // };
  // const handleCloseFuelCostPopover = () => {
  //   setPaymentCalculationFuelCostResponse([]);
  //   setAnchorElFuelCostPopover(null);
  // };
  // const openFuelCostPopover = Boolean(anchorElFuelCostPopover);
  // const idFuelCostPopover = openFuelCostPopover
  //   ? "FuelCostPopover-popover"
  //   : undefined;

  const [isPaymentModalWorkDatesVisible, setIsPaymentModalWorkDatesVisible] =
    useState(false);

  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const map = useRef<MapRefProps>(null);

  const [presentAlert] = useIonAlert();

  const [showMap, setShowMap] = useState(false);

  const [region, setRegion] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("Wszystko");

  const [presentLoading, dismissLoading] = useIonLoading();

  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const history = useHistory();

  const [dateTitle, setDateTitle] = useState("");

  const [isPaymentProfileModalVisible, setIsPaymentProfileModalVisible] =
    useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [returnedPassword, setReturnedPassword] = useState("");

  const [userDriver, setUserDriver] = useState<string>("");
  const [userDriverId, setUserDriverId] = useState<string | undefined>("");

  const [contractType, setContractType] = useState<string>("");
  const [contractTypeData, setContractTypeData] = useState<ContractType>();

  const [personalBonusSalary, setPersonalBonusSalary] = useState("0.00");

  const [isCaptain, setIsCaptain] = useState(false);

  const [contractStartDate, setContractStartDate] = useState<string>("");

  const [
    contractDriverUsersAutocompleteActiveChecked,
    setContractDriverUsersAutocompleteActiveChecked,
  ] = useState(false);

  useIonViewDidLeave(() => {
    setShowMap(false);
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let tempFiles = files;

      for (const _file of acceptedFiles) {
        tempFiles.push(_file);
      }

      setFiles([]);
      setFiles(tempFiles);
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  // useEffect(() => {
  //   if (drivers.length <= 0) {
  //     const temp<Drivers> = [];

  //     for (let i = 0; i < 60; i++) {
  //       tempDrivers.push("");
  //     }

  //     setDrivers(tempDrivers);
  //   }
  // }, []);

  const { setState, state } = useGlobalState();

  type DriverPoint = {
    postCode: string;
    city: string;
    street: string;
    houseNumber: string;
  };

  type Driver = {
    id: string;
    role: string;
    name: string;
    routeId: string;
    region: string;
    status: boolean;
    password: string;
    color: string;
    colorLight: string;
    startPoint: DriverPoint;
    endPoint: DriverPoint;
    successPoints: number;
    warningPoints: number;
    errorPoints: number;

    hidden: boolean;

    donePoints: number;
    proccessingPoints: number;
    undonePoints: number;

    active: boolean;
  };

  // const getData = async () => {
  //   presentLoading({
  //     spinner: "crescent",
  //     message: "Pobieranie danych...",
  //     duration: 20000,
  //   });

  //   await api
  //     .get("payments")
  //     .then((response) => {
  //       if (response.data) {
  //         setDateTitle(response.data.date);
  //         setPayments(response.data.list);
  //       }
  //     })
  //     .finally(() => {
  //       dismissLoading();
  //     });
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  const [payments, setPayments] = useState<PaymentResponse[]>([]);

  const [selectMonth, setSelectMonth] = useState("");
  const [selectMonthId, setSelectMonthId] = useState<Date>();

  const [{ data, loading, error }, refetch] =
    useAxios<PaymentDateAndListResponse>({
      url: "payments",
      params: {
        date: selectMonthId,
      },
    });

  useEffect(() => {
    if (data && selectMonthId) {
      setPayments(data.list);
      setDateTitle(data.date);
    }
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: "userName",
      headerName: "Kierowca",
      // width: 150,
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "contractName",
      headerName: "Rodzaj zatrudnienia",
      // width: 150,
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "startDate",
      headerName: "Data rozpoczęcia pracy",
      // width: 150,
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "endDate",
      headerName: "Data zakończenia pracy",
      // width: 150,
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "isCaptain",
      headerName: "Kapitan regionu",
      // width: 150,
      minWidth: 30,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      type: "boolean",
    },
    {
      field: "additionalInfo",
      headerName: "Szczegóły",
      // width: 150,
      minWidth: 800,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.isMonthlyCalculationGenerated) {
          
            return (
              <Grid spacing={2} container>
                <Grid item xs={4}>
                  <Stack direction="column" sx={{ py: "3px" }}>
                    <Stack>
                      <Typography>
                        Ecologic Index:{" "}
                        {params.row.ecologicIndex == undefined ? (
                          "-"
                        ) : (
                          <strong>{params.row.ecologicIndex}</strong>
                        )}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography>
                        Zużycie paliwa:{" "}
                        {params.row.fuelConsumption == undefined ? (
                          "-"
                        ) : (
                          <strong>{params.row.fuelConsumption}</strong>
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack direction="column" sx={{ py: "3px" }}>
                    <Stack>
                      <Typography>
                        Ecologic Index bonus:{" "}
                        <strong>{params.row.ecologicIndexBonus} zł</strong>
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography>
                        Zużycie paliwa bonus:{" "}
                        <strong>{params.row.fuelConsumptionBonus} zł</strong>
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack direction="column" sx={{ py: "3px" }}>
                    <Stack>
                      <Typography>
                        Przepracowane dni:{" "}
                        <strong>{params.row.workingDaysInMonthCount}</strong>
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography>
                        Premia za frekwencję:{" "}
                        <strong>{params.row.attendanceBonus} zł</strong>
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography>
                        Premia za brak kar:{" "}
                        <strong>{params.row.contractBonusSalary} zł</strong>
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            );
          
        }
      },
    },
    // {
    //   field: "fuelConsumption",
    //   headerName: "Zużycie paliwa",
    //   // width: 150,
    //   minWidth: 30,
    //   flex: 1,
    //   editable: false,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "ecologicIndexBonus",
    //   headerName: "Ecologic Index bonus",
    //   // width: 150,
    //   minWidth: 30,
    //   flex: 1,
    //   editable: false,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "fuelConsumptionBonus",
    //   headerName: "Bonus za zużycie paliwa",
    //   // width: 150,
    //   minWidth: 30,
    //   flex: 1,
    //   editable: false,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "attendanceBonus",
    //   headerName: "Frekwencja",
    //   // width: 150,
    //   minWidth: 30,
    //   flex: 1,
    //   editable: false,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "personalBonusSalary",
    //   headerName: "Premia indywidualna",
    //   // width: 150,
    //   minWidth: 150,
    //   flex: 1,
    //   editable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: (params: GridRenderCellParams<any>) =>
    //     NumberToMoneyString(params.row.personalBonusSalary),
    // },
    // {
    //   field: "statusInfo",
    //   headerName: "Status",
    //   // width: 150,
    //   minWidth: 100,
    //   flex: 1,
    //   editable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: (params: GridRenderCellParams<any>) => (
    //     <span
    //       style={{
    //         color: "#" + params.row.textColor,
    //         textTransform: "uppercase",
    //         fontWeight: 500,
    //         letterSpacing: "1px",
    //       }}
    //     >
    //       {params.row.statusInfo}
    //     </span>
    //   ),
    // },
    {
      field: "action1",
      headerName: "Wypłata",
      minWidth: 400,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      renderCell: (params: GridRenderCellParams<any>) =>
        params.row.statusInfo ? (
          <></>
        ) : params.row.isMonthlyCalculationGenerated ? (
          <>
            {params.row.monthlyCalculationActive ? (
              <></>
            ) : (
              <Button
                onClick={async () => {
                  await presentLoading("Akceptowanie wypłaty...");

                  api
                    .patch(
                      "payments/" +
                        params.row.monthlyCalculationGeneratedId +
                        "/calculation-activate"
                    )
                    .finally(async () => {
                      await dismissLoading();
                    });
                }}
                variant="outlined"
                color="success"
                // endIcon={<NavigationIcon />}
              >
                Akceptuj
              </Button>
            )}

            <Button
              className="ml-2"
              onClick={async () => {
                await presentLoading("Ściąganie danych do wypłaty...");

                api
                  .get(
                    "payments/" +
                      params.row.monthlyCalculationGeneratedId +
                      "/calculation-info-view"
                  )
                  .then(async (response) => {
                    await dismissLoading();
                    setPaymentModalVisibleOnlyRead(true);
                    setPaymentCalculation(response.data);
                    setIsPaymentModalVisible(true);
                  })
                  .catch(async () => {
                    await refetch();
                    await dismissLoading();
                  });
              }}
              variant="outlined"
              color="info"
              // endIcon={<NavigationIcon />}
            >
              Zobacz
            </Button>
            <Button
              className="ml-2"
              onClick={async () => {
                await presentLoading("Ściąganie danych do wypłaty...");

                api
                  .get(
                    "payments/" +
                      params.row.monthlyCalculationGeneratedId +
                      "/calculation-info-edit"
                  )
                  .then(async (response) => {
                    await dismissLoading();
                    setPaymentCalculation(response.data);
                    setIsPaymentModalVisible(true);
                  })
                  .catch(async () => {
                    await dismissLoading();
                  });
              }}
              variant="outlined"
              color="secondary"
              // endIcon={<NavigationIcon />}
            >
              Edytuj
            </Button>
            <Button
              className="ml-2"
              onClick={async () => {
                await presentLoading("Usuwanie wypłaty...");

                api
                  .delete(
                    "payments/" +
                      params.row.monthlyCalculationGeneratedId +
                      "/calculation"
                  )
                  .finally(async () => {
                    await refetch();
                    await dismissLoading();
                  });
              }}
              variant="contained"
              color="error"
              // endIcon={<NavigationIcon />}
            >
              Usuń
            </Button>
            {/* <Button
              className="ml-2"
              onClick={async () => {
                await presentLoading("Ściąganie danych do wypłaty...");

                api
                  .get("payments/" + params.row.id + "/calculation-info")
                  .then(async (response) => {
                    await dismissLoading();
                    setPaymentCalculation(response.data);
                    setIsPaymentModalVisible(true);
                  })
                  .catch(async () => {
                    await dismissLoading();
                  });
              }}
              variant="contained"
              // endIcon={<NavigationIcon />}
            >
              Wygeneruj nową
            </Button> */}
          </>
        ) : (
          <Button
            onClick={async () => {
              await presentLoading("Ściąganie danych do wypłaty...");

              api
                .get("payments/" + params.row.id + "/calculation-info", {
                  params: {
                    date: selectMonthId,
                  },
                })
                .then(async (response) => {
                  await dismissLoading();
                  setPaymentCalculation(response.data);
                  setIsPaymentModalVisible(true);
                })
                .catch(async () => {
                  await dismissLoading();
                });
            }}
            variant="contained"
            // endIcon={<NavigationIcon />}
          >
            Wypłata
          </Button>
        ),
    },
    {
      field: "action2",
      headerName: "Profil",
      minWidth: 100,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          {/* {params.row.canEnd ? (
            <PopupState
                        variant="popover"
                        popupId="cost-popup-popover"
                      >
                        {(popupState) => (
                          <div>
                            <Button
                              {...bindTrigger(popupState)}
                              className="show-on-hover"
                              // onClick={() => {
                              //   setRows(
                              //     rows.map((e) =>
                              //       e.id == params.row.id
                              //         ? { ...e, isEditablePunishmentCost: true }
                              //         : e
                              //     )
                              //   );
                              // }}
                              color="error"
                              aria-label="change punishment cost"
                              component="label"
                              variant="outlined"
                              sx={{
                                marginRight: "15px"
                              }}
                            >
                              Zakończ z datą
                            </Button>
                            <Popover
                              style={{
                                padding: "30px",
                              }}
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <div
                                style={{
                                  margin: "15px",
                                  display: "inline-flex",
                                }}
                              >
                                {params.row.userName}
                                <StaticDatePicker defaultValue={dayjs('2022-04-17')} />
                              </div>
                            </Popover>
                          </div>
                        )}
                      </PopupState>
            
          ) : (
            <></>
          )} */}
          {params.row.canRemove ? (
            <Button
              endIcon={<DeleteForeverIcon />}
              onClick={async () => {
                await presentLoading("Usuwanie profilu...");

                api
                  .delete("payments/profile/" + params.row.id)
                  .finally(async () => {
                    await refetch();
                    await dismissLoading();
                  });
              }}
              variant="contained"
              color="error"
              // endIcon={<NavigationIcon />}
            >
              Usuń
            </Button>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const [colorValue, setColorValue] = useState<string>();
  const [colorDriverName, setColorDriverName] = useState<string>("");
  const [colorDriverId, setColorDriverId] = useState<string>("");

  const [searchValue, setSearchValue] = React.useState("");
  const apiRef = useGridApiRef();

  const updateSearchValue = React.useMemo(() => {
    return debounce((newValue) => {
      apiRef.current.setQuickFilterValues(
        newValue.split(" ").filter((word: string) => word !== "")
      );
    }, 200);
  }, [apiRef]);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );

  function handleSearchValueChange(event: any) {
    const newValue = event.target.value;
    setSearchValue(newValue);
    updateSearchValue(newValue);
  }

  const memoDataGrid = useMemo(() => {
    return (
      <DataGridPro
        style={{
          border: "1px solid rgb(219, 223, 234)",
        }}
        apiRef={apiRef}
        getRowClassName={(params) =>
          `background-color-${params.row.backgroundColor}`
        }
        isRowSelectable={(params: GridRowParams) =>
          !!params.row.routeId && !params.row.active
        }
        onRowSelectionModelChange={(newSelectionModel, details) => {
          setSelectionModel(newSelectionModel);
        }}
        rowSelectionModel={selectionModel}
        sx={{
          fontSize: "16px",
        }}
        rowHeight={100}
        rows={payments}
        columns={columns}
        hideFooter
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
        disableVirtualization
        // experimentalFeatures={{ newEditingApi: true }}
      />
    );
  }, [payments, selectionModel]);

  useEffect(() => {
    // await presentLoading("Ściąganie danych do wypłaty...");

    if (!paymentModalVisibleOnlyRead) {
      setPaymentCalculationLoading(true);

      api
        .post("payments/calculate", { ...paymentCalculation })
        .then(async (response) => {
          // await dismissLoading();

          const data = response.data as PaymentCalculation;

          if (JSON.stringify(paymentCalculation) != JSON.stringify(data)) {
            setPaymentCalculation(data);
          }
        })
        .catch(async () => {
          // await dismissLoading();
        })
        .finally(() => {
          setPaymentCalculationLoading(false);
        });
    }
  }, [paymentCalculation]);

  const [isExcelExportButtonLoading, setIsExcelExportButtonLoading] =
    useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pl"}>
      <IonPage>
        {/* <CustomHeaderFade
        page={"Strona główna"}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      /> */}
        <div
          id="payments-page-controller"
          className={"background-lightgrey slide-transition-leave nk-main"}
          style={{
            overflow: "auto",
          }}
        >
          <IonModal
            style={{
              "--height": "175px",
              "--wdith": "280px",
            }}
            isOpen={isPasswordOpen}
            onIonModalDidDismiss={() => setIsPasswordOpen(false)}
          >
            <IonRow>
              <IonCol size="12">
                <h3 style={{ textAlign: "center" }}>Nowe hasło dla</h3>
                <h3 style={{ textAlign: "center", margin: "0 0 10px" }}>
                  Pojazd {colorDriverName}
                </h3>
              </IonCol>
              <IonCol size="12">
                <h2
                  style={{
                    textAlign: "center",
                    fontSize: "50px",
                    letterSpacing: "3px",
                  }}
                >
                  {returnedPassword}
                </h2>
              </IonCol>
            </IonRow>
          </IonModal>

          <IonModal
            style={{
              "--width": "auto",
              "--height": "auto",
            }}
            // anchorEl={anchorEl}
            isOpen={isPickerOpen}
            onIonModalDidDismiss={() => setIsPickerOpen(false)}
            // anchorOrigin={{
            //   vertical: "bottom",
            //   horizontal: "left",
            // }}
            // transformOrigin={{
            //   vertical: "top",
            //   horizontal: "left",
            // }}
          >
            <IonRow>
              <IonCol size="12">
                <h3 style={{ textAlign: "center", margin: "15px 0px" }}>
                  Pojazd {colorDriverName}
                </h3>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <ChromePicker
                  color={colorValue}
                  onChange={(e) => {
                    setColorValue(e.hex);
                    console.log(e.hex);
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <Button
                  onClick={() => {
                    presentLoading();

                    api
                      .patch("drivers/color-change", {
                        DriverId: colorDriverId,
                        Color: colorValue,
                      })
                      .finally(() => {
                        dismissLoading();
                        setColorDriverName("");
                        setColorDriverId("");
                        setIsPickerOpen(false);
                      });
                  }}
                  variant="contained"
                  fullWidth
                  size="large"
                >
                  Akceptuj
                </Button>
              </IonCol>
            </IonRow>
          </IonModal>

          <Header />

          <div className="nk-wrap ">
            {/* main header @s */}

            {/* main header @e */}
            {/* content @s */}
            <div className="nk-content ">
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                      <div className="nk-block-between">
                        <div className="nk-block-head-content">
                          <h3 className="nk-block-title page-title">
                            Wypłaty kierowców za{" "}
                            <span style={{ textTransform: "uppercase" }}>
                              {dateTitle}
                            </span>
                          </h3>
                          <LoadingButton
                            onClick={() => {
                              setIsExcelExportButtonLoading(true);

                              api
                                .post("payments/generate-excel", {
                                  date: selectMonthId,
                                })
                                .then((response) => {
                                  console.log(response);

                                  // const url = window.URL.createObjectURL(
                                  //   new Blob([response.data], {
                                  //     type: response.headers["file-type"],
                                  //   })
                                  // );
                                  const link = document.createElement("a");
                                  link.href = response.data;
                                  link.setAttribute(
                                    "download",
                                    decodeURI(response.headers["file-name"])
                                  );
                                  document.body.appendChild(link);
                                  link.click();
                                })
                                .finally(() => {
                                  setIsExcelExportButtonLoading(false);
                                });
                            }}
                            loading={isExcelExportButtonLoading}
                            variant="text"
                            endIcon={<FileDownloadIcon />}
                          >
                            Pobierz raport do excela
                          </LoadingButton>
                          {/* <div className="nk-block-des text-soft">
                          <p>You have total 2,595 users.</p>
                        </div> */}
                        </div>
                        {/* .nk-block-head-content */}
                        <div className="nk-block-head-content">
                          <div className="toggle-wrap nk-block-tools-toggle">
                            <a
                              href="#"
                              className="btn btn-icon btn-trigger toggle-expand mr-n1"
                              data-target="pageMenu"
                            >
                              <em className="icon ni ni-menu-alt-r" />
                            </a>
                            {/* <div
                            className="toggle-expand-content"
                            data-content="pageMenu"
                          >
                            <ul className="nk-block-tools g-3">
                              <li>
                                <a
                                  href="#"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-download-cloud" />
                                  <span>Export</span>
                                </a>
                              </li>
                              <li className="nk-block-tools-opt">
                                <div className="drodown">
                                  <a
                                    href="#"
                                    className="dropdown-toggle btn btn-icon btn-primary"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-plus" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <a href="#">
                                          <span>Add User</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Add Team</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Import User</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div> */}
                          </div>
                          {/* .toggle-wrap */}
                        </div>
                        {/* .nk-block-head-content */}
                      </div>
                      {/* .nk-block-between */}
                    </div>
                    {/* .nk-block-head */}

                    <Paper elevation={0}>
                      <div className="nk-block">
                        <div className="card card-bordered card-stretch">
                          <div className="card-inner-group">
                            <div className="card-inner position-relative card-tools-toggle">
                              <div className="card-title-group">
                                {/* .card-tools */}
                                <div className="">
                                  <div className="row justify-content-between">
                                    <div className="col-auto">
                                      <PaymentsMonthsSelect
                                        setMonth={setSelectMonth}
                                        setMonthId={setSelectMonthId}
                                      />
                                    </div>
                                    <div className="col-auto">
                                      <Button
                                        variant="contained"
                                        onClick={() =>
                                          setIsPaymentProfileModalVisible(true)
                                        }
                                      >
                                        Dodaj profil wypłaty dla kierowcy
                                      </Button>
                                    </div>
                                  </div>
                                  {/* <ul className="btn-toolbar gx-1">
                                  <li>
                                    <a
                                      className="btn btn-primary btn-lg text-white"
                                      onClick={() => {
                                        setIsPaymentProfileModalVisible(true);
                                      }}
                                    >
                                      <em className="icon ni ni-plus" />{" "}
                                      <span>
                                        Dodaj profil wypłaty dla kierowcy
                                      </span>
                                    </a>
                                  </li>


                                </ul> */}

                                  <IonModal
                                    backdropDismiss={false}
                                    style={{
                                      "--height": "auto",
                                      "--width": "800px",
                                    }}
                                    isOpen={isPaymentProfileModalVisible}
                                    className=""
                                    onIonModalDidDismiss={() => {
                                      setIsPaymentProfileModalVisible(false);
                                      setContractTypeData(undefined);
                                      setContractType("");
                                      setUserDriver("");
                                      setContractStartDate("");
                                      setIsCaptain(false);
                                    }}
                                  >
                                    <>
                                      <div>
                                        <a
                                          className="close"
                                          style={{
                                            fontSize: "40px",
                                            float: "initial",
                                            cursor: "auto",
                                          }}
                                        >
                                          <em
                                            style={{
                                              marginLeft: "auto",
                                              display: "table",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              setIsPaymentProfileModalVisible(
                                                false
                                              )
                                            }
                                            className="icon ni ni-cross-sm"
                                          />
                                        </a>
                                        <div
                                          style={{
                                            paddingTop: 0,
                                          }}
                                          className="modal-body modal-body-md"
                                        >
                                          <div className="row mb-4">
                                            <div className="col-12">
                                              <h3>
                                                Dodawanie nowego profilu wypłat
                                              </h3>
                                            </div>
                                          </div>
                                          <div className="row ">
                                            <div className="col-12">
                                              {contractDriverUsersAutocompleteActiveChecked ? (
                                                <DriverUsersWithoutPaymentProfileAutocomplete
                                                  fullWidth
                                                  setDriver={setUserDriver}
                                                  setDriverId={setUserDriverId}
                                                />
                                              ) : (
                                                <DriverUsersAutocomplete
                                                  fullWidth
                                                  setDriver={setUserDriver}
                                                  setDriverId={setUserDriverId}
                                                />
                                              )}
                                            </div>
                                          </div>

                                          <div className="row justify-content-end mt-1">
                                            <div className="col-auto">
                                              <FormControlLabel
                                                labelPlacement="start"
                                                style={{ marginBottom: "0" }}
                                                control={
                                                  <Switch
                                                    value={
                                                      contractDriverUsersAutocompleteActiveChecked
                                                    }
                                                    onChange={(e) => {
                                                      setContractDriverUsersAutocompleteActiveChecked(
                                                        e.target.checked
                                                      );
                                                    }}
                                                  />
                                                }
                                                label={
                                                  <>
                                                    <div>
                                                      Pokaż tylko kierowców bez
                                                      aktywnego profilu
                                                    </div>
                                                  </>
                                                }
                                              ></FormControlLabel>
                                            </div>
                                          </div>

                                          <div className="row mt-4">
                                            <div className="col-12">
                                              <TextFieldDate
                                                setDate={setContractStartDate}
                                                label="Data rozpoczęcia pracy na profilu"
                                              />
                                            </div>
                                          </div>

                                          <div className="row mt-3">
                                            <div className="col-8">
                                              <ContractTypeAutocomplete
                                                disabled={!userDriver}
                                                fullWidth
                                                setContractType={
                                                  setContractType
                                                }
                                                setContractTypeData={
                                                  setContractTypeData
                                                }
                                              />
                                            </div>
                                            <div className="col-4 ">
                                              {/* <NumericFormat
                                                InputProps={{
                                                  endAdornment: <span>zł</span>,
                                                }}
                                                label="Dodatek do wypłaty"
                                                customInput={TextField}
                                                onValueChange={(values) =>
                                                  setPersonalBonusSalary(
                                                    values.value
                                                  )
                                                }
                                                value={personalBonusSalary}
                                                // you can define additional custom props that are all forwarded to the customInput e. g.
                                                variant="outlined"
                                                decimalScale={2}
                                                fixedDecimalScale
                                                id={GenerateGUID()}
                                                autoComplete={GenerateGUID()}
                                              /> */}
                                              <FormControlLabel
                                                labelPlacement="end"
                                                style={{
                                                  margin: "0",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  height: "100%",
                                                  color: "#364a63",
                                                }}
                                                control={
                                                  <Switch
                                                    color="success"
                                                    value={isCaptain}
                                                    onChange={(e) => {
                                                      setIsCaptain(
                                                        e.target.checked
                                                      );
                                                    }}
                                                  />
                                                }
                                                label={
                                                  <>
                                                    <div>Kapitan regionu</div>
                                                  </>
                                                }
                                              ></FormControlLabel>
                                            </div>
                                          </div>

                                          {contractTypeData ? (
                                            <div className="row mt-5">
                                              <div className="col-12">
                                                <Paper
                                                  elevation={3}
                                                  style={{
                                                    width: "500px",
                                                    margin: "auto",
                                                  }}
                                                >
                                                  <TableContainer>
                                                    <Table
                                                      sx={{ maxWidth: 500 }}
                                                      aria-label="simple table"
                                                    >
                                                      <TableBody>
                                                        <TableRow
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              { border: 0 },
                                                          }}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <strong>
                                                              Nazwa
                                                            </strong>
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            {
                                                              contractTypeData
                                                                ?.contract.name
                                                            }
                                                          </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              { border: 0 },
                                                          }}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <strong>
                                                              Podstawa stała
                                                            </strong>
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            {NumberToMoneyString(
                                                              contractTypeData
                                                                ?.contract
                                                                .baseSalary
                                                            )}
                                                          </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              { border: 0 },
                                                          }}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <strong>
                                                              Premia
                                                            </strong>
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            {NumberToMoneyString(
                                                              contractTypeData
                                                                ?.contract
                                                                .bonusSalary
                                                            )}
                                                          </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              { border: 0 },
                                                          }}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            <strong>
                                                              Stawka za jeden
                                                              stop
                                                            </strong>
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            {NumberToMoneyString(
                                                              contractTypeData
                                                                ?.contract
                                                                .amountPerPoint
                                                            )}
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                </Paper>
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}

                                          <div className="nk-modal-action justify-end">
                                            <ul className="btn-toolbar g-4 align-center">
                                              <li>
                                                <button
                                                  disabled={
                                                    !userDriver &&
                                                    !userDriverId &&
                                                    !contractStartDate &&
                                                    !contractType &&
                                                    !contractTypeData
                                                  }
                                                  onClick={() => {
                                                    api
                                                      .post("payments", {
                                                        userDriverId:
                                                          userDriverId,
                                                        contractStartDate:
                                                          contractStartDate,
                                                        contractTypeId:
                                                          contractTypeData
                                                            ?.contract.id,
                                                        // personalBonusSalary:
                                                        //   personalBonusSalary,
                                                        isCaptain: isCaptain,
                                                      })
                                                      .then(async (e) => {
                                                        // const data = e.data;

                                                        // setIsAddUserOpen(false);

                                                        await refetch();
                                                        await dismissLoading();

                                                        setIsPaymentProfileModalVisible(
                                                          false
                                                        );
                                                      })
                                                      .catch((e) => {
                                                        const data =
                                                          e.response.data
                                                            .errors;

                                                        presentAlert(
                                                          "Error: " +
                                                            JSON.stringify(data)
                                                        );
                                                      })
                                                      .finally(() => {
                                                        dismissLoading();
                                                      });
                                                  }}
                                                  type="button"
                                                  className="btn btn-primary btn-lg mt-4"
                                                >
                                                  Dodaj profil kierowcy
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  </IonModal>

                                  <IonModal
                                    style={{
                                      "--height": "auto",
                                      "--width": "auto",
                                    }}
                                    isOpen={isPaymentModalWorkDatesVisible}
                                    onIonModalDidDismiss={() => {
                                      setIsPaymentModalWorkDatesVisible(false);
                                      setValueStopsTableTabs("0");
                                    }}
                                  >
                                    <div style={{ padding: "20px 30px" }}>
                                      {paymentCalculation?.paydaysFromDatabase.map(
                                        (e) => {
                                          return (
                                            <div style={{ fontSize: "22px" }}>
                                              {e}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </IonModal>

                                  <IonModal
                                    backdropDismiss={false}
                                    // style={{
                                    //   "--height": "auto",
                                    //   "--width": "1800px",
                                    // }}
                                    isOpen={isPaymentModalVisible}
                                    className="payment-modal"
                                    onIonModalWillPresent={() => {
                                      api
                                        .get("payments/ecologic-private-km", {
                                          params: {
                                            date: selectMonthId,
                                          },
                                        })
                                        .then((response) => {
                                          const data =
                                            response.data as PaymentCalculationFuelCostResponse[];

                                          setPaymentCalculationFuelCostResponse(
                                            data
                                          );

                                          if (paymentCalculation) {

                                            const value = data.find(
                                              (e) =>
                                                e.driverName
                                                  .toLocaleLowerCase()
                                                  .includes(
                                                    paymentCalculation?.firstName.toLocaleLowerCase()
                                                  ) &&
                                                e.driverName
                                                  .toLocaleLowerCase()
                                                  .includes(
                                                    paymentCalculation?.lastName.toLocaleLowerCase()
                                                  )
                                            );

                                            if (value) {
                                              const route = value;

                                              if (paymentCalculation && route) {
                                                api
                                                  .get(
                                                    "payments/" +
                                                      paymentCalculation?.profileId +
                                                      "/calculate-fuel-index-bonus",
                                                    {
                                                      params: {
                                                        date: selectMonthId,
                                                        fuelConsumption:
                                                          route.averageFuelConsumption,
                                                        ecologicIndex:
                                                          route.ecologicIndex,
                                                        distance: route.kmWork,
                                                      },
                                                    }
                                                  )
                                                  .then(async (response) => {
                                                    type CalculateFuelIndexBonusProps =
                                                      {
                                                        fuelConsumptionBonus: number;
                                                        ecologicIndexBonus: number;
                                                      };

                                                    const data =
                                                      response.data as CalculateFuelIndexBonusProps;

                                                    setPaymentCalculationEcologic(
                                                      {
                                                        averageFuelConsumption:
                                                          route.averageFuelConsumption,
                                                        kmPrivate:
                                                          route.kmPrivate,
                                                        kmWork: route.kmWork,
                                                        kmPrivateDomesticCost:
                                                          route.kmPrivateDomesticCost,
                                                        ecologicIndex:
                                                          route.ecologicIndex,
                                                        driverName:
                                                          route.driverName,
                                                      }
                                                    );

                                                    setPaymentCalculation({
                                                      ...paymentCalculation,
                                                      fuelCost:
                                                        route.kmPrivateDomesticCost,
                                                      ecologicIndexBonus:
                                                        data.ecologicIndexBonus,
                                                      fuelConsumptionBonus:
                                                        data.fuelConsumptionBonus,
                                                    });
                                                  })
                                                  .finally(async () => {
                                                    await dismissLoading();
                                                  });
                                              } else {
                                                setPaymentCalculationEcologic(
                                                  undefined
                                                );
                                              }
                                            }
                                          }
                                        })
                                        .finally(() => {
                                          setPaymentCalculationFuelCostLoading(
                                            false
                                          );
                                        });
                                    }}
                                    onIonModalDidDismiss={() => {
                                      setIsPaymentModalVisible(false);
                                      setPaymentModalVisibleOnlyRead(false);

                                      setPaymentCalculationEcologic(undefined);
                                    }}
                                  >
                                    {/* <Popover
                                                      disablePortal
                                                      id={idFuelCostPopover}
                                                      open={openFuelCostPopover}
                                                      onClose={
                                                        handleCloseFuelCostPopover
                                                      }
                                                      anchorOrigin={{
                                                        vertical: "center",
                                                        horizontal: "center",
                                                      }}
                                                      transformOrigin={{
                                                        vertical: "center",
                                                        horizontal: "center",
                                                      }}
                                                    >
                                                      <Box
                                                        sx={{
                                                          padding:
                                                            "0 20px 15px",
                                                          width: "100%",
                                                          typography: "body1",
                                                        }}
                                                      >
                                                        <Box
                                                        sx={{
                                                          padding: "25px 10px"
                                                        }}
                                                        >
<LoadingButton
                                                                                                                loading={paymentCalculationFuelCostLoading}

                                                          variant="contained"
                                                          fullWidth
                                                          onClick={() => {

                                                            setPaymentCalculationFuelCostLoading(true);

                                                            api
                                                              .get(
                                                                "payments/ecologic-fuel-cost"
                                                              )
                                                              .then(
                                                                (response) => {
                                                                  const data =
                                                                    response.data as PaymentCalculationFuelCostResponse[];

                                                                  setPaymentCalculationFuelCostResponse(
                                                                    data
                                                                  );
                                                                }
                                                              ).finally(() => {
                                                                setPaymentCalculationFuelCostLoading(false);
                                                              });
                                                          }}
                                                        >
                                                          Pobierz dane z
                                                          Ecologic
                                                        </LoadingButton>
                                                        </Box>
                                                        

                                                        
                                                        
                                                      </Box>
                                                    </Popover> */}

                                    <>
                                      <div>
                                        <div className="close-container">
                                          <a
                                            className="close"
                                            style={{
                                              fontSize: "55px",
                                              float: "initial",
                                              cursor: "auto",
                                            }}
                                          >
                                            <em
                                              style={{
                                                marginLeft: "auto",
                                                display: "table",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setIsPaymentModalVisible(false);
                                                setPaymentModalVisibleOnlyRead(
                                                  false
                                                );
                                              }}
                                              className="icon ni ni-cross-sm"
                                            />
                                          </a>
                                        </div>
                                        <div
                                          style={{
                                            paddingTop: 0,
                                          }}
                                          className="modal-body modal-body-md"
                                        >
                                          <div className="row justify-content-between mb-4">
                                            <div className="col-auto">
                                              <h3>Kalkulacja wypłaty</h3>
                                            </div>
                                          </div>

                                          <TableContainer className="mb-3">
                                            <Table aria-label="simple table">
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell align="center">
                                                    Przypisz dane z Ecologic
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Koszt paliwa (prywatne km)
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Średnie spalanie
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Przejechane prywatne km
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Przejechane km na trasie
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Ecologic Index
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      { border: 0 },
                                                  }}
                                                >
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                    sx={{
                                                      minWidth: "300px",
                                                    }}
                                                  >
                                                    <CustomAutocomplete
                                                      disabled={
                                                        paymentCalculationFuelCostResponse.length <=
                                                        0
                                                      }
                                                      label="Trasy"
                                                      onChange={(
                                                        event,
                                                        value
                                                      ) => {
                                                        const route =
                                                          paymentCalculationFuelCostResponse.find(
                                                            (e) =>
                                                              e.driverName ==
                                                              value
                                                          );

                                                        if (
                                                          paymentCalculation &&
                                                          route
                                                        ) {
                                                          api
                                                            .get(
                                                              "payments/" +
                                                                paymentCalculation?.profileId +
                                                                "/calculate-fuel-index-bonus",
                                                              {
                                                                params: {
                                                                  date: selectMonthId,
                                                                  fuelConsumption:
                                                                    route.averageFuelConsumption,
                                                                  ecologicIndex:
                                                                    route.ecologicIndex,
                                                                  distance:
                                                                    route.kmWork,
                                                                },
                                                              }
                                                            )
                                                            .then(
                                                              async (
                                                                response
                                                              ) => {
                                                                type CalculateFuelIndexBonusProps =
                                                                  {
                                                                    fuelConsumptionBonus: number;
                                                                    ecologicIndexBonus: number;
                                                                  };

                                                                const data =
                                                                  response.data as CalculateFuelIndexBonusProps;

                                                                setPaymentCalculationEcologic(
                                                                  {
                                                                    averageFuelConsumption:
                                                                      route.averageFuelConsumption,
                                                                    kmPrivate:
                                                                      route.kmPrivate,
                                                                    kmWork:
                                                                      route.kmWork,
                                                                    kmPrivateDomesticCost:
                                                                      route.kmPrivateDomesticCost,
                                                                    ecologicIndex:
                                                                      route.ecologicIndex,
                                                                    driverName:
                                                                      route.driverName,
                                                                  }
                                                                );

                                                                setPaymentCalculation(
                                                                  {
                                                                    ...paymentCalculation,
                                                                    fuelCost:
                                                                      route.kmPrivateDomesticCost,
                                                                    ecologicIndexBonus:
                                                                      data.ecologicIndexBonus,
                                                                    fuelConsumptionBonus:
                                                                      data.fuelConsumptionBonus,
                                                                  }
                                                                );
                                                              }
                                                            )
                                                            .finally(
                                                              async () => {
                                                                await dismissLoading();
                                                              }
                                                            );
                                                        } else {
                                                          setPaymentCalculationEcologic(
                                                            undefined
                                                          );
                                                        }
                                                      }}
                                                      options={paymentCalculationFuelCostResponse.map(
                                                        (e) => e.driverName
                                                      )}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                  >
                                                    {NumberToMoneyString(
                                                      paymentCalculationEcologic?.kmPrivateDomesticCost
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                  >
                                                    {paymentCalculationEcologic?.averageFuelConsumption
                                                      ? paymentCalculationEcologic?.averageFuelConsumption +
                                                        " l/km"
                                                      : ""}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                  >
                                                    {paymentCalculationEcologic?.kmPrivate
                                                      ? paymentCalculationEcologic?.kmPrivate +
                                                        " km"
                                                      : paymentCalculationEcologic?.kmPrivate}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                  >
                                                    {paymentCalculationEcologic?.kmWork
                                                      ? paymentCalculationEcologic?.kmWork +
                                                        " km"
                                                      : ""}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                  >
                                                    {
                                                      paymentCalculationEcologic?.ecologicIndex
                                                    }
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>

                                          <h5
                                            style={{
                                              margin: "45px 0 10px 11px",
                                            }}
                                          >
                                            Dane pobrane z profilu wypłat
                                          </h5>
                                          <TableContainer className="mb-3">
                                            <Table aria-label="simple table">
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell align="center">
                                                    Imię
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Nazwisko
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Rodzaj umowy
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Podstawa stała
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Kwota za jeden stop
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      { border: 0 },
                                                  }}
                                                >
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                  >
                                                    {
                                                      paymentCalculation?.firstName
                                                    }
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                  >
                                                    {
                                                      paymentCalculation?.lastName
                                                    }
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                  >
                                                    {
                                                      paymentCalculation?.contractName
                                                    }
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                  >
                                                    {NumberToMoneyString(
                                                      paymentCalculation?.contractBaseSalary
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    scope="row"
                                                  >
                                                    {NumberToMoneyString(
                                                      paymentCalculation?.contractAmountPerPoint
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>

                                          <h5
                                            style={{
                                              margin: "45px 0 10px 11px",
                                            }}
                                          >
                                            Dane do obliczeń
                                          </h5>
                                          <TableContainer
                                            className="shadow-basic mb-3"
                                            sx={{
                                              "td:last-child, th:last-child": {
                                                width: "300px",
                                                borderLeft:
                                                  "1px solid rgb(212, 212, 212)",
                                              },
                                              "td:last-child": {
                                                fontSize: "20px !important",
                                                fontWeight: 700,
                                              },
                                            }}
                                          >
                                            <Table aria-label="simple table">
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell align="center">
                                                    Podstawa stała
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Dni pracujące w miesiącu
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Przepracowane dni
                                                    <br />
                                                    <Button
                                                      onClick={() =>
                                                        setIsPaymentModalWorkDatesVisible(
                                                          true
                                                        )
                                                      }
                                                    >
                                                      Pokaż dni
                                                    </Button>
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Dni płatnego urlopu
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Dni bezpłatnego uroplu
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Rzeczywista podstawa za
                                                    przepracowane dni
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      { border: 0 },
                                                  }}
                                                >
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              contractBaseSalary:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.contractBaseSalary
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              paydaysCount: val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.paydaysCount
                                                      }
                                                      dataType={"days"}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              workingDaysInMonthCount:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.workingDaysInMonthCount
                                                      }
                                                      dataType={"days"}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              vacationPayedCount:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.vacationPayedCount
                                                      }
                                                      dataType={"days"}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              vacationFreeCount:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.vacationFreeCount
                                                      }
                                                      dataType={"days"}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    {paymentCalculationLoading ? (
                                                      <Skeleton
                                                        variant="text"
                                                        width={150}
                                                        height={40}
                                                        style={{
                                                          margin: "auto",
                                                        }}
                                                        animation="wave"
                                                      ></Skeleton>
                                                    ) : (
                                                      NumberToMoneyString(
                                                        paymentCalculation?.contractBaseSalaryCalculated
                                                      )
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>

                                          <TableContainer
                                            className="shadow-basic mb-3"
                                            sx={{
                                              "td:last-child, th:last-child": {
                                                width: "300px",
                                                borderLeft:
                                                  "1px solid rgb(212, 212, 212)",
                                              },
                                              "td:last-child": {
                                                fontSize: "20px !important",
                                                fontWeight: 700,
                                              },
                                              "td:nth-last-child(2)": {
                                                fontSize: "20px !important",
                                                fontWeight: 700,
                                              },
                                            }}
                                          >
                                            <Table aria-label="simple table">
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell align="center">
                                                    Kwota za jeden stop
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    <Button
                                                      style={{
                                                        fontSize: "17px",
                                                      }}
                                                      disabled={
                                                        !paymentCalculation
                                                          ?.stopsTable?.length
                                                      }
                                                      aria-describedby={
                                                        idStopsTablePopover
                                                      }
                                                      variant="text"
                                                      onClick={
                                                        handleClickStopsTablePopover
                                                      }
                                                    >
                                                      Ilość stopów
                                                    </Button>
                                                    <Popover
                                                      id={idStopsTablePopover}
                                                      open={
                                                        openStopsTablePopover
                                                      }
                                                      anchorEl={
                                                        anchorElStopsTablePopover
                                                      }
                                                      onClose={
                                                        handleCloseStopsTablePopover
                                                      }
                                                      anchorOrigin={{
                                                        vertical: "center",
                                                        horizontal: "center",
                                                      }}
                                                      transformOrigin={{
                                                        vertical: "center",
                                                        horizontal: "center",
                                                      }}
                                                    >
                                                      <Box
                                                        sx={{
                                                          padding:
                                                            "0 20px 15px",
                                                          width: "100%",
                                                          typography: "body1",
                                                        }}
                                                      >
                                                        <TabContext
                                                          value={
                                                            valueStopsTableTabs
                                                          }
                                                        >
                                                          <Box
                                                            sx={{
                                                              borderColor:
                                                                "divider",
                                                            }}
                                                          >
                                                            <TabList
                                                              onChange={
                                                                handleChangeStopsTableTabs
                                                              }
                                                              aria-label="lab API tabs example"
                                                            >
                                                              {paymentCalculation?.stopsTable?.map(
                                                                (e, i) => {
                                                                  return (
                                                                    <Tab
                                                                      label={
                                                                        e.routeName
                                                                      }
                                                                      value={i.toString()}
                                                                    />
                                                                  );
                                                                }
                                                              )}
                                                            </TabList>
                                                          </Box>
                                                          {paymentCalculation?.stopsTable?.map(
                                                            (e, i) => {
                                                              return (
                                                                <TabPanel
                                                                  style={{
                                                                    padding: 0,
                                                                  }}
                                                                  value={i.toString()}
                                                                >
                                                                  <TableContainer>
                                                                    <Table
                                                                      sx={{
                                                                        minWidth: 400,
                                                                      }}
                                                                      size="small"
                                                                      aria-label="a dense table"
                                                                    >
                                                                      <TableHead>
                                                                        <TableRow>
                                                                          <TableCell>
                                                                            Data
                                                                            dostawy
                                                                          </TableCell>
                                                                          <TableCell align="right">
                                                                            Wszystkie
                                                                            adresy
                                                                            na
                                                                            trasie
                                                                          </TableCell>
                                                                          <TableCell align="right">
                                                                            Wykonane
                                                                            adresy
                                                                          </TableCell>
                                                                          <TableCell align="right">
                                                                            Wykonane
                                                                            przez
                                                                            innych
                                                                            kierowców
                                                                          </TableCell>
                                                                          <TableCell align="right">
                                                                            Niewykonane
                                                                            przez
                                                                            nikogo
                                                                          </TableCell>
                                                                          <TableCell align="right">
                                                                            Diety
                                                                            zapasowowe
                                                                          </TableCell>
                                                                          <TableCell align="right">
                                                                            Wykonane
                                                                            duplikaty
                                                                          </TableCell>
                                                                          <TableCell align="right">
                                                                            Zrekompensowane
                                                                            punkty
                                                                            (duplikaty)
                                                                          </TableCell>
                                                                          <TableCell align="right">
                                                                            Razem
                                                                          </TableCell>
                                                                        </TableRow>
                                                                      </TableHead>
                                                                      <TableBody
                                                                        sx={{
                                                                          "tr:hover:not(:last-child)":
                                                                            {
                                                                              background:
                                                                                "#b6dbff",
                                                                              cursor:
                                                                                "pointer",
                                                                            },
                                                                        }}
                                                                      >
                                                                        {e.deliveries.map(
                                                                          (
                                                                            row
                                                                          ) => (
                                                                            <TableRow
                                                                              key={
                                                                                row.id
                                                                              }
                                                                              sx={{
                                                                                "&:last-child td, &:last-child th":
                                                                                  {
                                                                                    border: 0,
                                                                                  },
                                                                              }}
                                                                              onClick={() => {
                                                                                const newWindow =
                                                                                  window.open(
                                                                                    "/route/" +
                                                                                      row.url,
                                                                                    "_blank",
                                                                                    "noopener,noreferrer"
                                                                                  );
                                                                                if (
                                                                                  newWindow
                                                                                )
                                                                                  newWindow.opener =
                                                                                    null;
                                                                              }}
                                                                            >
                                                                              <TableCell
                                                                                component="th"
                                                                                scope="row"
                                                                              >
                                                                                {
                                                                                  row.deliveryDate
                                                                                }
                                                                              </TableCell>
                                                                              <TableCell align="right">
                                                                                {
                                                                                  row.totalPoints
                                                                                }
                                                                              </TableCell>
                                                                              <TableCell
                                                                                style={{
                                                                                  fontWeight: 600,
                                                                                }}
                                                                                align="right"
                                                                              >
                                                                                {
                                                                                  row.donePoints
                                                                                }
                                                                              </TableCell>
                                                                              <TableCell
                                                                                align="right"
                                                                                style={{
                                                                                  color:
                                                                                    row.doneByOthers >
                                                                                    0
                                                                                      ? "rgb(232, 83, 71)"
                                                                                      : "",
                                                                                  fontWeight:
                                                                                    row.doneByOthers >
                                                                                    0
                                                                                      ? 600
                                                                                      : "",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  row.doneByOthers
                                                                                }
                                                                              </TableCell>
                                                                              <TableCell
                                                                                align="right"
                                                                                style={{
                                                                                  color:
                                                                                    row.undonePoints >
                                                                                    0
                                                                                      ? "rgb(232, 83, 71)"
                                                                                      : "",
                                                                                  fontWeight:
                                                                                    row.undonePoints >
                                                                                    0
                                                                                      ? 600
                                                                                      : "",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  row.undonePoints
                                                                                }
                                                                              </TableCell>
                                                                              <TableCell
                                                                                align="right"
                                                                                style={{
                                                                                  color:
                                                                                    row.backupDiets >
                                                                                    0
                                                                                      ? "#acacac"
                                                                                      : "",
                                                                                  fontWeight:
                                                                                    row.backupDiets >
                                                                                    0
                                                                                      ? 600
                                                                                      : "",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  row.backupDiets
                                                                                }
                                                                              </TableCell>
                                                                              <TableCell
                                                                                align="right"
                                                                                style={{
                                                                                  color:
                                                                                    row.duplicates >
                                                                                    0
                                                                                      ? "rgb(232, 83, 71)"
                                                                                      : "",
                                                                                  fontWeight:
                                                                                    row.duplicates >
                                                                                    0
                                                                                      ? 600
                                                                                      : "",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  row.duplicates
                                                                                }
                                                                              </TableCell>
                                                                              <TableCell
                                                                                align="right"
                                                                                style={{
                                                                                  color:
                                                                                    row.compensationPoints >
                                                                                    0
                                                                                      ? "rgb(0, 128, 0)"
                                                                                      : "",
                                                                                  fontWeight:
                                                                                    row.compensationPoints >
                                                                                    0
                                                                                      ? 600
                                                                                      : "",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  row.compensationPoints
                                                                                }
                                                                              </TableCell>
                                                                              <TableCell
                                                                                align="right"
                                                                                style={{
                                                                                  fontWeight: 600,
                                                                                }}
                                                                              >
                                                                                {
                                                                                  row.overall
                                                                                }
                                                                              </TableCell>
                                                                            </TableRow>
                                                                          )
                                                                        )}

                                                                        <TableRow>
                                                                          <TableCell
                                                                            colSpan={
                                                                              5
                                                                            }
                                                                          />
                                                                          <TableCell
                                                                            colSpan={
                                                                              1
                                                                            }
                                                                            style={{
                                                                              fontWeight: 600,
                                                                            }}
                                                                          >
                                                                            Suma
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align="right"
                                                                            style={{
                                                                              fontWeight: 600,
                                                                              color:
                                                                                e.deliveries.reduce(
                                                                                  (
                                                                                    accumulator,
                                                                                    currentValue
                                                                                  ) =>
                                                                                    accumulator +
                                                                                    currentValue.duplicates,
                                                                                  0
                                                                                ) >
                                                                                0
                                                                                  ? "rgb(232, 83, 71)"
                                                                                  : "",
                                                                            }}
                                                                          >
                                                                            {e.deliveries.reduce(
                                                                              (
                                                                                accumulator,
                                                                                currentValue
                                                                              ) =>
                                                                                accumulator +
                                                                                currentValue.duplicates,
                                                                              0
                                                                            )}
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align="right"
                                                                            style={{
                                                                              fontWeight: 600,
                                                                              color:
                                                                                e.deliveries.reduce(
                                                                                  (
                                                                                    accumulator,
                                                                                    currentValue
                                                                                  ) =>
                                                                                    accumulator +
                                                                                    currentValue.compensationPoints,
                                                                                  0
                                                                                ) >
                                                                                0
                                                                                  ? "rgb(0, 128, 0)"
                                                                                  : "",
                                                                            }}
                                                                          >
                                                                            {e.deliveries.reduce(
                                                                              (
                                                                                accumulator,
                                                                                currentValue
                                                                              ) =>
                                                                                accumulator +
                                                                                currentValue.compensationPoints,
                                                                              0
                                                                            )}
                                                                          </TableCell>
                                                                          <TableCell
                                                                            align="right"
                                                                            style={{
                                                                              fontWeight: 600,
                                                                            }}
                                                                          >
                                                                            {e.deliveries.reduce(
                                                                              (
                                                                                accumulator,
                                                                                currentValue
                                                                              ) =>
                                                                                accumulator +
                                                                                currentValue.overall,
                                                                              0
                                                                            )}
                                                                          </TableCell>
                                                                        </TableRow>
                                                                      </TableBody>
                                                                    </Table>
                                                                  </TableContainer>
                                                                </TabPanel>
                                                              );
                                                            }
                                                          )}
                                                        </TabContext>
                                                      </Box>
                                                    </Popover>
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Wypłata za stopy
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Stopy + podstawa
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      { border: 0 },
                                                  }}
                                                >
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              contractAmountPerPoint:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.contractAmountPerPoint
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              pointsAmount: val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.pointsAmount
                                                      }
                                                      dataType={"none"}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    {paymentCalculationLoading ? (
                                                      <Skeleton
                                                        variant="text"
                                                        width={150}
                                                        height={40}
                                                        style={{
                                                          margin: "auto",
                                                        }}
                                                        animation="wave"
                                                      ></Skeleton>
                                                    ) : (
                                                      NumberToMoneyString(
                                                        paymentCalculation?.pointsAmountCalculated
                                                      )
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    {paymentCalculationLoading ? (
                                                      <Skeleton
                                                        variant="text"
                                                        width={150}
                                                        height={40}
                                                        style={{
                                                          margin: "auto",
                                                        }}
                                                        animation="wave"
                                                      ></Skeleton>
                                                    ) : (
                                                      NumberToMoneyString(
                                                        paymentCalculation?.pointsWithBaseSalaryCalculated
                                                      )
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>

                                          <TableContainer className="shadow-basic mb-3">
                                            <Table aria-label="simple table">
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell align="center">
                                                    Frekwencja
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Funkcja kierownika
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Statystyka Ecologic
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Ekonomiczna jazda
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Premia za brak kar
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      { border: 0 },
                                                  }}
                                                >
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              attendanceBonus:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.attendanceBonus
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>

                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              regionCaptainBonus:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.regionCaptainBonus
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>

                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              ecologicIndexBonus:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.ecologicIndexBonus
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>

                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              fuelConsumptionBonus:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.fuelConsumptionBonus
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>

                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              contractBonusSalary:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.contractBonusSalary
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>

                                          <TableContainer
                                            className="shadow-basic mb-3"
                                            sx={{
                                              "td:last-child, th:last-child": {
                                                width: "300px",
                                                borderLeft:
                                                  "1px solid rgb(212, 212, 212)",
                                              },
                                              "td:last-child": {
                                                fontSize: "25px !important",
                                                fontWeight: 800,
                                                color:
                                                  "rgb(232, 83, 71) !important",
                                              },
                                            }}
                                          >
                                            <Table aria-label="simple table">
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell align="center">
                                                    Kary niedowozy i pomyłki
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Kary dodatkowe
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {/* <LoadingButton
                                                      style={{
                                                        fontSize: "17px",
                                                      }}
                                                      aria-describedby={
                                                        idFuelCostPopover
                                                      }
                                                      variant="text"
                                                      onClick={
                                                        handleClickFuelCostPopover
                                                      }
                                                    > */}
                                                    Koszt paliwa
                                                    {/* </LoadingButton> */}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Dodatki
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    KOREKTA
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Suma
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      { border: 0 },
                                                  }}
                                                >
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              punishmentCost:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.punishmentCost
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              punishmentExtraCost:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.punishmentExtraCost
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              fuelCost: val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.fuelCost
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>

                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              allowance: val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.allowance
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>

                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    <NumericFormatEditable
                                                      disabled={
                                                        paymentModalVisibleOnlyRead
                                                      }
                                                      onChange={(val) => {
                                                        if (
                                                          paymentCalculation
                                                        ) {
                                                          setPaymentCalculation(
                                                            {
                                                              ...paymentCalculation,
                                                              correctionAmount:
                                                                val,
                                                            }
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        paymentCalculation?.correctionAmount
                                                      }
                                                      dataType={"money"}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    {paymentCalculationLoading ? (
                                                      <Skeleton
                                                        variant="text"
                                                        width={150}
                                                        height={40}
                                                        style={{
                                                          margin: "auto",
                                                        }}
                                                        animation="wave"
                                                      ></Skeleton>
                                                    ) : (
                                                      NumberToMoneyString(
                                                        paymentCalculation?.salaryCalculated
                                                      )
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>

                                          <div className="row justify-content-end">
                                            <div className="col-4">
                                              <TableContainer
                                                className="shadow-basic mb-3"
                                                sx={{
                                                  "td:last-child, th:last-child":
                                                    {
                                                      borderLeft:
                                                        "1px solid rgb(212, 212, 212)",
                                                    },
                                                }}
                                              >
                                                <Table aria-label="simple table">
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        Kary niedowozy i pomyłki
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    {paymentCalculation?.notes
                                                      ?.sort()
                                                      .map((e) => {
                                                        return (
                                                          <TableRow
                                                            sx={{
                                                              "&:last-child td span strong":
                                                                {
                                                                  fontSize:
                                                                    "15px",
                                                                },
                                                            }}
                                                          >
                                                            <TableCell
                                                              scope="row"
                                                              align="center"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontWeight: 600,
                                                                  color:
                                                                    e.noteAssignmentId ==
                                                                    1
                                                                      ? "red"
                                                                      : e.noteAssignmentId ==
                                                                        2
                                                                      ? "green"
                                                                      : "blue",
                                                                  marginRight:
                                                                    "20px",
                                                                }}
                                                              >
                                                                {
                                                                  e.noteAssignmentName
                                                                }
                                                              </span>
                                                              {e.noteAssignmentId !=
                                                              3 ? (
                                                                <span
                                                                  style={{
                                                                    marginRight:
                                                                      "20px",
                                                                  }}
                                                                >
                                                                  {NumberToMoneyString(
                                                                    e.cost
                                                                  )}
                                                                </span>
                                                              ) : (
                                                                <></>
                                                              )}
                                                              <span
                                                                style={{
                                                                  marginRight:
                                                                    "20px",
                                                                }}
                                                              >
                                                                {e.title}
                                                              </span>
                                                              <span>
                                                                {e.addDate}
                                                              </span>
                                                              <br />
                                                              <span
                                                                style={{
                                                                  marginTop:
                                                                    "10px",
                                                                  display:
                                                                    "block",
                                                                }}
                                                              >
                                                                {e.description}
                                                              </span>
                                                              <div
                                                                style={{
                                                                  marginTop:
                                                                    "10px",
                                                                }}
                                                              ></div>
                                                              {e.comments.map(
                                                                (c) => {
                                                                  return (
                                                                    <p>
                                                                      {
                                                                        c.description
                                                                      }{" "}
                                                                      -{" "}
                                                                      {
                                                                        c.createdDate
                                                                      }{" "}
                                                                      -{" "}
                                                                      {
                                                                        c.addedBy
                                                                      }
                                                                    </p>
                                                                  );
                                                                }
                                                              )}
                                                            </TableCell>
                                                          </TableRow>
                                                        );
                                                      })}
                                                  </TableBody>
                                                </Table>
                                              </TableContainer>
                                            </div>
                                            <div className="col-4">
                                              <TableContainer
                                                className="shadow-basic mb-3"
                                                sx={{
                                                  "td:last-child, th:last-child":
                                                    {
                                                      borderLeft:
                                                        "1px solid rgb(212, 212, 212)",
                                                    },
                                                }}
                                              >
                                                <Table aria-label="simple table">
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        Bazowe informacje o
                                                        wypłacie
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    <TableRow
                                                      sx={{
                                                        "&:last-child td span strong":
                                                          { fontSize: "15px" },
                                                      }}
                                                    >
                                                      <TableCell
                                                        scope="row"
                                                        align="center"
                                                      >
                                                        {paymentCalculation?.infos?.map(
                                                          (e) => {
                                                            return (
                                                              <div className="mb-1">
                                                                <Chip
                                                                  color={
                                                                    e.color
                                                                  }
                                                                  label={
                                                                    <strong>
                                                                      {
                                                                        e.description
                                                                      }
                                                                    </strong>
                                                                  }
                                                                />
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                </Table>
                                              </TableContainer>
                                            </div>
                                            <div className="col-4">
                                              <TextField
                                                // disabled={
                                                //   paymentModalVisibleOnlyRead
                                                // }
                                                style={{
                                                  pointerEvents:
                                                    paymentModalVisibleOnlyRead
                                                      ? "none"
                                                      : "all",
                                                }}
                                                value={
                                                  paymentCalculation?.summaryNote
                                                }
                                                onChange={(e) => {
                                                  if (paymentCalculation) {
                                                    setPaymentCalculation({
                                                      ...paymentCalculation,
                                                      summaryNote:
                                                        e.target.value,
                                                    });
                                                  }
                                                }}
                                                fullWidth
                                                label="Notatka do wypłaty"
                                                multiline
                                                rows={2}
                                              ></TextField>
                                              {paymentModalVisibleOnlyRead ? (
                                                <></>
                                              ) : (
                                                <Button
                                                  style={{
                                                    display: "block",
                                                    marginLeft: "auto",
                                                    marginTop: "10px",
                                                  }}
                                                  size="large"
                                                  variant="contained"
                                                  onClick={async () => {
                                                    await presentLoading(
                                                      "Przesyłanie danych do wypłaty..."
                                                    );

                                                    api
                                                      .post(
                                                        "payments/" +
                                                          paymentCalculation?.profileId +
                                                          "/calculation-info",
                                                        {
                                                          ...paymentCalculation,
                                                          date: selectMonthId,
                                                          ecologicIndex:
                                                            paymentCalculationEcologic?.ecologicIndex,
                                                          fuelConsumption:
                                                            paymentCalculationEcologic?.averageFuelConsumption,
                                                        }
                                                      )
                                                      .then(
                                                        async (response) => {
                                                          await dismissLoading();
                                                          setPaymentCalculation(
                                                            undefined
                                                          );
                                                          setIsPaymentModalVisible(
                                                            false
                                                          );

                                                          await refetch();
                                                        }
                                                      )
                                                      .catch(async () => {
                                                        await dismissLoading();
                                                      });
                                                  }}
                                                >
                                                  Zapisz wypłatę
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  </IonModal>

                                  {/* .btn-toolbar */}
                                </div>
                                {/* .card-tools */}
                              </div>
                              {/* .card-title-group */}
                              <div
                                className="card-search search-wrap"
                                data-search="search"
                              >
                                <div className="card-body">
                                  <div className="search-content">
                                    <a
                                      href="#"
                                      className="search-back btn btn-icon toggle-search"
                                      data-target="search"
                                    >
                                      <em className="icon ni ni-arrow-left" />
                                    </a>
                                    <input
                                      type="text"
                                      className="form-control border-transparent form-focus-none"
                                      placeholder="Search by user or email"
                                    />
                                    <button className="search-submit btn btn-icon">
                                      <em className="icon ni ni-search" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/* .card-search */}
                            </div>
                            {/* .card-inner */}
                            <div
                              className="card-inner p-0"
                              // style={{ maxHeight: "800px", overflow: "auto" }}
                            >
                              {payments.length > 0 ? (
                                <Box
                                  sx={{
                                    height: region && showMap ? 400 : 900,
                                    width: "100%",
                                  }}
                                >
                                  {/* {selectionModel.length > 0 ? (
                                  <div style={{ margin: "15px 20px" }}>
                                    <a
                                      className="btn btn-primary btn-lg text-white"
                                      style={{
                                        marginRight: "20px",
                                      }}
                                      onClick={async () => {
                                        presentLoading("Akceptacja tras");

                                        try {
                                          for (const n of selectionModel) {
                                            const routeId = payments.find(
                                              (k) => k.id == n
                                            )?.routeId;

                                            if (routeId) {
                                              const res = await api.patch(
                                                "routes/activate/" + routeId
                                              );
                                            }
                                          }
                                        } catch (error) {}

                                        dismissLoading();
                                        getData();

                                        setSelectionModel([]);
                                      }}
                                    >
                                      <span>
                                        Zaakceptuj masowo trasy -{" "}
                                        {selectionModel.length}
                                      </span>
                                    </a>
                                    {selectionModel.map((e) => {
                                      return (
                                        <Chip
                                          style={{
                                            height: "35px",
                                            border: "2px solid",
                                            color: "white",
                                            marginRight: "6px",
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            background:
                                              "#" +
                                              drivers.find((k) => k.id == e)
                                                ?.color,
                                            borderColor:
                                              "#" +
                                              drivers.find((k) => k.id == e)
                                                ?.colorLight,
                                          }}
                                          label={
                                            drivers.find((k) => k.id == e)?.name
                                          }
                                        ></Chip>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <></>
                                )} */}
                                  <TextField
                                    id={GenerateGUID()}
                                    autoComplete={GenerateGUID()}
                                    style={{
                                      margin: "10px auto",
                                      width: "500px",
                                      display: "flex",
                                    }}
                                    variant="filled"
                                    value={searchValue}
                                    onChange={handleSearchValueChange}
                                    label="Wyszukaj"
                                    sx={{ mb: 1 }}
                                  />
                                  {memoDataGrid}
                                </Box>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          {/* .card-inner-group */}
                        </div>
                        {/* .card */}
                      </div>
                    </Paper>

                    {/* .nk-block */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>

          <IonPopover
            className="drivers-list-popover"
            trigger="left-start"
            showBackdrop={false}
            ref={popover}
            isOpen={popoverOpen}
            onDidDismiss={() => setPopoverOpen(false)}
            dismissOnSelect={true}
            side="left"
            alignment="start"
          >
            <IonContent>
              <IonItem
                lines="none"
                style={{
                  "--background": colorValue,
                  "--color": "rgb(255, 255, 255)",
                  textAlign: "center",
                }}
              >
                <IonLabel style={{ fontSize: "22px" }}>
                  Pojazd{" "}
                  <strong style={{ fontWeight: 700 }}>{colorDriverName}</strong>
                </IonLabel>
              </IonItem>
              <IonList lines="none">
                <IonItem
                  onClick={() => {
                    presentAlert({
                      subHeader: "Czy na pewno chcesz zmienić hasło kierowcy:",
                      message: colorDriverName,
                      buttons: [
                        {
                          text: "Anuluj",
                          role: "cancel",
                        },
                        {
                          text: "Wygeneruj hasło",
                          cssClass: "text-danger text-strong",
                          role: "confirm",
                          handler: () => {
                            presentLoading("Generowanie hasła");

                            api
                              .patch(
                                "drivers/" + colorDriverId + "/change-password"
                              )
                              .then(async (e) => {
                                const data = e.data;

                                await refetch();

                                setReturnedPassword(data);
                                setIsPasswordOpen(true);
                              })
                              .catch(() => {
                                dismissLoading();
                              });
                          },
                        },
                      ],
                    });
                  }}
                  button={true}
                  detail={false}
                >
                  <IonLabel>Zmień hasło</IonLabel>
                  <IonIcon icon={lockClosed} slot="start"></IonIcon>
                </IonItem>
                <IonItem
                  onClick={() => {
                    setIsPickerOpen(true);
                  }}
                  button={true}
                  detail={false}
                >
                  <IonLabel>Zmień kolor</IonLabel>
                  <IonIcon icon={colorPalette} slot="start"></IonIcon>
                </IonItem>
                <IonItem
                  onClick={() => {
                    presentAlert({
                      subHeader: "Czy na pewno chcesz usunąć kierowcę:",
                      message: colorDriverName,
                      buttons: [
                        {
                          text: "Anuluj",
                          role: "cancel",
                        },
                        {
                          text: "Usuń",
                          cssClass: "text-danger text-strong",
                          role: "confirm",
                          handler: () => {
                            presentLoading("Usuwanie kierowcy");

                            api
                              .patch("drivers/" + colorDriverId + "/hide")
                              .then(async () => {
                                await refetch();
                              })
                              .catch(() => {
                                dismissLoading();
                              });
                          },
                        },
                      ],
                    });
                  }}
                  button={true}
                  detail={false}
                >
                  <IonLabel>Usuń kierowcę z listy</IonLabel>
                  <IonIcon icon={trash} slot="start"></IonIcon>
                </IonItem>

                {/* <IonPopover
                trigger="nested-trigger"
                dismissOnSelect={true}
                side="end"
              >
                <IonContent>
                  <IonList>
                    <IonItem button={true} detail={false}>
                      Nested option
                    </IonItem>
                  </IonList>
                </IonContent>
              </IonPopover> */}
              </IonList>
            </IonContent>
          </IonPopover>
        </div>
      </IonPage>
    </LocalizationProvider>
  );
};

export default Payments;
