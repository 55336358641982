import { IonCol, IonImg, IonRow, useIonLoading } from "@ionic/react";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DriverUsersAutocomplete from "./DriverUsersAutocomplete";
import DateOfIncident from "./DateOfIncident";
import DriversAutocomplete from "./DriversAutocomplete";
import DeliveryDate from "./DeliveryDate";

import api from "./../../services/api";
import RouteAddressesAutocomplete, {
  DriverDataResponse,
} from "./RouteAddressesAutocomplete";
import PunishmentsAutocomplete from "./PunishmentsAutocomplete";

type Props = {
  onFinally: () => void;
};

const AddNoteForm: React.FC<Props> = ({ onFinally }) => {
  const [region, setRegion] = useState<string>("");

  const [userDriver, setUserDriver] = useState<string>("");
  const [userDriverId, setUserDriverId] = useState<string>();

  const [noteAssignment, setNoteAssignment] = useState<string>("");
  const [noteAssignmentId, setNoteAssignmentId] = useState<string>();

  const [dateOfIncident, setDateOfIncident] = useState("");

  const [checked, setChecked] = useState(false);

  const [driver, setDriver] = useState<string>("");

  const [driverId, setDriverId] = useState<string>();

  const [routeDate, setRouteDate] = useState("");

  const [driverRouteAvailableDays, setDriverRouteAvailableDays] =
    useState<string[]>();

  const [routeAddress, setRouteAddress] = useState<string>("");
  const [routeAddressData, setRouteAddressData] =
    useState<DriverDataResponse>();
  const [routeAddressId, setRouteAddressId] = useState<number>();

  const [titleId, setTitleId] = useState<number>();

  const [description, setDescription] = useState<string>("");

  const [punishmentCost, setPunishmentCost] = useState<number>();

  const [route, setRoute] = useState<string>("");

  const [containerKey, setContainerKey] = useState("1");

  useEffect(() => {
    if (driverId) {
      api.get("report/" + driverId + "/available-days").then((response) => {
        setDriverRouteAvailableDays(response.data);

        // let tempDate = new Date(response.data[response.data.length - 1]);
        // tempDate.setHours(5);
      });
    }
  }, [driverId]);

  useEffect(() => {
    if (routeAddressId) {
      api.get("routes/delivery-address/" + routeAddressId).then((response) => {
        const data = response.data as DriverDataResponse | undefined;

        setRouteAddressData(data);
      });
    } else {
      setRouteAddressData(undefined);
    }
  }, [routeAddressId]);

  const [presentLoading, dismissLoading] = useIonLoading();

  return (
    <IonCol size="12">
      <div
        className="day-item janek-shadow"
        style={{
          padding: "0 25px",
          paddingBottom: "20px",
          maxWidth: "1000px",
          margin: "0 auto",
        }}
      >
        <IonRow>
          <IonCol size="12">
            <h3 className="mt-2 mb-1">
              Dodawanie
              {/* {!punishmentCost
                    ? "uwagę"
                    : punishmentCost > 0
                    ? "karę"
                    : "uwagę"} */}
            </h3>
          </IonCol>
        </IonRow>

        <IonRow className="mt-2">
          <IonCol size="12" className="px-3">
            <FormControl variant="filled" fullWidth>
              <InputLabel id="note-assignment-select-label">
                Wybierz typ
              </InputLabel>
              <Select
                labelId="note-assignment-select-label"
                id="note-assignment-select"
                value={noteAssignmentId}
                label="Wybierz typ"
                onChange={(e, d) => {
                  if (e.target.value) {
                    setNoteAssignmentId(e.target.value);
                  }
                }}
              >
                <MenuItem value={1}>
                  <span style={{ color: "red", fontWeight: 600 }}>
                    Kara/uwaga
                  </span>
                </MenuItem>
                <MenuItem value={2}>
                  <span style={{ color: "green", fontWeight: 600 }}>
                    Dodatek
                  </span>
                </MenuItem>
                <MenuItem value={3}>
                  <span style={{ color: "blue", fontWeight: 600 }}>
                    Informacja
                  </span>
                </MenuItem>
              </Select>
            </FormControl>
          </IonCol>
        </IonRow>
        <div style={{ position: "relative" }}>
          {noteAssignmentId == "" ? (
            <div
              style={{
                backdropFilter: "blur(3px)",
                zIndex: 5,
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            ></div>
          ) : (
            <></>
          )}

          <IonRow className="mt-2">
            <IonCol size="8" className="px-3">
              <DriverUsersAutocomplete
                fullWidth
                setDriver={setUserDriver}
                setDriverId={setUserDriverId}
              />
            </IonCol>
            <IonCol size="4" className="px-3">
              <DateOfIncident setDate={setDateOfIncident} />
            </IonCol>
          </IonRow>

          <IonRow className="mt-4 px-3">
            <IonCol size="12">
              <FormControlLabel
                style={{ marginBottom: "0" }}
                control={
                  <Switch
                    value={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  />
                }
                label={
                  <>
                    <div>Nie dodawaj adresu dostawy</div>
                  </>
                }
              ></FormControlLabel>
            </IonCol>
          </IonRow>

          {checked ? (
            <></>
          ) : (
            <>
              <IonRow>
                <IonCol size="6" className="px-3">
                  <DriversAutocomplete
                    fullWidth
                    setDriver={setDriver}
                    setDriverId={setDriverId}
                  />
                </IonCol>
                <IonCol size="6" className="px-3">
                  {/* <RoutesAutocomplete
                  disabled={!driver}
                  fullWidth
                  setDriver={setRoute}
                /> */}

                  <DeliveryDate
                    setDate={setRouteDate}
                    disabled={!driver}
                    isDateEnabled={(date) => {
                      if (!driverRouteAvailableDays) {
                        return false;
                      }

                      const tempDate = new Date(date);

                      for (let n of driverRouteAvailableDays) {
                        const nDate = new Date(n);

                        if (
                          nDate.getFullYear() === tempDate.getFullYear() &&
                          nDate.getMonth() === tempDate.getMonth() &&
                          nDate.getDate() === tempDate.getDate()
                        ) {
                          return true;
                        }
                      }

                      return false;
                    }}
                  />
                </IonCol>
              </IonRow>

              <IonRow className="mt-1">
                <IonCol size="12" className="px-3">
                  <RouteAddressesAutocomplete
                    disabled={!routeDate}
                    fullWidth
                    setDriver={setRouteAddress}
                    setRouteId={setRouteAddressId}
                    date={routeDate}
                    driver={driver}
                  />
                </IonCol>
              </IonRow>
            </>
          )}

          {!checked && routeAddressData ? (
            <div className="row justify-content-center mt-5 mb-5">
              <div className="col-auto px-3" style={{ margin: "auto 0" }}>
                <Paper
                  elevation={3}
                  style={{
                    width: "400px",
                    margin: "auto",
                  }}
                >
                  <TableContainer>
                    <Table sx={{ maxWidth: 500 }} aria-label="simple table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <strong>Adres dostawy</strong>
                          </TableCell>
                          <TableCell align="right" className="text-capitalize">
                            {routeAddressData?.postCode}{" "}
                            {routeAddressData?.city}
                            <br />
                            {routeAddressData?.street}{" "}
                            {routeAddressData?.houseNumber}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <strong>Numer kontaktowy</strong>
                          </TableCell>
                          <TableCell align="right" className="text-capitalize">
                            {routeAddressData?.phone}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <strong>Czy próbowano przesłać zdjęcie?</strong>
                          </TableCell>
                          <TableCell align="right" className="text-capitalize">
                            {routeAddressData?.imageProcessed ? (
                              <span className="text-success">Tak</span>
                            ) : (
                              <span className="text-danger">Nie</span>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <strong>Opis</strong>
                          </TableCell>
                          <TableCell align="right" className="text-capitalize">
                            {routeAddressData?.comment ? (
                              <>
                                <span>{routeAddressData?.comment}</span>
                                <br />
                              </>
                            ) : (
                              <></>
                            )}
                            {routeAddressData?.commentExtra ? (
                              <>
                                <span>{routeAddressData?.commentExtra}</span>
                              </>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
              <div className="col-5 px-3">
                {routeAddressData.image ? (
                  <div
                    style={{
                      width: "375px",
                      height: "500px",
                    }}
                  >
                    <IonImg src={routeAddressData.image} />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "375px",
                      height: "500px",
                      border: "3px solid rgb(216, 216, 216)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: "19px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      Brak zdjęcia
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* <IonRow className="mt-5">
              <IonCol size="12">
                <FormControlLabel
                  style={{ marginBottom: "0" }}
                  control={
                    <Switch
                      value={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <>
                      <div>
                        Wpisz dowolny tytuł, jeżeli brakuje go na liście{" "}
                        <span style={{ color: "grey" }}>
                          (pamiętaj, aby tytuł został wpisany starannie)
                        </span>
                      </div>
                    </>
                  }
                ></FormControlLabel>
              </IonCol>
            </IonRow> */}
          <IonRow className="mt-4">
            <IonCol size="12" className="px-3">
              <PunishmentsAutocomplete
                fullWidth
                setTitleId={setTitleId}
                checked={false}
              />
            </IonCol>

            {/* <IonCol size="4" className="px-3">
                <NumericFormat
                  // inputProps={{min: 0, style: { textAlign: 'right' }}}

                  InputProps={{
                    endAdornment: <span>zł</span>,
                  }}
                  label="Koszt kary"
                  customInput={TextField}
                  onValueChange={(values) =>
                    setPunishmentCost(values.floatValue)
                  }
                  value={punishmentCost}
                  // you can define additional custom props that are all forwarded to the customInput e. g.
                  variant="outlined"
                  decimalScale={2}
                  fixedDecimalScale
                />
              </IonCol> */}

            {/* <IonCol size="4">
                  <TextField
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label="Tytuł"
                  ></TextField>
                </IonCol> */}
          </IonRow>
          <IonRow style={{ marginTop: "10px" }}>
            <IonCol className="px-3" size="12">
              <TextField
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={5}
                variant="outlined"
                multiline
                fullWidth
                label="Opis"
              ></TextField>
            </IonCol>
          </IonRow>
          <IonRow
            style={{ marginTop: "10px" }}
            className="ion-justify-content-end"
          >
            <IonCol size="auto" className="px-3">
              <Button
                disabled={
                  checked
                    ? !userDriver || !userDriverId || !titleId || !description
                    : !userDriver ||
                      !titleId ||
                      !description ||
                      !driver ||
                      !driverId ||
                      !routeDate ||
                      !routeAddressId ||
                      !routeAddress
                }
                onClick={() => {
                  //driver/{name}/note

                  presentLoading();

                  api
                    .post("stats/driver/note", {
                      userDriverId: userDriverId,
                      routeChecked: checked,

                      driverId: driverId,
                      routeDate: routeDate ? routeDate : null,
                      routeAddressId: routeAddressId,

                      punishmentCost:
                        punishmentCost == undefined ? 0 : punishmentCost,

                      titleId: titleId,
                      description: description,

                      noteAssignmentId: noteAssignmentId,
                      dateOfIncident: dateOfIncident,
                    })
                    .then(async (response) => {
                      setDateOfIncident("");
                      setNoteAssignment("");
                      setNoteAssignmentId(undefined);
                      setUserDriver("");
                      setUserDriverId(undefined);
                      setTitleId(undefined);
                      setDescription("");
                      setRoute("");
                      setRouteAddress("");
                      setRouteAddressId(undefined);
                      setPunishmentCost(undefined);
                      setChecked(false);

                      setContainerKey(containerKey + "1");

                      await dismissLoading();

                      onFinally();
                    })
                    .catch(() => {
                      dismissLoading();
                    });
                }}
                variant="contained"
                size="large"
              >
                Dodaj{" "}
                {/* {!punishmentCost
                      ? "uwagę"
                      : punishmentCost > 0
                      ? "karę"
                      : "uwagę"} */}
              </Button>
            </IonCol>
          </IonRow>
        </div>
      </div>
    </IonCol>
  );
};

export default AddNoteForm;
