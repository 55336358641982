import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonReorderGroup,
  IonReorder,
  IonModal,
  IonProgressBar,
  useIonLoading,
  useIonAlert,
  IonRow,
  IonCol,
} from "@ionic/react";
import "./Drivers.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { RouteComponentProps, useHistory } from "react-router";

import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";
import Header from "../components/Header";

import { v4 as uuidv4 } from "uuid";
import {
  colorPalette,
  colorPaletteOutline,
  ellipsisHorizontal,
  ellipsisHorizontalCircleOutline,
  lockClosed,
  searchOutline,
  trash,
} from "ionicons/icons";

import { useDropzone } from "react-dropzone";
import api from "./../services/api";

import TextField from "@mui/material/TextField";
import {
  Button,
  Chip,
  debounce,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Select,
  Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NavigationIcon from "@mui/icons-material/Navigation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RegionAutocomplete from "../components/Drivers/RegionAutocomplete";
import MapRead, { MapRefProps } from "../components/MapRead";

import Menu from "@mui/icons-material/Menu";

import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridFilterModel,
  GridLogicOperator,
  GridRenderCellParams,
  GridRow,
  GridRowParams,
  GridRowSelectionModel,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";

import { ChromePicker, SketchPicker } from "react-color";
import CsvList from "../components/CsvList";
import useAxios from "axios-hooks";
import { GridColumnHeaders } from "@mui/x-data-grid";

import { useDemoData } from '@mui/x-data-grid-generator';

const MemoizedRow = React.memo(GridRow);
const MemoizedColumnHeaders = React.memo(GridColumnHeaders);

type FileCustom = {};

const Drivers: React.FC<RouteComponentProps> = ({ match }) => {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const map = useRef<MapRefProps>(null);

  const [presentAlert] = useIonAlert();

  const [showMap, setShowMap] = useState(false);

  const [region, setRegion] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("Wszystko");

  const [presentLoading, dismissLoading] = useIonLoading();

  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const history = useHistory();

  const [isFilesModalVisible, setIsFilesModalVisible] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [returnedPassword, setReturnedPassword] = useState("");

  useIonViewDidLeave(() => {
    setShowMap(false);
  });

  const apiRef = useGridApiRef();
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });

  const updateSearchValue = React.useMemo(() => {
    return debounce((newValue) => {
      apiRef.current.setQuickFilterValues(
        newValue.split(" ").filter((word: string) => word !== "")
      );
    }, 500);
  }, [apiRef]);

  function handleSearchValueChange(event: any) {
    const newValue = event.target.value;
    setSearch(newValue);
    updateSearchValue(newValue);
  }

  //#region  filter model

  //#endregion

  const GridNameCell = React.memo((params: GridRenderCellParams<any>) => {
    return (
      <>
        <div
          className="user-avatar xs drivers-grid-avatar"
          style={{
            background: "#" + params.row.color,
            borderColor: "#" + params.row.colorLight,
          }}
        >
          <span
            style={{
              maxWidth: "27px",
              overflow: "hidden",
            }}
          >
            {params.row.name.toUpperCase()}
          </span>
        </div>
        Pojazd&nbsp;
        <strong>{params.row.name}</strong>
      </>
    );
  });

  const GridEyeCell = React.memo((params: GridRenderCellParams<any>) => {
    const onClick = () => {
      const layers = [
        ["route"],
        ["points", ["==", "t", "N"]],
        ["pointsError", ["==", "t", "E"]],
        ["start", ["==", "t", "S"]],
        ["end", ["==", "t", "L"]],
      ];

      if (params.row.hidden) {
        for (const n of layers) {
          let tempArray: any = ["all"];
          if (n[1]) {
            tempArray.push(n[1]);
          }

          for (const hiddenDriver of drivers.filter((s) => s.hidden)) {
            if (hiddenDriver.id != params.row.id) {
              tempArray.push(["!=", "driver", hiddenDriver.id]);
            }
          }

          map.current?.Map.current?.setFilter(n[0] as string, tempArray);
        }
      } else {
        for (const n of layers) {
          let tempArray: any = ["all"];
          if (n[1]) {
            tempArray.push(n[1]);
          }
          tempArray.push(["!=", "driver", params.row.id]);

          for (const hiddenDriver of drivers.filter((s) => s.hidden)) {
            tempArray.push(["!=", "driver", hiddenDriver.id]);
          }

          map.current?.Map.current?.setFilter(n[0] as string, tempArray);
        }
      }

      const driverIndex = drivers.findIndex((s) => s.id == params.row.id);
      let items = [...drivers];
      let item = {
        ...drivers[driverIndex],
      };
      item.hidden = !item.hidden;
      items[driverIndex] = item;
      setDrivers(items);
    };

    return (
      <IconButton
        color="primary"
        aria-label="pokaż zdjęcie"
        component="span"
        onClick={onClick}
      >
        {params.row.hidden ? (
          <VisibilityOffIcon color="error" />
        ) : (
          <VisibilityIcon />
        )}
      </IconButton>
    );
  });


  const GridButtonsCell = React.memo((params: GridRenderCellParams<any>) => {
    const onClickTrasy = () => {
      const newWindow = window.open(
        "/route/" + params.row.id,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    };

    const onClickMenu = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
      setColorDriverName(params.row.name);
      setColorDriverId(params.row.id);
      setColorValue("#" + params.row.color);

      openPopover(e);
    };

    return (
      <>
        <Button
          onClick={onClickTrasy}
          variant="outlined"
          endIcon={<NavigationIcon />}
        >
          Trasy
        </Button>
        <Button
          color="info"
          style={{
            height: "36.5px",
            minWidth: "45px",
            padding: "0px",
            marginLeft: "15px",
          }}
          sx={{
            ".MuiButton-endIcon": {
              margin: 0,
            },
          }}
          // onClick={openPopover}
          onClick={onClickMenu}
          variant="contained"
          endIcon={<Menu />}
        />
      </>
    );
  });

  const GridStatusCell = React.memo((params: GridRenderCellParams<any>) => {
    return params.row.status ? (
      <span className="tb-status text-success">
        Dodano
        {params.row.active ? (
          <></>
        ) : (
          <>
            <span className="text-warning" style={{ fontWeight: 700 }}>
              {" "}
              - wersja robocza
            </span>
          </>
        )}
      </span>
    ) : (
      <span className="tb-status text-danger">Brak</span>
    );
  });

  const GridEyeHeaderCell = React.memo(
    (params: GridColumnHeaderParams<any>) => {
      return (
        <IconButton
          color="primary"
          aria-label="pokaż zdjęcia"
          component="span"
          onClick={() => {
            const layers = [
              ["route"],
              ["points", ["==", "t", "N"]],
              ["pointsError", ["==", "t", "E"]],
              ["start", ["==", "t", "S"]],
              ["end", ["==", "t", "L"]],
            ];

            const driversTemp = drivers;

            const hideEverything = !driversTemp[0].hidden;

            for (const n of layers) {
              let tempArray: any = ["all"];
              if (n[1]) {
                tempArray.push(n[1]);
              }

              if (hideEverything) {
                for (const hiddenDriver of drivers) {
                  tempArray.push(["!=", "driver", hiddenDriver.id]);
                }
              }

              map.current?.Map.current?.setFilter(n[0] as string, tempArray);
            }

            let itemIndex = 0;

            let items = [...drivers];
            for (const item of items) {
              item.hidden = hideEverything;
              items[itemIndex] = item;
              itemIndex++;
            }
            setDrivers(items);
          }}
        >
          <VisibilityIcon />
        </IconButton>
      );
    }
  );

  const GridPointsCell = React.memo((params: GridRenderCellParams<any>) => {
    return (
      <>
        <span
          className="tb-status text-success"
          style={{
            minWidth: "45px",
            display: "inline-block",
          }}
        >
          {params.row.successPoints}
        </span>

        <span
          className="tb-status text-warning"
          style={{
            minWidth: "40px",
            display: "inline-block",
          }}
        >
          {params.row.warningPoints}
        </span>

        <span
          className="tb-status text-danger"
          style={{
            minWidth: "40px",
            display: "inline-block",
          }}
        >
          {params.row.errorPoints}
        </span>
      </>
    );
  });

  const GridImagesCell = React.memo((params: GridRenderCellParams<any>) => {
    return (
      <>
        <span
          className="tb-status text-primary"
          style={{
            minWidth: "45px",
            display: "inline-block",
            fontWeight: 700,
          }}
        >
          {params.row.donePoints}
        </span>

        <span
          className="tb-status text-primary"
          style={{
            minWidth: "40px",
            display: "inline-block",
          }}
        >
          {params.row.proccessingPoints}
        </span>

        <span
          className="tb-status text-medium"
          style={{
            minWidth: "40px",
            display: "inline-block",
          }}
        >
          {params.row.undonePoints}
        </span>
      </>
    );
  });

  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: "name",
      headerName: "Użytkownik",
      width: 200,
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        "Pojazd " + params.row.name,
      renderCell: (params: GridRenderCellParams<any>) => (
        <GridNameCell {...params} />
      ),
    },
    {
      field: "password",
      headerName: "Hasło",
      // width: 150,
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "id",
      headerName: "ID",
      // width: 150,
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "region",
      headerName: "Region",
      // width: 250,
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Aktualna trasa",
      // width: 250,
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <GridStatusCell {...params} />
      ),
    },
    {
      field: "eye",
      headerName: "Oko",
      // width: 100,
      minWidth: 100,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderHeader: (params: GridColumnHeaderParams) => (
        <GridEyeHeaderCell {...params} />
      ),
      renderCell: (params: GridRenderCellParams<any>) => (
        <GridEyeCell {...params} />
      ),
    },
    {
      field: "points",
      headerName: "Przetworzone punkty",
      flex: 1,
      minWidth: 250,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <GridPointsCell {...params} />
      ),
    },
    {
      field: "images",
      headerName: "Zdjęcia",
      flex: 1,
      minWidth: 250,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <GridImagesCell {...params} />
      ),
    },
    {
      field: "action",
      headerName: "",
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      renderCell: (params: GridRenderCellParams<any>) => (
        <GridButtonsCell {...params} />
      ),
    },

    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 110,
    //   editable: true,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    // },
  ];

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let tempFiles = files;

      for (const _file of acceptedFiles) {
        tempFiles.push(_file);
      }

      setFiles([]);
      setFiles(tempFiles);
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  type DriverPoint = {
    postCode: string;
    city: string;
    street: string;
    houseNumber: string;
  };

  type Driver = {
    id: string;
    role: string;
    name: string;
    routeId: string;
    region: string;
    status: boolean;
    password: string;
    color: string;
    colorLight: string;
    startPoint: DriverPoint;
    endPoint: DriverPoint;
    successPoints: number;
    warningPoints: number;
    errorPoints: number;

    hidden: boolean;

    donePoints: number;
    proccessingPoints: number;
    undonePoints: number;

    active: boolean;
  };

  const [{ data = [], loading, error }, refetch] = useAxios<Driver[]>({
    url: "drivers",
    params: {
      region: region,
      status: status,
    },
  });

  const [drivers, setDrivers] = useState<Driver[]>(data);

  useEffect(() => {
    setDrivers(data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [region, status]);

  const [colorValue, setColorValue] = useState<string>();
  const [colorDriverName, setColorDriverName] = useState<string>("");
  const [colorDriverId, setColorDriverId] = useState<string>("");

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );


  const memoDataGrid = useMemo(() => {
    return (
      <DataGridPro
      slots={{
        row: MemoizedRow,
        columnHeaders: MemoizedColumnHeaders,
      }}
        apiRef={apiRef}
        // filterModel={filterModel}
        isRowSelectable={(params: GridRowParams) =>
          !!params.row.routeId && !params.row.active
        }
        onRowSelectionModelChange={(newSelectionModel, details) => {
          setSelectionModel(newSelectionModel);
        }}
        rowSelectionModel={selectionModel}
        sx={{
          fontSize: "16px",
          // height: region && showMap ? 400 : 900,
        }}
        columns={columns}
        rows={drivers}
        hideFooter
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
        // experimentalFeatures={{ newEditingApi: true }}
        rowHeight={52}
        disableVirtualization
        // rowBuffer={10}
      />
    );
  }, [drivers, selectionModel]);




  return (
    <IonPage>
      {/* <CustomHeaderFade
        page={"Strona główna"}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      /> */}

      {isPlatform("mobile") ? <></> : <CsvList />}

      <div
        id="drivers-page-controller"
        className={"background-lightgrey slide-transition-leave nk-main"}
        style={{
          overflow: "auto ",
        }}
      >
        <IonModal
          style={{
            "--height": "175px",
            "--wdith": "280px",
          }}
          isOpen={isPasswordOpen}
          onIonModalDidDismiss={() => setIsPasswordOpen(false)}
        >
          <IonRow>
            <IonCol size="12">
              <h3 style={{ textAlign: "center" }}>Nowe hasło dla</h3>
              <h3 style={{ textAlign: "center", margin: "0 0 10px" }}>
                Pojazd {colorDriverName}
              </h3>
            </IonCol>
            <IonCol size="12">
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "50px",
                  letterSpacing: "3px",
                }}
              >
                {returnedPassword}
              </h2>
            </IonCol>
          </IonRow>
        </IonModal>

        <IonModal
          style={{
            "--width": "auto",
            "--height": "auto",
          }}
          // anchorEl={anchorEl}
          isOpen={isPickerOpen}
          onIonModalDidDismiss={() => setIsPickerOpen(false)}
          // anchorOrigin={{
          //   vertical: "bottom",
          //   horizontal: "left",
          // }}
          // transformOrigin={{
          //   vertical: "top",
          //   horizontal: "left",
          // }}
        >
          <IonRow>
            <IonCol size="12">
              <h3 style={{ textAlign: "center", margin: "15px 0px" }}>
                Pojazd {colorDriverName}
              </h3>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <ChromePicker
                color={colorValue}
                onChange={(e) => {
                  setColorValue(e.hex);
                  console.log(e.hex);
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <Button
                onClick={() => {
                  presentLoading();

                  api
                    .patch("drivers/color-change", {
                      DriverId: colorDriverId,
                      Color: colorValue,
                    })
                    .finally(() => {
                      dismissLoading();
                      setColorDriverName("");
                      setColorDriverId("");
                      setIsPickerOpen(false);
                    });
                }}
                variant="contained"
                fullWidth
                size="large"
              >
                Akceptuj
              </Button>
            </IonCol>
          </IonRow>
        </IonModal>

        <Header />

        <div className="nk-wrap ">
          {/* main header @s */}

          {/* main header @e */}
          {/* content @s */}
          <div className="nk-content ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">
                          Lista tras{" "}
                          <span
                            style={{
                              color: "rgb(63, 84, 255)",
                              fontSize: "34px",
                            }}
                          >
                            {region ? region.toUpperCase() : ""}
                          </span>
                        </h3>
                        {/* <div className="nk-block-des text-soft">
                          <p>You have total 2,595 users.</p>
                        </div> */}
                      </div>
                      {/* .nk-block-head-content */}
                      <div className="nk-block-head-content">
                        <div className="toggle-wrap nk-block-tools-toggle">
                          <a
                            href="#"
                            className="btn btn-icon btn-trigger toggle-expand mr-n1"
                            data-target="pageMenu"
                          >
                            <em className="icon ni ni-menu-alt-r" />
                          </a>
                          {/* <div
                            className="toggle-expand-content"
                            data-content="pageMenu"
                          >
                            <ul className="nk-block-tools g-3">
                              <li>
                                <a
                                  href="#"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-download-cloud" />
                                  <span>Export</span>
                                </a>
                              </li>
                              <li className="nk-block-tools-opt">
                                <div className="drodown">
                                  <a
                                    href="#"
                                    className="dropdown-toggle btn btn-icon btn-primary"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-plus" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <a href="#">
                                          <span>Add User</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Add Team</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Import User</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                        {/* .toggle-wrap */}
                      </div>
                      {/* .nk-block-head-content */}
                    </div>
                    {/* .nk-block-between */}
                  </div>
                  {/* .nk-block-head */}

                  <Paper elevation={0}>
                    <div className="nk-block">
                      <div className="card card-bordered card-stretch">
                        <div className="card-inner-group">
                          <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                              <div className="card-tools">
                                <div className="form-inline flex-nowrap">
                                  <TextField
                                    className="shadow-mui"
                                    style={{
                                      width: "500px",
                                    }}
                                    id="outlined-search"
                                    label="Wyszukaj"
                                    type="search"
                                    // onChange={(e) => {
                                    //   setSearch(e.target.value!);
                                    // }}
                                    onChange={handleSearchValueChange}
                                    value={search}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton
                                            // onClick={() => refetch()}
                                            aria-label="search"
                                          >
                                            <SearchIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />

                                  <RegionAutocomplete setRegion={setRegion} />

                                  <Button
                                    onClick={() => {
                                      refetch();

                                      if (showMap) {
                                        setShowMap(false);
                                        setTimeout(() => {
                                          setShowMap(true);
                                        }, 300);
                                      } else {
                                        setShowMap(true);
                                      }
                                    }}
                                    disabled={!region}
                                    className="ml-2"
                                    style={{
                                      height: "55px",
                                    }}
                                    variant="contained"
                                    startIcon={<VisibilityIcon />}
                                  >
                                    Pokaż mapę
                                  </Button>

                                  <FormControl
                                    style={{
                                      marginLeft: "30px",
                                      width: "200px",
                                    }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Aktualna trasa
                                    </InputLabel>
                                    <Select
                                      className="shadow-mui"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={status}
                                      label="Aktualna trasa"
                                      onChange={(e) =>
                                        setStatus(e.target.value!)
                                      }
                                    >
                                      <MenuItem value={"Wszystko"}>
                                        Wszystko
                                      </MenuItem>
                                      <MenuItem value={"Dodano"}>
                                        Dodano
                                      </MenuItem>
                                      <MenuItem value={"Brak"}>Brak</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>

                                {/* .form-inline */}
                              </div>
                              {/* .card-tools */}
                              <div className="card-tools mr-n1">
                                <ul className="btn-toolbar gx-1">
                                  <li>
                                    <a
                                      className="btn btn-primary btn-lg text-white"
                                      onClick={() => {
                                        setIsFilesModalVisible(true);
                                      }}
                                    >
                                      <em className="icon ni ni-upload-cloud" />{" "}
                                      <span>Prześlij trasę w csv</span>
                                    </a>
                                  </li>

                                  {/* <li>
                                    <a
                                      className="btn btn-secondary btn-lg text-white"
                                      onClick={() => {
                                        setIsFilesModalVisible(true);
                                      }}
                                    >
                                      <em className="icon ni ni-user-fill" />{" "}
                                      <span>Dodaj kierowcę</span>
                                    </a>
                                  </li> */}

                                  <IonModal
                                    backdropDismiss={false}
                                    style={{ "--height": "auto" }}
                                    isOpen={isFilesModalVisible}
                                    className=""
                                    onIonModalDidDismiss={() =>
                                      setIsFilesModalVisible(false)
                                    }
                                  >
                                    <>
                                      <div>
                                        <a
                                          className="close"
                                          style={{
                                            fontSize: "40px",
                                            float: "initial",
                                            cursor: "auto",
                                          }}
                                        >
                                          <em
                                            style={{
                                              marginLeft: "auto",
                                              display: "table",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              setIsFilesModalVisible(false)
                                            }
                                            className="icon ni ni-cross-sm"
                                          />
                                        </a>
                                        <div className="modal-body modal-body-md">
                                          <div className="nk-upload-form">
                                            {/* <h5 className="title mb-3">Upload File</h5> */}
                                            <div
                                              className="upload-zone bg-lighter dropzone"
                                              style={{ padding: "45px 20px" }}
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              {isDragActive ? (
                                                <div className="row justify-content-center">
                                                  <div className="col-auto">
                                                    <span
                                                      className="dz-message-text"
                                                      style={{
                                                        fontSize: "30px",
                                                      }}
                                                    >
                                                      Upuść tutaj 👇🏻
                                                    </span>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="row justify-content-center">
                                                  <div className="col-auto">
                                                    <span
                                                      className="dz-message-text"
                                                      style={{
                                                        fontSize: "30px",
                                                      }}
                                                    >
                                                      Zarzuć plikiem 😎
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            className="nk-upload-list"
                                            style={{
                                              maxHeight: "500px",
                                              overflowY: "auto",
                                              overflowX: "hidden",
                                            }}
                                          >
                                            <h6 className="title">
                                              Dodane pliki
                                            </h6>

                                            {files.map((e, index) => {
                                              return (
                                                <div className="nk-upload-item">
                                                  <div className="nk-upload-icon">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 72 72"
                                                    >
                                                      <g>
                                                        <path
                                                          d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
                                                          style={{
                                                            fill: "#599def",
                                                          }}
                                                        />
                                                        <path
                                                          d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z"
                                                          style={{
                                                            fill: "#c2e1ff",
                                                          }}
                                                        />
                                                        <rect
                                                          x={27}
                                                          y={31}
                                                          width={18}
                                                          height={2}
                                                          rx={1}
                                                          ry={1}
                                                          style={{
                                                            fill: "#fff",
                                                          }}
                                                        />
                                                        <rect
                                                          x={27}
                                                          y={36}
                                                          width={18}
                                                          height={2}
                                                          rx={1}
                                                          ry={1}
                                                          style={{
                                                            fill: "#fff",
                                                          }}
                                                        />
                                                        <rect
                                                          x={27}
                                                          y={41}
                                                          width={18}
                                                          height={2}
                                                          rx={1}
                                                          ry={1}
                                                          style={{
                                                            fill: "#fff",
                                                          }}
                                                        />
                                                        <rect
                                                          x={27}
                                                          y={46}
                                                          width={12}
                                                          height={2}
                                                          rx={1}
                                                          ry={1}
                                                          style={{
                                                            fill: "#fff",
                                                          }}
                                                        />
                                                      </g>
                                                    </svg>
                                                  </div>
                                                  <div className="nk-upload-info">
                                                    <div className="nk-upload-title">
                                                      <span className="title">
                                                        {e.name}
                                                      </span>{" "}
                                                      {/* <span className="meta">
                                                  70% Done
                                                </span> */}
                                                    </div>
                                                    {/* <div className="nk-upload-progress">
                                              <IonProgressBar color="primary" value={0.5} />
                                              </div> */}
                                                  </div>
                                                  <div className="nk-upload-action">
                                                    <a
                                                      className="btn btn-icon btn-trigger"
                                                      onClick={() => {
                                                        let tempFiles: File[] =
                                                          [];

                                                        for (
                                                          let i = 0;
                                                          i < files.length;
                                                          i++
                                                        ) {
                                                          if (i != index) {
                                                            tempFiles.push(
                                                              files[i]
                                                            );
                                                          }
                                                        }

                                                        // setFiles([]);
                                                        setFiles(tempFiles);

                                                        console.log(tempFiles);
                                                      }}
                                                    >
                                                      <em className="icon ni ni-trash" />
                                                    </a>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                          <div className="nk-modal-action justify-end">
                                            <ul className="btn-toolbar g-4 align-center">
                                              <li>
                                                <button
                                                  onClick={() => {
                                                    presentLoading({
                                                      spinner: "crescent",
                                                      message:
                                                        "Przesyłanie plików...",
                                                    });

                                                    if (files.length > 0) {
                                                      let formData =
                                                        new FormData();

                                                      for (const _file of files) {
                                                        formData.append(
                                                          "files",
                                                          _file
                                                        );
                                                        formData.append(
                                                          "names",
                                                          _file.name
                                                        );
                                                      }

                                                      api
                                                        .post(
                                                          "routes/csv",
                                                          formData,
                                                          {
                                                            headers: {
                                                              "Content-Type":
                                                                "multipart/form-data",
                                                            },
                                                          }
                                                        )
                                                        .then(
                                                          async (response) => {
                                                            presentAlert(
                                                              "Pliki zostały przesłane. Poczekaj parę minut i odśwież stronę główną.",
                                                              [
                                                                {
                                                                  text: "Zamknij",
                                                                },
                                                              ]
                                                            );

                                                            await dismissLoading();

                                                            setIsFilesModalVisible(
                                                              false
                                                            );
                                                            setFiles([]);
                                                          }
                                                        )
                                                        .catch(async () => {
                                                          presentAlert(
                                                            "Wystąpił BŁĄD przy przesyłanie plików.",
                                                            [
                                                              {
                                                                text: "Zamknij",
                                                              },
                                                            ]
                                                          );

                                                          await dismissLoading();
                                                        });
                                                    }
                                                  }}
                                                  type="button"
                                                  className="btn btn-primary"
                                                >
                                                  Prześlij pliki
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  </IonModal>
                                </ul>
                                {/* .btn-toolbar */}
                              </div>
                              {/* .card-tools */}
                            </div>
                            {/* .card-title-group */}
                            <div
                              className="card-search search-wrap"
                              data-search="search"
                            >
                              <div className="card-body">
                                <div className="search-content">
                                  <a
                                    href="#"
                                    className="search-back btn btn-icon toggle-search"
                                    data-target="search"
                                  >
                                    <em className="icon ni ni-arrow-left" />
                                  </a>
                                  <input
                                    type="text"
                                    className="form-control border-transparent form-focus-none"
                                    placeholder="Search by user or email"
                                  />
                                  <button className="search-submit btn btn-icon">
                                    <em className="icon ni ni-search" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* .card-search */}
                          </div>
                          {/* .card-inner */}
                          <div
                            className="card-inner p-0"
                            // style={{ maxHeight: "800px", overflow: "auto" }}
                          >
                            {drivers.length > 0 ? (
                              <Box
                                sx={{
                                  width: "100%",
                                }}
                              >
                                {selectionModel.length > 0 ? (
                                  <div style={{ margin: "15px 20px" }}>
                                    <a
                                      className="btn btn-primary btn-lg text-white"
                                      style={{
                                        marginRight: "20px",
                                      }}
                                      onClick={async () => {
                                        presentLoading("Akceptacja tras");

                                        try {
                                          for (const n of selectionModel) {
                                            const routeId = drivers.find(
                                              (k) => k.id == n
                                            )?.routeId;

                                            if (routeId) {
                                              const res = await api.patch(
                                                "routes/activate/" + routeId
                                              );
                                            }
                                          }
                                        } catch (error) {}

                                        dismissLoading();
                                        refetch();

                                        setSelectionModel([]);
                                      }}
                                    >
                                      <span>
                                        Zaakceptuj masowo trasy -{" "}
                                        {selectionModel.length}
                                      </span>
                                    </a>
                                    {selectionModel.map((e) => {
                                      return (
                                        <Chip
                                          style={{
                                            height: "35px",
                                            border: "2px solid",
                                            color: "white",
                                            marginRight: "6px",
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            background:
                                              "#" +
                                              drivers.find((k) => k.id == e)
                                                ?.color,
                                            borderColor:
                                              "#" +
                                              drivers.find((k) => k.id == e)
                                                ?.colorLight,
                                          }}
                                          label={
                                            drivers.find((k) => k.id == e)?.name
                                          }
                                        ></Chip>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {/* {memoDataGrid} */}
                                  <Box sx={{
                                    height: region && showMap ? 400 : 900
                                  }}
                                  >
                                    {memoDataGrid}
                                  </Box>
                               

                              </Box>
                            ) : (
                              <></>
                            )}

                            {/* 
                            <div className="nk-tb-list nk-tsb-ulist is-compact">
                              <div className="nk-tb-item nk-tb-head">
                                <div className="nk-tb-col">
                                  <span className="sub-text">Użytkownik</span>
                                </div>
                                <div className="nk-tb-col">
                                  <span className="sub-text">Hasło</span>
                                </div>
                                <div className="nk-tb-col">
                                  <span className="sub-text">ID</span>
                                </div>

                                <div className="nk-tb-col ">
                                  <span className="sub-text">Regiony</span>
                                </div>
                                <div className="nk-tb-col ">
                                  <span className="sub-text">
                                    Aktualna trasa
                                  </span>
                                </div>
                                <div className="nk-tb-col ">
                                  <IconButton
                                    color="primary"
                                    aria-label="pokaż zdjęcia"
                                    component="span"
                                    onClick={() => {
                                      const layers = [
                                        ["route"],
                                        ["points", ["==", "t", "N"]],
                                        ["pointsError", ["==", "t", "E"]],
                                        ["start", ["==", "t", "S"]],
                                        ["end", ["==", "t", "L"]],
                                      ];

                                      const driversTemp = drivers;

                                      const hideEverything =
                                        !driversTemp[0].hidden;

                                      for (const n of layers) {
                                        let tempArray: any = ["all"];
                                        if (n[1]) {
                                          tempArray.push(n[1]);
                                        }

                                        if (hideEverything) {
                                          for (const hiddenDriver of drivers) {
                                            tempArray.push([
                                              "!=",
                                              "driver",
                                              hiddenDriver.id,
                                            ]);
                                          }
                                        }

                                        map.current?.Map.current?.setFilter(
                                          n[0] as string,
                                          tempArray
                                        );
                                      }

                                      let itemIndex = 0;

                                      let items = [...drivers];
                                      for (const item of items) {
                                        item.hidden = hideEverything;
                                        items[itemIndex] = item;
                                        itemIndex++;
                                      }
                                      setDrivers(items);
                                    }}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </div>
                                <div className="nk-tb-col ">
                                  <span className="sub-text">
                                    Przetworzone punkty
                                  </span>
                                </div>

                                <div className="nk-tb-col ">
                                  <span className="sub-text">
                                    Zdjęcia
                                  </span>
                                </div>



                                <div className="nk-tb-col ">
                                  <span className="sub-text"></span>
                                </div>

                                <div className="nk-tb-col nk-tb-col-tools text-right">
                                  
                                </div>
                              </div> */}

                            {/* <IonReorderGroup onIonItemReorder={(event) => event.detail.complete(true)} disabled={false} style={{ display: "table-row-group" }} > */}

                            {/* {drivers.map((e, i) => {
                                return (
                                  <>
                                    <div className="nk-tb-item" key={e.id}>
                                      <div className="nk-tb-col">
                                        <div className="user-card">
                                          <div
                                            className="user-avatar xs"
                                            style={{
                                              height: "37px",
                                              width: "37px",
                                              fontSize: "13px",
                                              paddingTop: "2px",
                                              background: "#" + e.color,
                                              borderColor: "#" + e.colorLight,
                                              borderWidth: "3px",
                                              borderStyle: "solid",
                                              letterSpacing: 0,
                                            }}
                                          >
                                            <span>{e.name.toUpperCase()}</span>
                                          </div>
                                          <div className="user-name">
                                            <div
                                              className="tb-lead"
                                              style={{
                                                letterSpacing: "0.5px",
                                                fontSize: "19px",
                                              }}
                                            >
                                              Pojazd{" "}
                                              <span style={{ fontWeight: 700 }}>
                                                {e.name.toUpperCase()}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="nk-tb-col ">
                                        <span>{e.password}</span>
                                      </div>
                                      <div className="nk-tb-col ">
                                        <span>{e.id}</span>
                                      </div>
                                      <div className="nk-tb-col ">
                                        <span>{e.region}</span>
                                      </div>

                                      <div className="nk-tb-col">
                                        {e.status ? (
                                          <span className="tb-status text-success">
                                            Dodano
                                            {e.active ? (
                                              <></>
                                            ) : (
                                              <>
                                                <span
                                                  className="text-warning"
                                                  style={{ fontWeight: 700 }}
                                                >
                                                  {" "}
                                                  - wersja robocza
                                                </span>
                                              </>
                                            )}
                                          </span>
                                        ) : (
                                          <span className="tb-status text-danger">
                                            Brak
                                          </span>
                                        )}
                                      </div>

                                      <div className="nk-tb-col">
                                        <IconButton
                                          color="primary"
                                          aria-label="pokaż zdjęcie"
                                          component="span"
                                          onClick={() => {
                                            const layers = [
                                              ["route"],
                                              ["points", ["==", "t", "N"]],
                                              ["pointsError", ["==", "t", "E"]],
                                              ["start", ["==", "t", "S"]],
                                              ["end", ["==", "t", "L"]],
                                            ];

                                            if (e.hidden) {
                                              for (const n of layers) {
                                                let tempArray: any = ["all"];
                                                if (n[1]) {
                                                  tempArray.push(n[1]);
                                                }

                                                for (const hiddenDriver of drivers.filter(
                                                  (s) => s.hidden
                                                )) {
                                                  if (hiddenDriver.id != e.id) {
                                                    tempArray.push([
                                                      "!=",
                                                      "driver",
                                                      hiddenDriver.id,
                                                    ]);
                                                  }
                                                }

                                                map.current?.Map.current?.setFilter(
                                                  n[0] as string,
                                                  tempArray
                                                );
                                              }
                                            } else {
                                              for (const n of layers) {
                                                let tempArray: any = ["all"];
                                                if (n[1]) {
                                                  tempArray.push(n[1]);
                                                }
                                                tempArray.push([
                                                  "!=",
                                                  "driver",
                                                  e.id,
                                                ]);

                                                for (const hiddenDriver of drivers.filter(
                                                  (s) => s.hidden
                                                )) {
                                                  tempArray.push([
                                                    "!=",
                                                    "driver",
                                                    hiddenDriver.id,
                                                  ]);
                                                }

                                                map.current?.Map.current?.setFilter(
                                                  n[0] as string,
                                                  tempArray
                                                );
                                              }
                                            }

                                            const driverIndex =
                                              drivers.findIndex(
                                                (s) => s.id == e.id
                                              );
                                            let items = [...drivers];
                                            let item = {
                                              ...drivers[driverIndex],
                                            };
                                            item.hidden = !item.hidden;
                                            items[driverIndex] = item;
                                            setDrivers(items);
                                          }}
                                        >
                                          {e.hidden ? (
                                            <VisibilityOffIcon color="error" />
                                          ) : (
                                            <VisibilityIcon />
                                          )}
                                        </IconButton>
                                      </div>

                                      <div className="nk-tb-col">
                                        <span
                                          className="tb-status text-success"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                          }}
                                        >
                                          {e.successPoints}
                                        </span>

                                        <span
                                          className="tb-status text-warning"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                          }}
                                        >
                                          {e.warningPoints}
                                        </span>

                                        <span
                                          className="tb-status text-danger"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                          }}
                                        >
                                          {e.errorPoints}
                                        </span>
                                      </div>

                                      <div className="nk-tb-col">

                                        <span
                                          className="tb-status text-primary"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                            fontWeight: 700
                                          }}
                                        >
                                          {e.donePoints}
                                        </span>

                                        <span
                                          className="tb-status text-primary"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                          }}
                                        >
                                          {e.proccessingPoints}
                                        </span>

                                        <span
                                          className="tb-status text-medium"
                                          style={{
                                            minWidth: "35px",
                                            display: "inline-block",
                                          }}
                                        >
                                          {e.undonePoints}
                                        </span>

                                      </div>


                                      <div className="nk-tb-col"></div>
                                    
                                      <div className="nk-tb-col nk-tb-col-tools">
                                      

                                        <Button
                                          onClick={() => {
                                            const newWindow = window.open(
                                              "/route/" + e.id,
                                              "_blank",
                                              "noopener,noreferrer"
                                            );
                                            if (newWindow)
                                              newWindow.opener = null;
                                          }}
                                          variant="outlined"
                                          endIcon={<NavigationIcon />}
                                        >
                                          Trasy
                                        </Button>
                                      </div>

                                      <div></div>
                                      <div></div>
                                    </div>
                                  </>
                                );
                              })} */}

                            {/* </div> */}
                          </div>

                          {/* .card-inner */}
                          {/* <div className="card-inner">
                          <ul className="pagination justify-content-center justify-content-md-start">
                            <li className="page-item">
                              <a className="page-link" href="#">
                                Prev
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <span className="page-link">
                                <em className="icon ni ni-more-h" />
                              </span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                6
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                7
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                Next
                              </a>
                            </li>
                          </ul>
                        </div> */}
                          {/* .card-inner */}
                        </div>
                        {/* .card-inner-group */}
                      </div>
                      {/* .card */}
                    </div>

                    {region && showMap ? (
                      <div className="mt-3" style={{ position: "relative" }}>
                        <MapRead
                          ref={map}
                          routeId={drivers.map((e) => e.routeId)}
                          region={region}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </Paper>

                  {/* .nk-block */}
                </div>
              </div>
            </div>
          </div>
          {/* content @e */}
        </div>

        <IonPopover
          className="drivers-list-popover"
          trigger="left-start"
          showBackdrop={false}
          ref={popover}
          isOpen={popoverOpen}
          onDidDismiss={() => setPopoverOpen(false)}
          dismissOnSelect={true}
          side="left"
          alignment="start"
        >
          <IonContent>
            <IonItem
              lines="none"
              style={{
                "--background": colorValue,
                "--color": "rgb(255, 255, 255)",
                textAlign: "center",
              }}
            >
              <IonLabel style={{ fontSize: "22px" }}>
                Pojazd{" "}
                <strong style={{ fontWeight: 700 }}>{colorDriverName}</strong>
              </IonLabel>
            </IonItem>
            <IonList lines="none">
              <IonItem
                onClick={() => {
                  presentAlert({
                    subHeader: "Czy na pewno chcesz zmienić hasło kierowcy:",
                    message: colorDriverName,
                    buttons: [
                      {
                        text: "Anuluj",
                        role: "cancel",
                      },
                      {
                        text: "Wygeneruj hasło",
                        cssClass: "text-danger text-strong",
                        role: "confirm",
                        handler: () => {
                          presentLoading("Generowanie hasła");

                          api
                            .patch(
                              "drivers/" + colorDriverId + "/change-password"
                            )
                            .then((e) => {
                              const data = e.data;

                              refetch();

                              setReturnedPassword(data);
                              setIsPasswordOpen(true);
                            })
                            .catch(() => {
                              dismissLoading();
                            });
                        },
                      },
                    ],
                  });
                }}
                button={true}
                detail={false}
              >
                <IonLabel>Zmień hasło</IonLabel>
                <IonIcon icon={lockClosed} slot="start"></IonIcon>
              </IonItem>
              <IonItem
                onClick={() => {
                  setIsPickerOpen(true);
                }}
                button={true}
                detail={false}
              >
                <IonLabel>Zmień kolor</IonLabel>
                <IonIcon icon={colorPalette} slot="start"></IonIcon>
              </IonItem>
              <IonItem
                onClick={() => {
                  presentAlert({
                    subHeader: "Czy na pewno chcesz usunąć kierowcę:",
                    message: colorDriverName,
                    buttons: [
                      {
                        text: "Anuluj",
                        role: "cancel",
                      },
                      {
                        text: "Usuń",
                        cssClass: "text-danger text-strong",
                        role: "confirm",
                        handler: () => {
                          presentLoading("Usuwanie kierowcy");

                          api
                            .patch("drivers/" + colorDriverId + "/hide")
                            .then(() => {
                              refetch();
                            })
                            .catch(() => {
                              dismissLoading();
                            });
                        },
                      },
                    ],
                  });
                }}
                button={true}
                detail={false}
              >
                <IonLabel>Usuń kierowcę z listy</IonLabel>
                <IonIcon icon={trash} slot="start"></IonIcon>
              </IonItem>

              {/* <IonPopover
                trigger="nested-trigger"
                dismissOnSelect={true}
                side="end"
              >
                <IonContent>
                  <IonList>
                    <IonItem button={true} detail={false}>
                      Nested option
                    </IonItem>
                  </IonList>
                </IonContent>
              </IonPopover> */}
            </IonList>
          </IonContent>
        </IonPopover>
      </div>
    </IonPage>
  );
};

export default Drivers;
