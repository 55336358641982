import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  TextField,
} from "@mui/material";
import "./DriversScanTable.css";
import {
  IonCol,
  IonContent,
  IonDatetime,
  IonModal,
  IonRow,
  useIonLoading,
  useIonViewWillEnter,
} from "@ionic/react";
import { Container } from "@mui/system";

import api from "./../services/api";
import RegionAutocomplete from "./RegionAutocomplete";
import { useEffect, useState } from "react";
import DriversAutocomplete, { Option } from "./DriversAutocomplete";

type DriversScanTableProps = {
  routeId: string;
  region: string;
  driverName: string;
  driverId: string;
  scannedPercentage: string;
  scannedPercentageColor: string;
  scannedCount: number;
  clickedCount: number;
  undone: number;
  unscannedReason: string;
  unscannedReasonUser: string;
};

type DriversWarehouseDietProps = {
  id: number;
  guid: string;
  shortGuid: string;
  dietCode: string;
  dietName: string;
  isScanned: number;
  scannedDate: string;
  color: string;
};

type DriversWarehouseProps = {
  driverName: string;
  deliveryDate: string;
  stops: DriversWarehouseDietProps[];
};

interface ContainerProps {}

const DriversScanTable: React.FC<ContainerProps> = () => {
  const [_rows, _setRows] = useState<DriversScanTableProps[]>([]);
  const [rows, setRows] = useState<DriversScanTableProps[]>([]);

  const [region, setRegion] = useState<string[]>([]);
  const [driver, setDriver] = useState<Option[]>([]);

  const [date, setDate] = useState<Date>();

  const [availableDays, setAvailableDays] = useState<string[]>();

  const [presentLoading, dismissLoading] = useIonLoading();

  const [warehouseDetailsModalOpen, setWarehouseDetailsModalOpen] =
    useState(false);

  const [warehouseModalUserRow, setWarehouseModalUserRow] = useState<DriversScanTableProps>();
  const [warehouseData, setWarehouseData] = useState<DriversWarehouseProps>();

  useEffect(() => {
    if (!warehouseDetailsModalOpen) {
    }
  }, [warehouseDetailsModalOpen]);

  useEffect(() => {
    let tempRows = _rows;

    if (region.length > 0) {
      tempRows = tempRows.filter(
        (e) => region.some((k) => k.toLocaleLowerCase() == e.region.toLowerCase())
      );
    }
    if (driver.length > 0) {
      tempRows = tempRows.filter(
        (e) => driver.some((k) => k.value.toLocaleLowerCase() == e.driverName.toLowerCase())
      );
    }

    setRows(tempRows);
  }, [region, driver, _rows]);

  useEffect(() => {
    if (date) {
      presentLoading();

      api
        .get("report", {
          params: {
            Date: date.toLocaleDateString("pl-PL", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
          },
        })
        .then((response) => {
          _setRows(response.data);
        })
        .finally(() => {
          dismissLoading();
        });
    }
  }, [date]);

  useEffect(() => {
    if (!availableDays) {
      api.get("report/available-days").then((response) => {
        setAvailableDays(response.data);

        let tempDate = new Date(response.data[response.data.length - 1]);
        tempDate.setHours(5);

        setDate(tempDate);
      });
    }
  }, []);

  return (
    <Container style={{ marginTop: "15px" }} maxWidth={"xl"}>
      <IonModal
        style={{
          "--width": "auto",
          "--height": "90vh",
          "--overflow": "auto",
          alignItems: "end",
        }}
        isOpen={warehouseDetailsModalOpen}
        onIonModalDidDismiss={() => {
          setWarehouseDetailsModalOpen(false);
          setWarehouseModalUserRow(undefined);
        }}
      >
        <IonRow>
        <TableContainer style={{
          margin: "30px 30px 45px",
          borderRadius: "9px",
          border: "1px solid rgb(224 224 224)"
        }}>
                <Table
                  sx={{ minWidth: 680, "& th,td": { padding: "12px 13px" } }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        Region
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        }}
                        align="right"
                      >
                        Pojazd
                      </TableCell>
                      <TableCell align="center">
                        Procent zeskanowanych
                      </TableCell>
                      <TableCell align="center">Zeskanowane</TableCell>
                      <TableCell align="center">Odkliknięte</TableCell>
                      <TableCell align="center">Nieodhaczone</TableCell>
                      <TableCell align="center">Zgłaszający brak skanowania</TableCell>
                      <TableCell align="center">Powód</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow
                        className="row-hover"
                        key={warehouseModalUserRow?.driverId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {warehouseModalUserRow?.region}
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            fontWeight: "500",
                            letterSpacing: "1px",
                          }}
                          align="center"
                        >
                          {warehouseModalUserRow?.driverName}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            padding: "0",
                            background: warehouseModalUserRow?.scannedPercentageColor,
                          }}
                          align="center"
                        >
                          {warehouseModalUserRow?.scannedPercentage}
                        </TableCell>
                        <TableCell align="center">{warehouseModalUserRow?.scannedCount}</TableCell>
                        <TableCell align="center">{warehouseModalUserRow?.clickedCount}</TableCell>
                        <TableCell align="center">{warehouseModalUserRow?.undone}</TableCell>
                        <TableCell align="center">{warehouseModalUserRow?.unscannedReasonUser}</TableCell>
                        <TableCell align="center">{warehouseModalUserRow?.unscannedReason}</TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <TableContainer className="janek-shadow">
              <Table
                sx={{
                  minWidth: 680,
                  "& th,td": { padding: "12px 13px" },
                  th: {
                    borderRight: "1px solid rgba(224, 224, 224, 1) !important",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">Nazwa</TableCell>
                    <TableCell align="center">Kod</TableCell>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Data synchronizacji</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {warehouseData?.stops.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        td: {
                          borderRight:
                            "1px solid rgba(224, 224, 224, 1) !important",
                        },
                      }}
                    >
                      <TableCell
                        align="center"
                        style={{ background: row.color, width: "44.5px" }}
                      ></TableCell>
                      <TableCell align="center">{row.dietName}</TableCell>
                      <TableCell align="center">{row.dietCode}</TableCell>
                      <TableCell align="center">{row.shortGuid}</TableCell>
                      <TableCell align="center">{row.scannedDate}</TableCell>
                      {/* <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row.dietCode}
                        </TableCell> */}
                      {/* <TableCell
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            fontWeight: "500",
                            letterSpacing: "1px",
                          }}
                          align="center"
                        >
                          {row.driverName}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            padding: "0",
                            background: row.scannedPercentageColor,
                          }}
                          align="center"
                        >
                          {row.scannedPercentage}
                        </TableCell>
                        <TableCell align="center">{row.scannedCount}</TableCell>
                        <TableCell align="center">{row.clickedCount}</TableCell>
                        <TableCell align="center">{row.undone}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </IonCol>
        </IonRow>
      </IonModal>

      <IonRow  className="ion-justify-content-between">
        <IonCol size="12" sizeMd="9" className="order-2 order-md-1">
          <IonRow className="justify-content-center justify-content-md-between">
            <IonCol size="12" sizeMd="auto">
              <RegionAutocomplete width="400px" multiple setRegions={setRegion} />
            </IonCol>
            <IonCol size="12" sizeMd="auto">
              <DriversAutocomplete width="400px" multiple setDrivers={setDriver} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <TableContainer className="janek-shadow">
                <Table
                  sx={{ minWidth: 680, "& th,td": { padding: "12px 13px" } }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        Region
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        }}
                        align="right"
                      >
                        Pojazd
                      </TableCell>
                      <TableCell align="center">
                        Procent zeskanowanych
                      </TableCell>
                      <TableCell align="center">Zeskanowane</TableCell>
                      <TableCell align="center">Odkliknięte</TableCell>
                      <TableCell align="center">Nieodhaczone</TableCell>
                      <TableCell align="center">Zgłaszający brak skanowania</TableCell>
                      <TableCell align="center">Powód</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        className="row-hover"
                        key={row.driverId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={() => {
                          presentLoading();

                          setWarehouseModalUserRow(row);

                          api
                            .get("report/" + row.routeId)
                            .then((response) => {
                              setWarehouseData(
                                response.data as DriversWarehouseProps
                              );

                              setWarehouseDetailsModalOpen(true);
                            })
                            .finally(() => {
                              dismissLoading();
                            });
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row.region}
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            fontWeight: "500",
                            letterSpacing: "1px",
                          }}
                          align="center"
                        >
                          {row.driverName}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            padding: "0",
                            background: row.scannedPercentageColor,
                          }}
                          align="center"
                        >
                          {row.scannedPercentage}
                        </TableCell>
                        <TableCell align="center">{row.scannedCount}</TableCell>
                        <TableCell align="center">{row.clickedCount}</TableCell>
                        <TableCell align="center">{row.undone}</TableCell>
                        <TableCell align="center">{row.unscannedReasonUser}</TableCell>
                        <TableCell align="center">{row.unscannedReason}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol size="12" sizeMd="auto" className="order-1 order-md-2">
          <div style={{ position: "sticky", top: "20px" }}>
          <h2 style={{ textAlign: "center", maxWidth: "240px", margin: "auto auto 25px auto" }}>
            Data dostawy:{" "}
            <strong>
              {date?.toLocaleDateString("pl-PL", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </strong>
          </h2>
          {availableDays && date ? (
            <IonDatetime
              firstDayOfWeek={1}
              style={{
                // position: "sticky",
                // top: "125px",
                margin: "auto",
              }}
              value={date.toISOString()}
              presentation="date"
              mode="ios"
              className="janek-shadow"
              onIonChange={(e) => {
                if (e.target.value) {
                  setDate(new Date(e.target.value as string));
                  console.log(new Date(e.target.value as string));
                }
              }}
              isDateEnabled={(e) => {
                let today = new Date();
                let tomorrow = new Date();
                tomorrow.setDate(today.getDate() + 1);

                const valueDate = new Date(e);

                if (
                  valueDate <= tomorrow &&
                  availableDays.includes(
                    valueDate.toISOString().replace(".000Z", "")
                  )
                ) {
                  return true;
                } else {
                  return false;
                }
              }}
            />
          ) : (
            <></>
          )}
          </div>
        </IonCol>
      </IonRow>
    </Container>
  );
};

export default DriversScanTable;
