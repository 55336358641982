import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { GenerateGUID } from "../Common";

import api from "./../../services/api";

// import { v4 as uuidv4, v4 } from "uuid";

type Props = {
  setDriver: React.Dispatch<React.SetStateAction<string>>;
  setDriverId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  width?: string;
  fullWidth?: boolean;
};

type Option = {
  id: string;
  value: string;
}

const DriversAutocomplete: React.FC<Props> = ({
  setDriver,
  setDriverId,
  width,
  fullWidth = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Option[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    api.get("autocomplete/drivers").then((response) => {
      let data = response.data as Option[];
      setOptions(data);
    });

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      noOptionsText="Nie znaleziono"
      // id={v4()}
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: "100%"
        },
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => {
        return option.value;
      }}
      onChange={(event, value) => {
        setDriver(value?.value ?? "");
        if(setDriverId)
        {
          setDriverId(value?.id);
        }
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant="filled"
          type={"search"}
          className="shadow-mui"
          {...params}
          label="Trasa"
          fullWidth={true}
          id={GenerateGUID()}
          autoComplete={GenerateGUID()}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default DriversAutocomplete;
