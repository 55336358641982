import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import api from "./../services/api";
import { GenerateGUID } from "./Common";

// import { v4 as uuidv4, v4 } from "uuid";

export type Option = {
  id: string;
  value: string;
}

type Props = {
  setDriver?: React.Dispatch<React.SetStateAction<string>>;
  setDrivers?: React.Dispatch<React.SetStateAction<Option[]>>;
  width?: string;
  fullWidth?: boolean;
  multiple?: boolean;
};

const DriversAutocomplete: React.FC<Props> = ({
  setDriver,
  setDrivers,
  width,
  fullWidth = false,
  multiple = false
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Option[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    api.get("autocomplete/drivers").then((response) => {
      setOptions(response.data);
    });

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      multiple={multiple}
      noOptionsText="Nie znaleziono"
      // id={v4()}
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: width ? width : 300,
        },
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => {
        return option.value;
      }}
      onChange={(event, value) => {
        if(setDriver)
        {
          const val = value as Option | undefined;
          setDriver(val?.value ?? "");
        }
        if(setDrivers)
        {
          const val = value as Option[] | undefined;
          setDrivers(val ?? []);
        }
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          type={"search"}
          className="shadow-mui"
          {...params}
          id={GenerateGUID()}
          autoComplete={GenerateGUID()}
          label="Kierowca"
          fullWidth={true}
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default DriversAutocomplete;
