import {
  createAnimation,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { localePl, Popup, setOptions } from "@mobiscroll/react";
import {
  checkmarkCircle,
  chevronDown,
  chevronUp,
  closeCircle,
  cloudUpload,
} from "ionicons/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./CsvList.scss";
import api from "./../services/api";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";

import Auth from "./../services/auth.service";

import { useInterval } from "usehooks-ts";

import { Virtuoso } from "react-virtuoso";

type CsvListResponse = {
  id: string;
  _AddDate: string;
  addDate: string;
  name: string;
  error: string;
  status: "success" | "error" | "processing" | "queue";
};

type Props = {};

const CsvList: React.FC<Props> = () => {
  const { setState, state } = useGlobalState();

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector("ion-backdrop")!)
      .fromTo("opacity", "1", "var(--backdrop-opacity)");

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "1", transform: "translate3d(0px, 100%, 0px)" },
        { offset: 1, opacity: "1", transform: "translate3d(0px, 0px, 0px)" },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing("ease-in-out")
      .duration(200)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction("reverse");
  };

  const [queue, setQueue] = useState<CsvListResponse[]>();
  const [errors, setErrors] = useState<CsvListResponse[]>();
  const [successes, setSuccesses] = useState<CsvListResponse[]>();

  useEffect(() => {
    return () => {
      modal.current?.dismiss();

      if (state.listInterval) {
        clearInterval(state.listInterval);
      }
    };
  }, []);

  const modalInterval = async () => {
    const user = await Auth.getCurrentUser();

    if (user) {
      api.get("routes/csv/list").then((e) => {
        let data = e.data as CsvListResponse[];

        data = data.sort(
          (a, b) =>
            new Date(b._AddDate).getTime() - new Date(a._AddDate).getTime()
        );

        const queueData = data
          .filter((e) => e.status == "processing")
          .concat(data.filter((e) => e.status == "queue"));

        setQueue(queueData);
        setErrors(data.filter((e) => e.status == "error"));
        setSuccesses(data.filter((e) => e.status == "success"));
      });
    }
  };

  //const [isModalOpen, setIsModalOpen] = useState(false);

  useInterval(async () => await modalInterval(), 5000);

  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <>
      <IonItem
        button
        // onClick={() => {
        //   setIsModalOpen(true);
        // }}
        id="csv-modal-button"
        style={{
          "--background": "rgb(101, 118, 255)",
        }}
        lines="none"
      >
        <IonIcon
          style={{ color: "white" }}
          icon={chevronUp}
          slot="start"
        ></IonIcon>
      </IonItem>

      <IonModal
        // onIonModalWillPresent={async () => {

        //   if(!state.listInterval)
        //   {

        //     await modalInterval();

        //   }

        // }}
        ref={modal}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        backdropDismiss={false}
        showBackdrop={false}
        // isOpen={isModalOpen}
        id="csv-modal"
        // onIonModalDidDismiss={() => setIsModalOpen(false)}
        trigger="csv-modal-button"
      >
        <IonItem
          button
          onClick={() => modal.current?.dismiss()}
          style={{
            "--background": "rgb(101, 118, 255)",
          }}
          lines="none"
        >
          <IonIcon
            style={{ color: "white" }}
            icon={chevronDown}
            slot="start"
          ></IonIcon>
        </IonItem>
        <IonRow>
          <IonCol size="4">
            <IonListHeader>
              <IonLabel className="csv-header">Kolejka</IonLabel>
            </IonListHeader>

            {queue ? (
              <Virtuoso
                style={{ height: "597px", overflow: "auto" }}
                totalCount={100}
                fixedItemHeight={63}
                data={queue}
                itemContent={(index, e) => {
                  return (
                    <IonItem lines="full">
                      {e.status == "processing" ? (
                        <IonSpinner
                          style={{ marginRight: "20px" }}
                          color="secondary"
                          name="crescent"
                        ></IonSpinner>
                      ) : (
                        <IonIcon
                          style={{ marginRight: "20px" }}
                          icon={cloudUpload}
                          slot="start"
                        ></IonIcon>
                      )}

                      <IonLabel>
                        <h3 style={{ fontWeight: 700 }}>{e.name}</h3>
                        <p>{e.addDate}</p>
                      </IonLabel>
                    </IonItem>
                  );
                }}
              />
            ) : (
              <></>
            )}
          </IonCol>
          <IonCol size="4">
            <IonListHeader>
              <IonLabel className="csv-header">Błędy</IonLabel>
            </IonListHeader>

            {errors ? (
              <Virtuoso
                style={{ height: "597px", overflow: "auto" }}
                totalCount={100}
                fixedItemHeight={63}
                data={errors}
                itemContent={(index, e) => {
                  return (
                    <IonItem lines="full">
                      <IonIcon
                        style={{ marginRight: "20px" }}
                        icon={closeCircle}
                        color="danger"
                        slot="start"
                      ></IonIcon>

                      <IonLabel>
                        <h3 style={{ fontWeight: 700 }}>{e.name}</h3>
                        <p>{e.addDate}</p>
                      </IonLabel>
                    </IonItem>
                  );
                }}
              />
            ) : (
              <></>
            )}
          </IonCol>
          <IonCol size="4">
            <IonListHeader>
              <IonLabel className="csv-header">Zakończone</IonLabel>
            </IonListHeader>

            {errors ? (
              <Virtuoso
                style={{ height: "597px", overflow: "auto" }}
                totalCount={100}
                fixedItemHeight={63}
                data={successes}
                itemContent={(index, e) => {
                  return (
                    <IonItem lines="full">
                      <IonIcon
                        style={{ marginRight: "20px" }}
                        icon={checkmarkCircle}
                        color="success"
                        slot="start"
                      ></IonIcon>
                      <IonLabel>
                        <h3 style={{ fontWeight: 700 }}>{e.name}</h3>
                        <p>{e.addDate}</p>
                      </IonLabel>
                    </IonItem>
                  );
                }}
              />
            ) : (
              <></>
            )}
          </IonCol>
        </IonRow>
      </IonModal>
    </>
  );
};

export default CsvList;
