import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonReorderGroup,
  IonReorder,
  IonModal,
  IonProgressBar,
  useIonLoading,
  useIonAlert,
} from "@ionic/react";
import "./Photos.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { RouteComponentProps, useHistory } from "react-router";

import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";
import Header from "../components/Header";

import { v4 as uuidv4 } from "uuid";
import {
  cloudDownloadOutline,
  download,
  downloadOutline,
  ellipsisHorizontal,
  ellipsisHorizontalCircleOutline,
  saveSharp,
  searchOutline,
} from "ionicons/icons";

import { useDropzone } from "react-dropzone";
import api from "./../services/api";

import TextField from "@mui/material/TextField";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NavigationIcon from "@mui/icons-material/Navigation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RegionAutocomplete from "../components/Drivers/RegionAutocomplete";
import MapRead, { MapRefProps } from "../components/MapRead";
import CitiesAutocomplete from "../components/Photos/CitiesAutocomplete";
import StreetsAutocomplete from "../components/Photos/StreetsAutocomplete";

type AutocompleteImagesUser = {
  firstName: string;
  lastName: string;
};

type AutocompleteImages = {
  date: string;
  image: string;
  day: string;
  driver: string;
  user: AutocompleteImagesUser;
};

const Photos: React.FC<RouteComponentProps> = ({ match }) => {
  const map = useRef<MapRefProps>(null);

  const [modalImage, setModalImage] = useState<string>("");
  const [showModalImage, setShowModalImage] = useState(false);

  const [presentAlert] = useIonAlert();

  const [showMap, setShowMap] = useState(false);

  const [region, setRegion] = useState<string>("");

  const [city, setCity] = useState<string>("");
  const [cityDisabled, setCityDisabled] = useState(true);

  const [street, setStreet] = useState<string>("");
  const [streetDisabled, setStreetDisabled] = useState(true);

  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("Wszystko");

  const [images, setImages] = useState<AutocompleteImages[]>([]);

  useEffect(() => {
    if (region && cityDisabled) {
      setCityDisabled(false);
    } else if (!cityDisabled && !Boolean(region)) {
      setCityDisabled(true);
      setStreetDisabled(true);
    }

    setCity("");
    setStreet("");
  }, [region]);

  useEffect(() => {
    if (region && streetDisabled) {
      setStreetDisabled(false);
    } else if (!streetDisabled && !Boolean(city)) {
      setStreetDisabled(true);
    }
    setStreet("");
  }, [city]);

  useEffect(() => {
    if (street) {
      api
        .get("autocomplete/images", {
          params: {
            region: region,
            city: city,
            street: street,
          },
        })
        .then((response) => {
          setImages(response.data);
        });
    }
  }, [street]);

  // useEffect(() => {

  //   if(city)
  //   {
  //     api.get("autocomplete/images").then((response) => {
  //       setImages(response.data);
  //     });
  //   }

  // }, [city])

  const [presentLoading, dismissLoading] = useIonLoading();

  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const history = useHistory();

  const [drivers, setDrivers] = useState<Driver[]>([]);

  const [isFilesModalVisible, setIsFilesModalVisible] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  useIonViewDidLeave(() => {
    setShowMap(false);
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let tempFiles = files;

      for (const _file of acceptedFiles) {
        tempFiles.push(_file);
      }

      setFiles([]);
      setFiles(tempFiles);
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  // useEffect(() => {
  //   if (drivers.length <= 0) {
  //     const tempDrivers = [];

  //     for (let i = 0; i < 60; i++) {
  //       tempDrivers.push("");
  //     }

  //     setDrivers(tempDrivers);
  //   }
  // }, []);

  const { setState, state } = useGlobalState();

  type DriverPoint = {
    postCode: string;
    city: string;
    street: string;
    houseNumber: string;
  };

  type Driver = {
    id: string;
    role: string;
    name: string;
    routeId: string;
    region: string;
    status: boolean;
    password: string;
    color: string;
    colorLight: string;
    startPoint: DriverPoint;
    endPoint: DriverPoint;
    successPoints: number;
    errorPoints: number;

    hidden: boolean;
  };

  // const getData = async () => {
  //   presentLoading({
  //     spinner: "crescent",
  //     message: "Pobieranie danych...",
  //     duration: 20000,
  //   });

  //   await api
  //     .get("drivers", {
  //       params: {
  //         region: region,
  //         search: search,
  //         status: status,
  //       },
  //     })
  //     .then((response) => {
  //       if(response.data)
  //       {
  //         setDrivers(response.data);
  //       }
  //     })
  //     .finally(() => {
  //       dismissLoading();
  //     });
  // };

  // useEffect(() => {
  //   getData();
  // }, [region, status]);

  return (
    <IonPage>
      <IonContent
        id="photos-page-controller"
        fullscreen={true}
        ref={ionContentRef}
        className={"background-lightgrey slide-transition-leave nk-main"}
      >
        <Header />

        <div className="nk-wrap ">
          {/* main header @s */}

          {/* main header @e */}
          {/* content @s */}
          <div className="nk-content ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">
                          Wyszukiwarka zdjęć{" "}
                          <span
                            style={{
                              color: "rgb(63, 84, 255)",
                              fontSize: "34px",
                            }}
                          >
                            {region ? region.toUpperCase() : ""}
                          </span>
                        </h3>
                        {/* <div className="nk-block-des text-soft">
                          <p>You have total 2,595 users.</p>
                        </div> */}
                      </div>
                      {/* .nk-block-head-content */}
                      <div className="nk-block-head-content">
                        <div className="toggle-wrap nk-block-tools-toggle">
                          <a
                            href="#"
                            className="btn btn-icon btn-trigger toggle-expand mr-n1"
                            data-target="pageMenu"
                          >
                            <em className="icon ni ni-menu-alt-r" />
                          </a>
                          {/* <div
                            className="toggle-expand-content"
                            data-content="pageMenu"
                          >
                            <ul className="nk-block-tools g-3">
                              <li>
                                <a
                                  href="#"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-download-cloud" />
                                  <span>Export</span>
                                </a>
                              </li>
                              <li className="nk-block-tools-opt">
                                <div className="drodown">
                                  <a
                                    href="#"
                                    className="dropdown-toggle btn btn-icon btn-primary"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-plus" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <a href="#">
                                          <span>Add User</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Add Team</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Import User</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                        {/* .toggle-wrap */}
                      </div>
                      {/* .nk-block-head-content */}
                    </div>
                    {/* .nk-block-between */}
                  </div>
                  {/* .nk-block-head */}

                  <Paper elevation={0}>
                    <div className="nk-block">
                      <div className="card card-bordered card-stretch">
                        <div className="card-inner-group">
                          <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                              <div className="card-tools">
                                <div className="form-inline flex-nowrap">
                                  <RegionAutocomplete setRegion={setRegion} isImages={true} />

                                  <CitiesAutocomplete
                                    city={city}
                                    setCity={setCity}
                                    disabled={cityDisabled}
                                    region={region}
                                  />

                                  <StreetsAutocomplete
                                    street={street}
                                    setStreet={setStreet}
                                    disabled={streetDisabled}
                                    region={region}
                                    city={city}
                                  />
                                </div>

                                {/* .form-inline */}
                              </div>
                              {/* .card-tools */}
                              <div className="card-tools mr-n1">
                                <ul className="btn-toolbar gx-1">
                                  {/* <li>
                                    <a
                                      className="btn btn-secondary btn-lg text-white"
                                      onClick={() => {
                                        setIsFilesModalVisible(true);
                                      }}
                                    >
                                      <em className="icon ni ni-user-fill" />{" "}
                                      <span>Dodaj kierowcę</span>
                                    </a>
                                  </li> */}
                                </ul>
                                {/* .btn-toolbar */}
                              </div>
                              {/* .card-tools */}
                            </div>
                            {/* .card-title-group */}
                            <div
                              className="card-search search-wrap"
                              data-search="search"
                            >
                              <div className="card-body">
                                <div className="search-content">
                                  <a
                                    href="#"
                                    className="search-back btn btn-icon toggle-search"
                                    data-target="search"
                                  >
                                    <em className="icon ni ni-arrow-left" />
                                  </a>
                                  <input
                                    type="text"
                                    className="form-control border-transparent form-focus-none"
                                    placeholder="Search by user or email"
                                  />
                                  <button className="search-submit btn btn-icon">
                                    <em className="icon ni ni-search" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* .card-search */}
                          </div>

                          <div className="card-inner p-3">
                            <div className="row">
                              {images.map((e) => {
                                return (
                                  <div className="col-auto mb-3 mt-1 photo">
                                    <Paper elevation={2}>
                                      <div
                                        style={{
                                          background: "rgb(101, 118, 255)",
                                          color: "white",
                                          fontWeight: 700,
                                          fontSize: "20px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <div style={{ padding: "5px 0" }}>
                                          {e.day} {e.date}
                                        </div>
                                        <div style={{
                                          position: "relative"
                                        }}>
                                        <IonImg
                                          onClick={() => {
                                            setModalImage(e.image);
                                            setShowModalImage(true);
                                          }}
                                          style={{
                                            height: "400px",
                                            cursor: "pointer",
                                          }}
                                          src={e.image}
                                        />
                                        <IonButton
                                          className="download-button"
                                          href={e.image}
                                        >
                                          <IonIcon
                                            className="download-icon"
                                            icon={cloudDownloadOutline}
                                          />
                                        </IonButton>
                                        <div className="driver-info-photo">
                                          {"Pojazd "}
                                          {
                                            e.driver
                                          }
    {
      e.user.firstName && e.user.lastName
      ?
      <>
      <br/>
      {e.user.firstName + " " + e.user.lastName}
      </>
      :
      <></>
    }
                                        </div>
                                        </div>
                                      </div>
                                    </Paper>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <IonModal
                            style={{
                              "--height": "auto",
                              "--width": "auto",
                            }}
                            isOpen={showModalImage}
                            onIonModalDidDismiss={() =>
                              setShowModalImage(false)
                            }
                          >
                            <IonImg
                              style={{ height: "90vh" }}
                              src={modalImage}
                            />
                          </IonModal>

                          {/* .card-inner */}
                        </div>
                        {/* .card-inner-group */}
                      </div>
                      {/* .card */}
                    </div>
                  </Paper>

                  {/* .nk-block */}
                </div>
              </div>
            </div>
          </div>
          {/* content @e */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Photos;
