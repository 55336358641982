import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Input, localePl, Popup, setOptions } from "@mobiscroll/react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { ChangeEvent, forwardRef, ReactNode, SetStateAction, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import api from "./../../services/api";

import { v4 as uuidv4, v4 } from "uuid";
import axios from "axios";
import mapboxgl from "mapbox-gl";

type Props = {
  setLat: React.Dispatch<React.SetStateAction<number>>;
  setLng: React.Dispatch<React.SetStateAction<number>>;
  setZoom?: React.Dispatch<React.SetStateAction<number>>;
  map: React.MutableRefObject<mapboxgl.Map | null>;
  onclick?: (lat: number, lng: number) => void;
  draggedPointId?: string;
}

type Options = {
  text: string;
  key: string;
}

const MapAutocomplete: React.FC<Props> = ({ setLat, setLng, map, onclick, draggedPointId }) => {

  const mapboxSearchMarker = useRef<mapboxgl.Marker>();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Options[]>([]);
  const loading = open && options.length === 0;

  const [query, setQuery] = useState("");
  const [_search, _setSearch] = React.useState("");

  useEffect(() => {
    const timeOutId = setTimeout(() => {

      axios
        .get(
          "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=json&maxSuggestions=5&countryCode=PL&text=" +
            query
        )
        .then((response) => {
          let tempArray: Options[] = [];

          for (const n of response.data.suggestions) {
            tempArray.push({
              key: n.magicKey,
              text: n.text,
            });
          }

          setOptions(tempArray);

          

          

        });

    }, 300);
    return () => clearTimeout(timeOutId);
  }, [query]);



  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    return () => {
      active = false;
    };
  }, [loading]);


  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete

      onFocus={(event) => {
        const target = event.target as HTMLInputElement;
        if(target.autocomplete)
        {
          target.autocomplete = "whatever";
        }
      }}

      noOptionsText="Nie znaleziono"
      id={v4()}
      sx={{ width: 500 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.text === value.text}
      getOptionLabel={(option) => {
        return option.text
      }}
      filterOptions={(x) => x}
      options={options}
      renderOption={(props, option) => <div className="geocode" onClick={() => {

        axios
        .get(
          "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=json&magicKey=" +
            option.key
        )
        .then((response) => {

          if(onclick)
          {
            onclick(response.data.candidates[0].location.y, response.data.candidates[0].location.x);
          }
          
          setLat(response.data.candidates[0].location.y);
          setLng(response.data.candidates[0].location.x);

          setOpen(false);
          setQuery("");

          if (map.current) {
            if (mapboxSearchMarker.current) {
              mapboxSearchMarker.current.remove();
            }

            if(!onclick)
            {

              mapboxSearchMarker.current = new mapboxgl.Marker({
                color: "#ffb732",
                scale: 1.4,
              })
                .setLngLat([
                  response.data.candidates[0].location.x,
                  response.data.candidates[0].location.y,
                ])
                .addTo(map.current);
  
                mapboxSearchMarker.current.getElement().addEventListener('click', () => {
                  if (mapboxSearchMarker.current) {
                    mapboxSearchMarker.current.remove();
                  }
                });

            }
            
            

          }

        });

      }} ><span className="f">{option.text.split(",")[0]}</span><span>{option.text.split(",").map((e, i) => (i == 0 ? "" : e + " "))}</span></div>}
      loading={loading}
      renderInput={(params) => (
        <TextField
          onChange={(event) => {
            event.preventDefault()
            setQuery(event.target.value)   
          }}
          value={query}
          variant="filled"
          type={"search"}
          className="shadow-mui"
          {...params}
          label="Wyszukaj adres"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );

};

export default MapAutocomplete;