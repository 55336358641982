import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { GenerateGUID } from "../Common";

import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import api from "./../../services/api";

// import { v4 as uuidv4, v4 } from "uuid";

type Option = {
  id: string;
  value: string;
}

type Props = {
  setDriver: React.Dispatch<React.SetStateAction<string>>;
  setDriverId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  width?: string;
  fullWidth?: boolean;
};

const DriverUsersAutocomplete: React.FC<Props> = ({
  setDriver,
  setDriverId,
  width,
  fullWidth = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Option[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    api.get("autocomplete/driver-users").then((response) => {
      setOptions(response.data);
    });

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      // onFocus={(event) => {
      //   const target = event.target as HTMLInputElement;
      //   if (target.autocomplete) {
      //     target.autocomplete = "whatever";
      //   }
      // }}
      noOptionsText="Nie znaleziono"
      // id={v4()}
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: "100%"
        },
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => {
        return option.value;
      }}
      renderOption={(props, option) => {

        const splitted = option.value.split(' | ');

        if(splitted.length > 1)
        {
          return (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <span style={{ fontSize: "17px" }}>{splitted[0]}</span>
              <span style={{ color: "rgb(176, 176, 176)", marginLeft: "15px", fontWeight: 600 }}> 
                {splitted[1]}
                <LocalShippingIcon style={{ color: "rgb(176, 176, 176)", marginTop: "-2px", marginLeft: "4px" }}  />
              </span>
            </Box>
          )
        }
        else
        {
          return (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {splitted[0]}{" "}{splitted[1]}
            </Box>
          )
        }
      }}
      onChange={(event, value) => {
        setDriver(value?.value ?? "");
        if(setDriverId)
        {
          setDriverId(value?.id);
        }
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant="filled"
          type={"search"}
          className="shadow-mui"
          {...params}
          id={GenerateGUID()}
          autoComplete={GenerateGUID()}
          label="Kierowca"
          fullWidth={true}
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default DriverUsersAutocomplete;
