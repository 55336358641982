import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { GenerateGUID } from "../components/Common";

import api from "./../services/api";

// import { v4 as uuidv4, v4 } from "uuid";

type Props = {
  setPunishmentComment?: React.Dispatch<React.SetStateAction<string>>;
  option: string;
};

const PunishmentCommentTextfield: React.FC<Props> = ({
  setPunishmentComment,
  option,
}) => {
  return (
    <TextField
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: "100%",
        },
      }}
      value={option}
      id={GenerateGUID()}
      autoComplete={GenerateGUID()}
      variant="outlined"
      label={"Komentarz"}
      placeholder="Wpisz słowo kluczowe"
      onChange={(event) => {
        if (setPunishmentComment) {
          setPunishmentComment(event.target.value!);
        }
      }}
    ></TextField>
  );
};

export default PunishmentCommentTextfield;
