import {
  IonCol,
  IonDatetime,
  IonGrid,
  IonModal,
  IonRow,
  isPlatform,
} from "@ionic/react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import api from "./../../services/api";
import { GenerateGUID } from "../Common";

// import { v4 as uuidv4, v4 } from "uuid";

type Props = {
  setDate: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
  isDateEnabled?: ((dateIsoString: string) => boolean) | undefined;
};

const DeliveryDate: React.FC<Props> = ({ setDate, disabled, isDateEnabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [_date, _setDate] = useState<string>();
  const [_dateFriendly, _setDateFriendly] = useState<string>();

  const [_dateFriendlyTextField, _setDateFriendlyTextField] =
    useState<string>("");

  return (
    <>
      <TextField
        value={_dateFriendlyTextField}
        id={GenerateGUID()}
        autoComplete={GenerateGUID()}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarMonthIcon />
            </InputAdornment>
          ),
        }}
        variant="filled"
        style={{
          width: "100%",
        }}
        label="Data dostawy diety"
        onClick={() => {
          if (!disabled) {
            setIsModalOpen(true);
          }
        }}
      ></TextField>

      <IonModal
        style={{
          "--width": "auto",
          "--height": "auto",
        }}
        isOpen={isModalOpen}
        keepContentsMounted={true}
        onIonModalDidDismiss={() => setIsModalOpen(false)}
      >
        <IonDatetime
          isDateEnabled={isDateEnabled}
          value={_date}
          presentation="date"
          firstDayOfWeek={1}
          mode="ios"
          id="datetime"
          onIonChange={(e) => {
            if (e.target.value) {
              _setDate(e.target.value as string);

              let date = new Date(e.target.value as string);
              let options: Intl.DateTimeFormatOptions = {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
              };

              let dateFriendly = date.toLocaleDateString("pl-PL", options);

              _setDateFriendly(dateFriendly);
            }
          }}
        ></IonDatetime>
        <Button
          onClick={() => {
            if (_date) {
              setDate(_date);
              setIsModalOpen(false);
              if (_dateFriendly) {
                _setDateFriendlyTextField(_dateFriendly);
              }
            }
          }}
          variant="contained"
          fullWidth
        >
          Ustaw datę <br />
          {_dateFriendly}
        </Button>
      </IonModal>
    </>
  );
};

export default DeliveryDate;
