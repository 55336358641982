import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonReorderGroup,
  IonReorder,
  IonModal,
  IonProgressBar,
  useIonLoading,
  useIonAlert,
  IonRow,
  IonCol,
} from "@ionic/react";
import "./IssueDiet.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { RouteComponentProps, useHistory } from "react-router";

import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import Header from "../components/Header";

import api from "./../services/api";

import TextField from "@mui/material/TextField";
import {
  Button,
  Chip,
  debounce,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Select,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";

import { GenerateGUID, NumberToMoneyString } from "../components/Common";
import { LoadingButton } from "@mui/lab";

import VisibilityIcon from "@mui/icons-material/Visibility";
import DriverUsersAutocomplete from "../components/DriverReports/DriverUsersAutocomplete";
import DriverNameAutocomplete from "../components/DriverNameAutocomplete";
import IssueTypeSelect from "../components/IssueTypeSelect";
import RouteAutocomplete from "../components/RouteAutocomplete";

import AddressAutocomplete from "../components/AddressAutocomplete";
import CitiesAutocomplete from "../components/CitiesAutocomplete";

type NotificationAddressResponse = {
  street: string;
  city: string;
  postcode: string;
};

type NotificationResponse = {
  id: number;
  driverUser: string;
  driver: string;
  region: string;
  created: string;
  issueTypeName: string;
  receiverName: string;
  receiveLoading: boolean;
  address: NotificationAddressResponse;
  addressDiet: string;
  note: string;
  replacedDiet: string;
  replacedPackageParts: string[] | undefined;
  addressPackageParts: string[] | undefined;
};

const IssueDiet: React.FC<RouteComponentProps> = ({ match }) => {
  const [paymentCalculation, setPaymentCalculation] =
    useState<NotificationResponse>();

  const [isDescriptionModalVisible, setIsDescriptionModalVisible] =
    useState(false);

  const [showMap, setShowMap] = useState(false);

  const [region, setRegion] = useState<string>("");

  const [status, setStatus] = useState<string>("Wszystko");

  const [presentLoading, dismissLoading] = useIonLoading();

  const ionContentRef = useRef<HTMLIonContentElement>(null);

  const [issueDiets, setIssueDiets] = useState<NotificationResponse[]>([]);

  useIonViewDidLeave(() => {
    setShowMap(false);
  });

  const columns: GridColDef[] = [
    {
      field: "driverUser",
      headerName: "Kierowca",
      minWidth: 150,
      maxWidth: 200,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "driver",
      headerName: "Trasa",
      minWidth: 70,
      maxWidth: 70,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "text-capitalize",
    },
    {
      field: "issueTypeName",
      headerName: "Typ zgłoszenia",
      // width: 150,
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "created",
      headerName: "Data zgłoszenia",
      // width: 150,
      minWidth: 150,
      maxWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "addressCity",
      valueGetter: (params: GridValueGetterParams) => params.row.address.city,
    },
    {
      field: "addressStreetAndStreetNumber",
      valueGetter: (params: GridValueGetterParams) => params.row.address.street,
    },
    {
      field: "address",
      headerName: "Adres",
      // width: 150,
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "text-capitalize",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <div>
            <div>
              {params.row.address.city} {params.row.address.postcode}
            </div>
            <div>{params.row.address.street}</div>
          </div>
        );
      },
    },
    {
      field: "addressDiet",
      headerName: "ZGŁOSZONA dieta",

      minWidth: 160,
      maxWidth: 160,

      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <div>
            {params.row.addressPackageParts ? (
              <div>Tacki: {params.row.addressPackageParts}</div>
            ) : (
              <></>
            )}

            <div>{params.row.addressDiet}</div>
          </div>
        );
      },
    },
    {
      field: "replacedDiet",
      headerName: "WYMIENIONA dieta",

      minWidth: 170,
      maxWidth: 170,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <div>
            {params.row.replacedPackageParts ? (
              <div>Tacki: {params.row.replacedPackageParts}</div>
            ) : (
              <></>
            )}

            <div>{params.row.replacedDiet}</div>
          </div>
        );
      },
    },
    {
      field: "note",
      headerName: "Notatka",
      // width: 150,
      minWidth: 280,
      maxWidth: 280,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "17px",
              padding: "15px 0",
            }}
          >
            {params.row.note}
          </Box>
        );
      },
    },
    {
      field: "receiverName",
      headerName: "Odczytano przez",
      // width: 150,
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "",
      minWidth: 150,
      maxWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.receiverName) {
          return <></>;
        } else {
          return (
            <LoadingButton
              onClick={async () => {
                setIssueDiets(
                  issueDiets.map((el) =>
                    el.id === params.row.id
                      ? { ...el, receiveLoading: true }
                      : el
                  )
                );

                api
                  .patch("issueDiet/" + params.row.id + "/received")
                  .finally(async () => {
                    getDataDebounce();

                    // setIssueDiets(
                    //   issueDiets.map((el) =>
                    //     el.id === params.row.id
                    //       ? { ...el, receiveLoading: false }
                    //       : el
                    //   )
                    // );
                  });
              }}
              loading={params.row.receiveLoading}
              variant={"contained"}
              startIcon={<VisibilityIcon />}
              size={"small"}
            >
              Odczytaj
            </LoadingButton>
          );
        }
      },
    },
  ];

  const getDataDebounce = useMemo(() => {
    return debounce(async () => {
      await api.get("issueDiet").then((response) => {
        if (response.data) {
          setIssueDiets(response.data);
        }
      });
    }, 750);
  }, []);

  const getData = async () => {
    await presentLoading({
      spinner: "crescent",
      message: "Pobieranie danych...",
      duration: 20000,
    });

    await api
      .get("issueDiet")
      .then((response) => {
        if (response.data) {
          setIssueDiets(response.data);
        }
      })
      .finally(async () => {
        await dismissLoading();
      });
  };

  useEffect(() => {
    getData();
  }, [region, status]);

  const [searchValue, setSearchValue] = React.useState("");
  const apiRef = useGridApiRef();

  const updateSearchValue = React.useMemo(() => {
    return debounce((newValue) => {
      apiRef.current.setQuickFilterValues(
        newValue.split(" ").filter((word: string) => word !== "")
      );
    }, 500);
  }, [apiRef]);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  function handleSearchValueChange(event: any) {
    const newValue = event.target.value;
    setSearchValue(newValue);
    updateSearchValue(newValue);
  }

  // const memoDataGrid = useMemo(() => {
  //   return (
  //     <DataGridPro
  //       style={{
  //         height: "1000px"
  //       }}
  //       apiRef={apiRef}
  //       getRowClassName={(params) =>
  //         `background-color-${params.row.backgroundColor}`
  //       }
  //       isRowSelectable={(params: GridRowParams) =>
  //         !!params.row.routeId && !params.row.active
  //       }
  //       onSelectionModelChange={(newSelectionModel, details) => {
  //         setSelectionModel(newSelectionModel);
  //       }}
  //       selectionModel={selectionModel}
  //       sx={{
  //         fontSize: "16px",
  //       }}
  //       rows={issueDiets}
  //       columns={columns}
  //       hideFooter
  //       // pageSize={5}
  //       // rowsPerPageOptions={[5]}
  //       checkboxSelection
  //       disableRowSelectionOnClick

  //       // experimentalFeatures={{ newEditingApi: true }}
  //     />
  //   );
  // }, [issueDiets, selectionModel]);

  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });

  const [driverNames, setDriverNames] = useState<string[]>([]);
  const [routes, setRoutes] = useState<string[]>([]);
  const [issueTypes, setIssueTypes] = useState<string[]>([]);
  const [addresses, setAddresses] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (driverNames.length > 0) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("driverUser")
        );

        tempFilterModelItems.push({
          id: "driverUser",
          field: "driverUser",
          operator: "isAnyOf",
          value: driverNames,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("driverUser")
          ),
        });
      }
    }
  }, [driverNames]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (routes.length > 0) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("driver")
        );

        tempFilterModelItems.push({
          id: "driver",
          field: "driver",
          operator: "isAnyOf",
          value: routes,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("driver")
          ),
        });
      }
    }
  }, [routes]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (issueTypes.length > 0) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("issueTypeName")
        );

        tempFilterModelItems.push({
          id: "issueTypeName",
          field: "issueTypeName",
          operator: "isAnyOf",
          value: issueTypes,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("issueTypeName")
          ),
        });
      }
    }
  }, [issueTypes]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (addresses.length > 0) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("addressStreetAndStreetNumber")
        );

        tempFilterModelItems.push({
          id: "addressStreetAndStreetNumber",
          field: "addressStreetAndStreetNumber",
          operator: "isAnyOf",
          value: addresses,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("addressStreetAndStreetNumber")
          ),
        });
      }
    }
  }, [addresses]);

  useEffect(() => {
    if (apiRef.current && filterModel) {
      if (cities.length > 0) {
        let tempFilterModelItems = filterModel.items.filter(
          (e) => !(e.id as string).startsWith("addressCity")
        );

        tempFilterModelItems.push({
          id: "addressCity",
          field: "addressCity",
          operator: "isAnyOf",
          value: cities,
        });

        setFilterModel({
          ...filterModel,
          items: tempFilterModelItems,
        });
      } else {
        setFilterModel({
          ...filterModel,
          items: filterModel.items.filter(
            (e) => !(e.id as string).startsWith("addressCity")
          ),
        });
      }
    }
  }, [cities]);

  return (
    <IonPage>
      <IonContent
        id="payments-page-controller"
        fullscreen={true}
        ref={ionContentRef}
        className={"background-lightgrey slide-transition-leave nk-main"}
      >
        <Header />

        <div className="">
          {/* main header @s */}

          {/* main header @e */}
          {/* content @s */}
          <div className="nk-content ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">
                          Zgłoszenia kierowców{" "}
                          <span style={{ textTransform: "uppercase" }}>
                            {/* {dateTitle} */}
                          </span>
                          <span
                            style={{
                              color: "rgb(63, 84, 255)",
                              fontSize: "34px",
                            }}
                          >
                            {region ? region.toUpperCase() : ""}
                          </span>
                        </h3>
                        {/* <div className="nk-block-des text-soft">
                          <p>You have total 2,595 users.</p>
                        </div> */}
                      </div>
                      {/* .nk-block-head-content */}
                      <div className="nk-block-head-content">
                        <div className="toggle-wrap nk-block-tools-toggle">
                          <a
                            href="#"
                            className="btn btn-icon btn-trigger toggle-expand mr-n1"
                            data-target="pageMenu"
                          >
                            <em className="icon ni ni-menu-alt-r" />
                          </a>
                        </div>
                        {/* .toggle-wrap */}
                      </div>
                      {/* .nk-block-head-content */}
                    </div>
                    {/* .nk-block-between */}
                  </div>
                  {/* .nk-block-head */}
                  <IonRow>
                    <IonCol size="2">
                      <DriverNameAutocomplete
                        setDriverNames={setDriverNames}
                        multiple
                        options={[
                          ...new Set(issueDiets.map((e) => e.driverUser)),
                        ].sort()}
                      />
                    </IonCol>
                    <IonCol size="2">
                      <RouteAutocomplete
                        setRoutes={setRoutes}
                        multiple
                        options={[
                          ...new Set(issueDiets.map((e) => e.driver)),
                        ].sort()}
                      />
                    </IonCol>
                    <IonCol size="2">
                      <IssueTypeSelect
                        issueType={issueTypes}
                        setIssueTypes={setIssueTypes}
                        multiple
                        options={[
                          ...new Set(issueDiets.map((e) => e.issueTypeName)),
                        ].sort()}
                      />
                    </IonCol>
                    <IonCol size="3">
                      <AddressAutocomplete
                        setAddresses={setAddresses}
                        multiple
                        options={[
                          ...new Set(issueDiets.map((e) => e.address.street)),
                        ].sort()}
                      />
                    </IonCol>
                    <IonCol size="3">
                      <CitiesAutocomplete
                        setCities={setCities}
                        multiple
                        options={[
                          ...new Set(issueDiets.map((e) => e.address.city)),
                        ].sort()}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <Paper elevation={0}>
                        <div className="nk-block">
                          <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                              {/* .card-inner */}
                              <div
                                className="card-inner p-0"
                                // style={{ maxHeight: "800px", overflow: "auto" }}
                              >
                                {issueDiets.length > 0 ? (
                                  <Box
                                    sx={{
                                      width: "100%",
                                    }}
                                  >
                                    {/* <TextField
                                  id={GenerateGUID()}
                                  autoComplete={GenerateGUID()}
                                  style={{
                                    margin: "10px auto",
                                    width: "500px",
                                    display: "flex",
                                  }}
                                  variant="filled"
                                  value={searchValue}
                                  onChange={handleSearchValueChange}
                                  label="Wyszukaj"
                                  sx={{ mb: 1 }}
                                /> */}
                                    {/* {memoDataGrid} */}
                                    <DataGridPro
                                      columnVisibilityModel={{
                                        addressCity: false,
                                        addressStreetAndStreetNumber: false
                                      }}
                                      rowHeight={50}
                                      getRowHeight={() => "auto"}
                                      style={{
                                        height: "950px",
                                      }}
                                      apiRef={apiRef}
                                      filterModel={filterModel}
                                      getRowClassName={(params) => {
                                        if (params.row.receiverName) {
                                          return `issue-diet-row-disabled`;
                                        } else {
                                          return "";
                                        }
                                      }}
                                      isRowSelectable={(
                                        params: GridRowParams
                                      ) =>
                                        !!params.row.routeId &&
                                        !params.row.active
                                      }
                                      // onSelectionModelChange={(
                                      //   newSelectionModel,
                                      //   details
                                      // ) => {
                                      //   setSelectionModel(newSelectionModel);
                                      // }}
                                      // selectionModel={selectionModel}
                                      sx={{
                                        fontSize: "16px",
                                      }}
                                      rows={issueDiets}
                                      columns={columns}
                                      hideFooter
                                      // pageSize={5}
                                      // rowsPerPageOptions={[5]}
                                      // checkboxSelection
                                      disableRowSelectionOnClick

                                      // experimentalFeatures={{ newEditingApi: true }}
                                    />
                                  </Box>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            {/* .card-inner-group */}
                          </div>
                          {/* .card */}
                        </div>
                      </Paper>
                    </IonCol>
                  </IonRow>

                  {/* .nk-block */}
                </div>
              </div>
            </div>
          </div>
          {/* content @e */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default IssueDiet;
