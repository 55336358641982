import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonReorderGroup,
  IonReorder,
  IonModal,
  IonProgressBar,
  useIonLoading,
} from "@ionic/react";
import "./SingleRoute.scss";

import * as React from "react";

import {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { RouteComponentProps, useHistory, useParams } from "react-router";

import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";
import Header from "../components/Header";

import { v4 as uuidv4 } from "uuid";
import {
  ellipsisHorizontal,
  ellipsisHorizontalCircleOutline,
  searchOutline,
} from "ionicons/icons";

import { useDropzone } from "react-dropzone";
import api from "./../services/api";

import TextField from "@mui/material/TextField";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NavigationIcon from "@mui/icons-material/Navigation";
import RegionAutocomplete from "../components/Drivers/RegionAutocomplete";

import PhotoCamera from "@mui/icons-material/PhotoCamera";

import SendIcon from "@mui/icons-material/Send";
import { Storage } from "@capacitor/storage";
import Map, { MapRefProps } from "../components/Map";
import { GeoJSONSource } from "mapbox-gl";

const SingleRoute: React.FC<RouteComponentProps> = ({ match, location }) => {
  // const [mapChanges, setMapChanges] = useState<MapChanges[]>([]);

  const map = useRef<MapRefProps>(null);

  const [modalImage, setModalImage] = useState<string>("");
  const [showModalImage, setShowModalImage] = useState(false);

  const [presentLoading, dismissLoading] = useIonLoading();

  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const history = useHistory();

  const [routeAddresses, setRouteAddresses] = useState<RouteAddresses[]>([]);

  const [startPoint, setStartPoint] = useState<RoutePoint>();
  const [endPoint, setEndPoint] = useState<RoutePoint>();

  const [driverName, setDriverName] = useState<string>("");

  const [isFilesModalVisible, setIsFilesModalVisible] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let tempFiles = files;

      for (const _file of acceptedFiles) {
        tempFiles.push(_file);
      }

      setFiles([]);
      setFiles(tempFiles);
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const [region, setRegion] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("Wszystko");

  // useEffect(() => {
  //   if (drivers.length <= 0) {
  //     const tempDrivers = [];

  //     for (let i = 0; i < 60; i++) {
  //       tempDrivers.push("");
  //     }

  //     setDrivers(tempDrivers);
  //   }
  // }, []);

  type RouteAddressesPackages = {
    id: string;
    name: string;
    scanned: boolean;
    code: string;
    image: string;
  };

  type RoutePoint = {
    id: number;
    city: string;
    postCode: string;
    street: string;
    houseNumber: string;
    lat: number;
    lng: number;
  };

  type RouteAddresses = {
    id: number;
    order: number;
    city: string;
    postCode: string;
    street: string;
    houseNumber: string;
    lat: number;
    lng: number;
    comment: string;
    phone: string;
    image: string;
    packagesComplete: boolean;
    customerId: string;
    packages: RouteAddressesPackages[];
    score: number;
    imageCreatedDate: string;
    imageCreatedTime: string;
    imageUser: string;
    manual: boolean;
  };

  const getData = async (loading = true) => {
    if (loading) {
      presentLoading({
        spinner: "crescent",
        message: "Pobieranie danych...",
        duration: 20000,
      });
    }

    await api
      .get("routes/" + routeId, {
        params: {
          userId: userId,
        },
      })
      .then((response) => {
        setRouteAddresses(response.data.route);

        setStartPoint(response.data.startPoint);
        setEndPoint(response.data.endPoint);
      })
      .finally(() => {
        if (loading) {
          dismissLoading();
        }
      });
  };

  type Params = {
    routeId: string;
    userId: string;
  };
  const { routeId, userId } = useParams<Params>();
  const [isLoading, setIsLoading] = useState(true);
  useIonViewDidLeave(() => {
    setIsLoading(true);
  });
  useEffect(() => {
    getData();

    setIsLoading(false);
  }, [routeId, userId]);

  return (
    <IonPage>
      {/* <CustomHeaderFade
        page={"Strona główna"}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      /> */}

      <IonContent
        id="drivers-page-controller"
        fullscreen={true}
        ref={ionContentRef}
        className={"background-lightgrey slide-transition-leave nk-main"}
      >
        <Header />

        <IonModal
          style={{
            "--height": "auto",
            "--width": "auto",
          }}
          isOpen={showModalImage}
          onIonModalDidDismiss={() => setShowModalImage(false)}
        >
          <IonImg style={{ height: "90vh" }} src={modalImage} />
        </IonModal>

        {isLoading ? (
          <></>
        ) : (
          <div className="nk-wrap ">
            {/* main header @s */}

            {/* main header @e */}
            {/* content @s */}
            <div className="nk-content ">
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      {routeId ? (
                        <Map ref={map} GetData={getData} routeId={routeId} />
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="nk-block-head nk-block-head-sm">
                      <div className="nk-block-between">
                        <div className="nk-block-head-content">
                          {/* <h3 className="nk-block-title page-title">
                          Trasy pojazdu <span style={{ textTransform: "uppercase" }}>{driverName}</span>
                        </h3> */}
                          {/* <div className="nk-block-des text-soft">
                          <p>You have total 2,595 users.</p>
                        </div> */}
                        </div>
                        {/* .nk-block-head-content */}
                        <div className="nk-block-head-content">
                          <div className="toggle-wrap nk-block-tools-toggle">
                            <a
                              href="#"
                              className="btn btn-icon btn-trigger toggle-expand mr-n1"
                              data-target="pageMenu"
                            >
                              <em className="icon ni ni-menu-alt-r" />
                            </a>
                            {/* <div
                            className="toggle-expand-content"
                            data-content="pageMenu"
                          >
                            <ul className="nk-block-tools g-3">
                              <li>
                                <a
                                  href="#"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-download-cloud" />
                                  <span>Export</span>
                                </a>
                              </li>
                              <li className="nk-block-tools-opt">
                                <div className="drodown">
                                  <a
                                    href="#"
                                    className="dropdown-toggle btn btn-icon btn-primary"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-plus" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <a href="#">
                                          <span>Add User</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Add Team</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Import User</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div> */}
                          </div>
                          {/* .toggle-wrap */}
                        </div>
                        {/* .nk-block-head-content */}
                      </div>
                      {/* .nk-block-between */}
                    </div>
                    {/* .nk-block-head */}

                    <Paper elevation={0}>
                      <div className="nk-block">
                        <div className="card card-bordered card-stretch">
                          <div className="card-inner-group">
                            <div className="card-inner position-relative card-tools-toggle">
                              <div className="card-title-group">
                                <div className="card-tools">
                                  <div className="form-inline flex-nowrap">
                                    {/*
                                  <TextField
                                    className="shadow-mui"
                                    style={{
                                      width: "500px",
                                    }}
                                    id="outlined-search"
                                    label="Wyszukaj"
                                    type="search"
                                    onChange={(e) => {
                                      setSearch(e.target.value!);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key == "Enter") {
                                        getData();
                                      }
                                    }}
                                    value={search}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton
                                            onClick={() => getData()}
                                            aria-label="search"
                                          >
                                            <SearchIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  /> */}

                                    <div>
                                      <Button
                                        onClick={() => {
                                          if (startPoint) {
                                            map.current?.Map.current?.flyTo({
                                              center: {
                                                lat: startPoint.lat,
                                                lng: startPoint.lng,
                                              },
                                              zoom: 18,
                                              duration: 500,
                                            });
                                          }
                                        }}
                                        color={
                                          startPoint ? "success" : "primary"
                                        }
                                        variant="text"
                                        style={{ fontSize: "19px" }}
                                      >
                                        Początek trasy:&nbsp;
                                        {startPoint ? (
                                          <strong style={{ fontWeight: 800 }}>
                                            {" "}
                                            zobacz
                                          </strong>
                                        ) : (
                                          <strong style={{ fontWeight: 800 }}>
                                            {" "}
                                            NIE USTAWIONO
                                          </strong>
                                        )}
                                      </Button>

                                      {startPoint ? (
                                        <></>
                                      ) : (
                                        <Button
                                          className="ml-2"
                                          variant="outlined"
                                          style={{ fontSize: "19px" }}
                                          color="success"
                                          onClick={() => {
                                            if (map.current) {
                                              map.current.geojsonPoints.current.features.push(
                                                {
                                                  geometry: {
                                                    type: "Point",
                                                    coordinates: [
                                                      map.current?.Map.current?.getCenter()
                                                        .lng,
                                                      map.current?.Map.current?.getCenter()
                                                        .lat,
                                                    ],
                                                  },
                                                  properties: {
                                                    t: "S",
                                                    id: "-999",
                                                  },
                                                  type: "Feature",
                                                }
                                              );

                                              (
                                                map.current.Map.current?.getSource(
                                                  "points"
                                                ) as GeoJSONSource
                                              ).setData(
                                                map.current.geojsonPoints
                                                  .current
                                              );
                                            }
                                          }}
                                        >
                                          <strong style={{ fontWeight: 800 }}>
                                            dodaj
                                          </strong>
                                        </Button>
                                      )}

                                      {startPoint ? (
                                        <Button
                                          className="ml-2"
                                          variant="outlined"
                                          style={{ fontSize: "19px" }}
                                          color="error"
                                          onClick={() => {
                                            if (map.current) {
                                              map.current.geojsonPoints.current.features =
                                                (
                                                  map.current.geojsonPoints
                                                    .current.features as []
                                                ).filter((e: any) => {
                                                  return e.properties.t != "L";
                                                });

                                              (
                                                map.current.Map.current?.getSource(
                                                  "points"
                                                ) as GeoJSONSource
                                              ).setData(
                                                map.current.geojsonPoints
                                                  .current
                                              );

                                              setStartPoint(undefined);

                                              presentLoading({
                                                spinner: "crescent",
                                                message: "Usuwanie punktu...",
                                                duration: 20000,
                                              });

                                              api
                                                .delete("points", {
                                                  data: {
                                                    Points: [
                                                      {
                                                        Id: -666,
                                                        Lat: 0,
                                                        Lng: 0,
                                                        Type: "S",
                                                      },
                                                    ],
                                                    RouteId:
                                                      location.pathname.split(
                                                        "/"
                                                      )[3],
                                                  },
                                                })
                                                .then(async () => {
                                                  api
                                                    .post(
                                                      "routes/" +
                                                        location.pathname.split(
                                                          "/"
                                                        )[3] +
                                                        "/optimize"
                                                    )
                                                    .finally(async () => {
                                                      if (map.current) {
                                                        await map.current.UpdateGeojson();
                                                        await getData(false);
                                                      }

                                                      await dismissLoading();
                                                    });
                                                });
                                            }
                                          }}
                                        >
                                          <strong style={{ fontWeight: 800 }}>
                                            usuń
                                          </strong>
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  <div className="form-inline flex-nowrap mt-2">
                                    <Button
                                      onClick={() => {
                                        if (endPoint) {
                                          map.current?.Map.current?.flyTo({
                                            center: {
                                              lat: endPoint.lat,
                                              lng: endPoint.lng,
                                            },
                                            zoom: 18,
                                            duration: 500,
                                          });
                                        }
                                      }}
                                      color={endPoint ? "success" : "primary"}
                                      variant="text"
                                      style={{
                                        fontSize: "19px",
                                        marginLeft: "30px",
                                      }}
                                    >
                                      Koniec trasy:&nbsp;
                                      {endPoint ? (
                                        <strong style={{ fontWeight: 800 }}>
                                          {" "}
                                          zobacz
                                        </strong>
                                      ) : (
                                        <strong style={{ fontWeight: 800 }}>
                                          {" "}
                                          NIE USTAWIONO
                                        </strong>
                                      )}
                                    </Button>

                                    {endPoint ? (
                                      <></>
                                    ) : (
                                      <Button
                                        className="ml-2"
                                        variant="outlined"
                                        style={{ fontSize: "19px" }}
                                        color="success"
                                        onClick={() => {
                                          if (map.current) {
                                            map.current.geojsonPoints.current.features.push(
                                              {
                                                geometry: {
                                                  type: "Point",
                                                  coordinates: [
                                                    map.current?.Map.current?.getCenter()
                                                      .lng,
                                                    map.current?.Map.current?.getCenter()
                                                      .lat,
                                                  ],
                                                },
                                                properties: {
                                                  t: "L",
                                                  id: "-666",
                                                },
                                                type: "Feature",
                                              }
                                            );

                                            (
                                              map.current.Map.current?.getSource(
                                                "points"
                                              ) as GeoJSONSource
                                            ).setData(
                                              map.current.geojsonPoints.current
                                            );
                                          }
                                        }}
                                      >
                                        <strong style={{ fontWeight: 800 }}>
                                          dodaj
                                        </strong>
                                      </Button>
                                    )}

                                    {endPoint ? (
                                      <Button
                                        className="ml-2"
                                        variant="outlined"
                                        style={{ fontSize: "19px" }}
                                        color="error"
                                        onClick={() => {
                                          if (map.current) {
                                            map.current.geojsonPoints.current.features =
                                              (
                                                map.current.geojsonPoints
                                                  .current.features as []
                                              ).filter((e: any) => {
                                                return e.properties.t != "L";
                                              });

                                            (
                                              map.current.Map.current?.getSource(
                                                "points"
                                              ) as GeoJSONSource
                                            ).setData(
                                              map.current.geojsonPoints.current
                                            );

                                            setEndPoint(undefined);

                                            presentLoading({
                                              spinner: "crescent",
                                              message: "Usuwanie punktu...",
                                              duration: 20000,
                                            });

                                            api
                                              .delete("points", {
                                                data: {
                                                  Points: [
                                                    {
                                                      Id: -666,
                                                      Lat: 0,
                                                      Lng: 0,
                                                      Type: "L",
                                                    },
                                                  ],
                                                  RouteId:
                                                    location.pathname.split(
                                                      "/"
                                                    )[3],
                                                },
                                              })
                                              .then(async () => {
                                                api
                                                  .post(
                                                    "routes/" +
                                                      location.pathname.split(
                                                        "/"
                                                      )[3] +
                                                      "/optimize"
                                                  )
                                                  .finally(async () => {
                                                    if (map.current) {
                                                      await map.current.UpdateGeojson();
                                                      await getData(false);
                                                    }

                                                    await dismissLoading();
                                                  });
                                              });
                                          }
                                        }}
                                      >
                                        <strong style={{ fontWeight: 800 }}>
                                          usuń
                                        </strong>
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  {/* <RegionAutocomplete setRegion={setRegion} /> */}

                                  {/* <FormControl style={{ marginLeft: "30px", width: "200px" }}>
  <InputLabel id="demo-simple-select-label">Aktualna trasa</InputLabel>
  <Select
    className="shadow-mui"
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={status}
    label="Aktualna trasa"
    onChange={(e) => setStatus(e.target.value!)}
  >
    <MenuItem value={"Wszystko"}>Wszystko</MenuItem>
    <MenuItem value={"Dodano"}>Dodano</MenuItem>
    <MenuItem value={"Brak"}>Brak</MenuItem>
  </Select>
</FormControl> */}

                                  {/* .form-inline */}
                                </div>
                                {/* .card-tools */}
                                <div className="card-tools mr-n1">
                                  <ul className="btn-toolbar gx-1">
                                    {/* <li>
                                    <a
                                      className="btn btn-success btn-lg text-white"
                                      onClick={() => {
                                        presentLoading({
                                          spinner: "crescent",
                                          message: "Optymalizowanie trasy...",
                                          duration: 20000,
                                        });

                                        const params: any = match.params as any;

                                        api
                                          .post(
                                            "routes/" +
                                              params.routeId +
                                              "/optimize"
                                          )
                                          .finally(async () => {
                                            if (map.current) {
                                              await map.current.UpdateGeojson();
                                              await getData(false);
                                            }

                                            await dismissLoading();
                                          });
                                      }}
                                    >
                                      <em className="icon ni ni-setting" />{" "}
                                      <span>Zoptymalizuj trasę</span>
                                    </a>
                                  </li> */}

                                    {/* <li>
                                    <a
                                      className="btn btn-secondary btn-lg text-white"
                                      onClick={() => {
                                        setIsFilesModalVisible(true);
                                      }}
                                    >
                                      <em className="icon ni ni-user-fill" />{" "}
                                      <span>Dodaj kierowcę</span>
                                    </a>
                                  </li> */}

                                    {/* <IonModal
                                    backdropDismiss={false}
                                    style={{ "--height": "auto" }}
                                    isOpen={isFilesModalVisible}
                                    className=""
                                    onIonModalDidDismiss={() =>
                                      setIsFilesModalVisible(false)
                                    }
                                  >
                                    <>
                                      <div>
                                        <a
                                          className="close"
                                          style={{
                                            fontSize: "40px",
                                            float: "initial",
                                            cursor: "auto",
                                          }}
                                        >
                                          <em
                                            style={{
                                              marginLeft: "auto",
                                              display: "table",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              setIsFilesModalVisible(false)
                                            }
                                            className="icon ni ni-cross-sm"
                                          />
                                        </a>
                                        <div className="modal-body modal-body-md">
                                          <div className="nk-upload-form">
                                            <div
                                              className="upload-zone bg-lighter dropzone"
                                              style={{ padding: "45px 20px" }}
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              {isDragActive ? (
                                                <div className="row justify-content-center">
                                                  <div className="col-auto">
                                                    <span
                                                      className="dz-message-text"
                                                      style={{
                                                        fontSize: "30px",
                                                      }}
                                                    >
                                                      Upuść tutaj 👇🏻
                                                    </span>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="row justify-content-center">
                                                  <div className="col-auto">
                                                    <span
                                                      className="dz-message-text"
                                                      style={{
                                                        fontSize: "30px",
                                                      }}
                                                    >
                                                      Zarzuć plikiem 😎
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="nk-upload-list">
                                            <h6 className="title">
                                              Dodane pliki
                                            </h6>

                                            {files.map((e, index) => {
                                              return (
                                                <div className="nk-upload-item">
                                                  <div className="nk-upload-icon">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 72 72"
                                                    >
                                                      <g>
                                                        <path
                                                          d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
                                                          style={{
                                                            fill: "#599def",
                                                          }}
                                                        />
                                                        <path
                                                          d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z"
                                                          style={{
                                                            fill: "#c2e1ff",
                                                          }}
                                                        />
                                                        <rect
                                                          x={27}
                                                          y={31}
                                                          width={18}
                                                          height={2}
                                                          rx={1}
                                                          ry={1}
                                                          style={{
                                                            fill: "#fff",
                                                          }}
                                                        />
                                                        <rect
                                                          x={27}
                                                          y={36}
                                                          width={18}
                                                          height={2}
                                                          rx={1}
                                                          ry={1}
                                                          style={{
                                                            fill: "#fff",
                                                          }}
                                                        />
                                                        <rect
                                                          x={27}
                                                          y={41}
                                                          width={18}
                                                          height={2}
                                                          rx={1}
                                                          ry={1}
                                                          style={{
                                                            fill: "#fff",
                                                          }}
                                                        />
                                                        <rect
                                                          x={27}
                                                          y={46}
                                                          width={12}
                                                          height={2}
                                                          rx={1}
                                                          ry={1}
                                                          style={{
                                                            fill: "#fff",
                                                          }}
                                                        />
                                                      </g>
                                                    </svg>
                                                  </div>
                                                  <div className="nk-upload-info">
                                                    <div className="nk-upload-title">
                                                      <span className="title">
                                                        {e.name}
                                                      </span>{" "}
                                                    </div>
                                                  </div>
                                                  <div className="nk-upload-action">
                                                    <a
                                                      className="btn btn-icon btn-trigger"
                                                      onClick={() => {
                                                        let tempFiles: File[] =
                                                          [];

                                                        for (
                                                          let i = 0;
                                                          i < files.length;
                                                          i++
                                                        ) {
                                                          if (i != index) {
                                                            tempFiles.push(
                                                              files[i]
                                                            );
                                                          }
                                                        }

                                                        setFiles(tempFiles);

                                                        console.log(tempFiles);
                                                      }}
                                                    >
                                                      <em className="icon ni ni-trash" />
                                                    </a>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                          <div className="nk-modal-action justify-end">
                                            <ul className="btn-toolbar g-4 align-center">
                                              <li>
                                                <a
                                                  href="#"
                                                  className="link link-primary"
                                                >
                                                  Anuluj
                                                </a>
                                              </li>
                                              <li>
                                                <button
                                                  onClick={() => {
                                                    presentLoading({
                                                      spinner: "crescent",
                                                      message:
                                                        "Przesyłanie plików...",
                                                      duration: 20000,
                                                    });

                                                    if (files.length > 0) {
                                                      let formData =
                                                        new FormData();

                                                      for (const _file of files) {
                                                        formData.append(
                                                          "files",
                                                          _file
                                                        );
                                                      }

                                                      api
                                                        .post(
                                                          "routes/csv",
                                                          formData,
                                                          {
                                                            headers: {
                                                              "Content-Type":
                                                                "multipart/form-data",
                                                            },
                                                          }
                                                        )
                                                        .then((response) => {
                                                          console.log(response);
                                                        })
                                                        .finally(async () => {
                                                          await dismissLoading();

                                                          await getData();
                                                        });
                                                    }
                                                  }}
                                                  type="button"
                                                  className="btn btn-primary"
                                                >
                                                  Prześlij pliki
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  </IonModal> */}
                                  </ul>
                                  {/* .btn-toolbar */}
                                </div>
                                {/* .card-tools */}
                              </div>
                              {/* .card-title-group */}
                              {/* <div
                              className="card-search search-wrap"
                              data-search="search"
                            >
                              <div className="card-body">
                                <div className="search-content">
                                  <a
                                    href="#"
                                    className="search-back btn btn-icon toggle-search"
                                    data-target="search"
                                  >
                                    <em className="icon ni ni-arrow-left" />
                                  </a>
                                  <input
                                    type="text"
                                    className="form-control border-transparent form-focus-none"
                                    placeholder="Search by user or email"
                                  />
                                  <button className="search-submit btn btn-icon">
                                    <em className="icon ni ni-search" />
                                  </button>
                                </div>
                              </div>
                            </div> */}
                              {/* .card-search */}
                            </div>
                            {/* .card-inner */}
                            <div className="card-inner p-0">
                              <div className="nk-tb-list nk-tb-ulist is-compact">
                                <div className="nk-tb-item nk-tb-head">
                                  {/* <div className="nk-tb-col nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="uid"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="uid"
                                  />
                                </div>
                              </div> */}
                                  <div className="nk-tb-col tb-col-auto">
                                    <span className="sub-text">#</span>
                                  </div>
                                  <div className="nk-tb-col">
                                    <span className="sub-text text-right">
                                      Ulica
                                    </span>
                                  </div>
                                  {/* <div className="nk-tb-col">
                                  <span className="sub-text">ID</span>
                                </div>
                                <div className="nk-tb-col ">
                                  <span className="sub-text">Rola</span>
                                </div> */}
                                  <div className="nk-tb-col ">
                                    <span className="sub-text">Numer</span>
                                  </div>
                                  <div className="nk-tb-col ">
                                    <span className="sub-text">Adres</span>
                                  </div>
                                  <div className="nk-tb-col ">
                                    <span className="sub-text">Kierowca</span>
                                  </div>
                                  <div className="nk-tb-col ">
                                    <span className="sub-text">Zdjęcie</span>
                                  </div>
                                  <div className="nk-tb-col ">
                                    <span className="sub-text">
                                      Data zrobienia zdjęcia
                                    </span>
                                  </div>
                                  <div className="nk-tb-col ">
                                    <span className="sub-text">Telefon</span>
                                  </div>

                                  <div className="nk-tb-col ">Diety</div>

                                  <div className="nk-tb-col ">
                                    Poprawnośc adresu (max 100)
                                  </div>

                                  <div className="nk-tb-col "></div>
                                </div>

                                {/* <IonReorderGroup onIonItemReorder={(event) => event.detail.complete(true)} disabled={false} style={{ display: "table-row-group" }} > */}

                                {routeAddresses.map((e, i) => {
                                  return (
                                    <>
                                      <div className="nk-tb-item">
                                        {/* <div className="nk-tb-col nk-tb-col-check">
                                      <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={"uid" + i}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={"uid" + i}
                                        />
                                      </div>
                                    </div> */}
                                        <div
                                          className="nk-tb-col tb-col-auto"
                                          style={{ width: "50px" }}
                                        >
                                          <div className="user-card">
                                            <div className="user-name">
                                              <div
                                                className="tb-lead"
                                                style={{
                                                  letterSpacing: "0.5px",
                                                  fontSize: "19px",
                                                }}
                                              >
                                                {e.order < 0 ? (
                                                  <span
                                                    className="text-danger"
                                                    style={{
                                                      fontWeight: 700,
                                                      textTransform:
                                                        "uppercase",
                                                    }}
                                                  >
                                                    ERROR
                                                  </span>
                                                ) : (
                                                  <span
                                                    style={{
                                                      fontWeight: 700,
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    {e.order}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="nk-tb-col "
                                          style={{ width: "250px" }}
                                        >
                                          <div
                                            className="tb-lead"
                                            style={{
                                              letterSpacing: "0.5px",
                                              fontSize: "19px",
                                              textAlign: "right",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: 700,
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {e.street}
                                            </span>
                                          </div>
                                        </div>
                                        {/* <div className="nk-tb-col ">
                                        <span>{e.deliveryDay}</span>
                                      </div>
                                      <div className="nk-tb-col ">
                                        <span>{e.driverId}</span>
                                      </div> */}
                                        <div
                                          className="nk-tb-col "
                                          style={{ width: "100px" }}
                                        >
                                          <div
                                            className="tb-lead"
                                            style={{
                                              letterSpacing: "0.5px",
                                              fontSize: "19px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: 700,
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {e.houseNumber}
                                            </span>
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          className="nk-tb-col"
                                        >
                                          {e.city} {e.postCode}
                                        </div>
                                        <div
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          className="nk-tb-col"
                                        >
                                          {e.imageUser}
                                        </div>

                                        <div
                                          className="nk-tb-col"
                                          style={{ minWidth: "70px" }}
                                        >
                                          <IconButton
                                            disabled={!Boolean(e.image)}
                                            color="primary"
                                            aria-label="pokaż zdjęcie"
                                            component="span"
                                            onClick={() => {
                                              setModalImage(e.image);
                                              setShowModalImage(true);
                                            }}
                                          >
                                            <PhotoCamera />
                                          </IconButton>
                                        </div>

                                        <div
                                          className="nk-tb-col"
                                          style={{ minWidth: "190px" }}
                                        >
                                          <span>
                                            <strong
                                              style={{
                                                fontWeight: 700,
                                                fontSize: "19px",
                                                color: "rgb(25 118 210)",
                                                marginRight: "5px",
                                              }}
                                            >
                                              {e.imageCreatedTime}
                                            </strong>
                                          </span>{" "}
                                          <span>{e.imageCreatedDate}</span>
                                        </div>

                                        <div className="nk-tb-col">
                                          {e.phone}
                                        </div>

                                        <div className="nk-tb-col">
                                          <ul>
                                            {e.packages.map((_package) => {
                                              return (
                                                <li>
                                                  <span
                                                    className={
                                                      "text-" +
                                                      (_package.scanned
                                                        ? "success"
                                                        : "danger")
                                                    }
                                                  >
                                                    {_package.name}
                                                  </span>{" "}
                                                  {_package.image ? (
                                                    <a
                                                      className="text-primary"
                                                      onClick={() => {
                                                        setModalImage(
                                                          _package.image
                                                        );
                                                        setShowModalImage(true);
                                                      }}
                                                      style={{
                                                        fontWeight: 700,
                                                      }}
                                                    >
                                                      <IconButton
                                                        disabled={
                                                          !Boolean(
                                                            _package.image
                                                          )
                                                        }
                                                        color="primary"
                                                        aria-label="pokaż zdjęcie"
                                                        component="span"
                                                        onClick={() => {
                                                          setModalImage(
                                                            _package.image
                                                          );
                                                          setShowModalImage(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        <PhotoCamera />
                                                      </IconButton>
                                                    </a>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </div>

                                        <div className="nk-tb-col ">
                                          {e.manual ? (
                                            <span className="tb-status">
                                              {e.score} Manual
                                            </span>
                                          ) : e.score < 90 ? (
                                            <span
                                              className="tb-status text-danger"
                                              style={{ fontWeight: 700 }}
                                            >
                                              {e.score} - popraw adres
                                            </span>
                                          ) : (
                                            <span className="tb-status text-success">
                                              {e.score}
                                            </span>
                                          )}
                                        </div>

                                        {/* <div className="nk-tb-col nk-tb-col-tools">
                                <ul className="nk-tb-actions gx-2">

                                  <li>
                                    <div className="drodown">
                                      <a
                                        href="#"
                                        className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
                                        data-toggle="dropdown"
                                      >
                                        <em className="icon ni ni-more-h" />
                                      </a>

                                    </div>
                                  </li>
                                </ul>
                              </div> */}
                                        {/* <div className="nk-tb-col">
                                      <IonReorder slot="center" />
                                    </div> */}
                                        <div className="nk-tb-col ">
                                          {/* <IonButton
                                          color="medium"
                                          fill="clear"
                                          style={{
                                            height: "unset",
                                            "--padding-start": 0,
                                            "--padding-end": 0,
                                          }}
                                        >
                                          <IonIcon
                                            style={{ fontSize: "30px" }}
                                            src={ellipsisHorizontal}
                                          />
                                        </IonButton> */}

                                          <Button
                                            style={{
                                              float: "right",
                                            }}
                                            variant="outlined"
                                            onClick={() => {
                                              ionContentRef.current?.scrollToTop(
                                                500
                                              );

                                              map.current?.Map.current?.flyTo({
                                                center: {
                                                  lat: e.lat,
                                                  lng: e.lng,
                                                },
                                                zoom: 18,
                                                duration: 500,
                                              });
                                            }}
                                          >
                                            Zobacz punkt
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}

                                {/* </IonReorderGroup> */}

                                {/* .nk-tb-item */}
                              </div>
                              {/* .nk-tb-list */}
                            </div>
                            {/* .card-inner */}
                            {/* <div className="card-inner">
                          <ul className="pagination justify-content-center justify-content-md-start">
                            <li className="page-item">
                              <a className="page-link" href="#">
                                Prev
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <span className="page-link">
                                <em className="icon ni ni-more-h" />
                              </span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                6
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                7
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                Next
                              </a>
                            </li>
                          </ul>
                        </div> */}
                            {/* .card-inner */}
                          </div>
                          {/* .card-inner-group */}
                        </div>
                        {/* .card */}
                      </div>
                    </Paper>

                    {/* .nk-block */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
            {/* footer @s */}

            {/* footer @e */}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default SingleRoute;
