import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonAlert,
  useIonLoading,
  IonInput,
} from "@ionic/react";
import "./Home.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { useHistory } from "react-router";

import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";

import auth from "./../services/auth.service";

import { User } from "./../services/userProps";


const Home: React.FC = () => {
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const history = useHistory();

  const { setState, state } = useGlobalState();

  const { navigate } = useContext(NavContext);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [present] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();


  useEffect(() => {
    if (!state.hideMenu) {
      setState((prev) => ({
        ...prev,
        ...{ hideMenu: true },
      }));
    }
  }, []);

  return (
    <IonPage>
      {/* <CustomHeaderFade
        page={"Strona główna"}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      /> */}

      <IonContent
        id="home-page-controller"
        fullscreen={true}
        ref={ionContentRef}
        className={"background-lightgrey slide-transition-leave"}
      >
        <div className="nk-main ">
          {/* wrap @s */}
          <div className="nk-wrap nk-wrap-nosidebar">
            {/* content @s */}
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                {/* <div className="brand-logo pb-4 text-center">
          <a href="html/index.html" className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src="./images/logo.png" srcSet="./images/logo2x.png 2x" alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src="./images/logo-dark.png" srcSet="./images/logo-dark2x.png 2x" alt="logo-dark" />
          </a>
        </div> */}
                <div className="card card-bordered">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title">Logowanie</h4>
                        {/* <div className="nk-block-des">
                  <p>Access the DashLite panel using your email and passcode.</p>
                </div> */}
                      </div>
                    </div>
                    <form action="html/index.html">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Nazwa użytkownika
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <IonInput
                            className="form-control form-control-lg"
                            value={username}
                            onIonChange={(e) => setUsername(e.detail.value!)}
                            type="text"
                          ></IonInput>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Hasło
                          </label>
                          {/* <a className="link link-primary link-sm" href="html/pages/auths/auth-reset-v2.html">Forgot Code?</a> */}
                        </div>
                        <div className="form-control-wrap">
                          <IonInput
                            className="form-control form-control-lg"
                            value={password}
                            onIonChange={(e) => setPassword(e.detail.value!)}
                            type="password"
                          ></IonInput>
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-lg btn-primary btn-block"
                          onClick={async () => {
                            presentLoading({
                              spinner: "crescent",
                              message: "Logowanie...",
                              duration: 10000,
                            });

                            // navigate("/Home", "forward", "replace")

                            await auth
                              .login(username, password)
                              .then(async (response) => {
                                console.log(auth);

                                const data = response as User;

                                dismissLoading();

                                if (data.jwtToken) {

                                  const user = await auth.getCurrentUser();

                                  setState((prev) => ({
                                    ...prev,
                                    ...{
                                      role: user?.role,
                                    },
                                  }));

                                  history.push("/");
                                } else {
                                  present("Niepoprawne dane logowanie", [
                                    { text: "Zamknij" },
                                  ]);
                                }
                              })
                              .catch((exception) => {
                                dismissLoading();
                                present("Niepoprawne dane logowanie", [
                                  { text: "Zamknij" },
                                ]);
                              });
                          }}
                        >
                          Zaloguj się
                        </button>
                      </div>
                    </form>
                    {/* <div className="form-note-s2 text-center pt-4"> New on our platform? <a href="html/pages/auths/auth-register-v2.html">Create an account</a>
            </div>
            <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap"><span>OR</span></h6>
            </div>
            <ul className="nav justify-center gx-4">
              <li className="nav-item"><a className="nav-link" href="#">Facebook</a></li>
              <li className="nav-item"><a className="nav-link" href="#">Google</a></li>
            </ul> */}
                  </div>
                </div>
              </div>
            </div>
            {/* wrap @e */}
          </div>
          {/* content @e */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
