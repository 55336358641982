import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonReorderGroup,
  IonReorder,
  IonModal,
  IonProgressBar,
  useIonLoading,
  useIonViewWillEnter,
  useIonAlert,
} from "@ionic/react";
import "./Route.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { RouteComponentProps, useHistory, useParams } from "react-router";

import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";
import Header from "../components/Header";

import { v4 as uuidv4 } from "uuid";
import {
  ellipsisHorizontal,
  ellipsisHorizontalCircleOutline,
  searchOutline,
  trashBinOutline,
  trashOutline,
} from "ionicons/icons";

import { useDropzone } from "react-dropzone";
import api from "./../services/api";

import TextField from "@mui/material/TextField";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NavigationIcon from "@mui/icons-material/Navigation";
import RegionAutocomplete from "../components/Drivers/RegionAutocomplete";

type FileCustom = {};

const Route: React.FC<RouteComponentProps> = ({ match }) => {
  const [presentLoading, dismissLoading] = useIonLoading();

  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const history = useHistory();

  const [routes, setRoutes] = useState<RoutesResponse>();
  const [driverName, setDriverName] = useState<string>("");

  const [isFilesModalVisible, setIsFilesModalVisible] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let tempFiles = files;

      for (const _file of acceptedFiles) {
        tempFiles.push(_file);
      }

      setFiles([]);
      setFiles(tempFiles);
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const [region, setRegion] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("Wszystko");

  // useEffect(() => {
  //   if (drivers.length <= 0) {
  //     const tempDrivers = [];

  //     for (let i = 0; i < 60; i++) {
  //       tempDrivers.push("");
  //     }

  //     setDrivers(tempDrivers);
  //   }
  // }, []);

  const { setState, state } = useGlobalState();

  type RoutesResponse = {
    data: RouteDetail[];
  };

  type RouteDetail = {
    date: string;
    distance: number;
    points: number;
    route: RouteType[];
  };

  type RouteType = {
    routeId: string;
    driverId: string;
    deliveryDay: string;
    deliveryDate: string;
    created: string;
    region: string;
    daysDiffrence: number;
    successPoints: number;
    errorPoints: number;
    distance: number;

    active: boolean;
  };

  const getData = async () => {
    presentLoading({
      spinner: "crescent",
      message: "Pobieranie danych...",
      duration: 20000,
    });

    await api
      .get("routes/all", {
        params: {
          userId: userId,
        },
      })
      .then((response) => {
        setRoutes(response.data);
        console.log(response.data);
        setDriverName(response.data.userName);
      })
      .finally(() => {
        dismissLoading();
      });
  };

  type Params = {
    userId: string;
  };
  const { userId } = useParams<Params>();
  const [isLoading, setIsLoading] = useState(true);
  useIonViewDidLeave(() => {
    setIsLoading(true);
  });
  useEffect(() => {
    getData();

    setIsLoading(false);
  }, [userId]);

  const [presentAlert] = useIonAlert();

  return (
    <IonPage>
      {/* <CustomHeaderFade
        page={"Strona główna"}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      /> */}

      <IonContent
        id="drivers-page-controller"
        fullscreen={true}
        ref={ionContentRef}
        className={"background-lightgrey slide-transition-leave nk-main"}
      >
        <Header />

        {isLoading ? (
          <></>
        ) : (
          <div className="nk-wrap ">
            {/* main header @s */}

            {/* main header @e */}
            {/* content @s */}
            <div className="nk-content ">
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                      <div className="nk-block-between">
                        <div className="nk-block-head-content">
                          <h3 className="nk-block-title page-title">
                            Trasy pojazdu{" "}
                            <span style={{ textTransform: "uppercase" }}>
                              {driverName}
                            </span>
                          </h3>
                          {/* <div className="nk-block-des text-soft">
                          <p>You have total 2,595 users.</p>
                        </div> */}
                        </div>
                        {/* .nk-block-head-content */}
                        <div className="nk-block-head-content">
                          <div className="toggle-wrap nk-block-tools-toggle">
                            <a
                              href="#"
                              className="btn btn-icon btn-trigger toggle-expand mr-n1"
                              data-target="pageMenu"
                            >
                              <em className="icon ni ni-menu-alt-r" />
                            </a>
                            {/* <div
                            className="toggle-expand-content"
                            data-content="pageMenu"
                          >
                            <ul className="nk-block-tools g-3">
                              <li>
                                <a
                                  href="#"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-download-cloud" />
                                  <span>Export</span>
                                </a>
                              </li>
                              <li className="nk-block-tools-opt">
                                <div className="drodown">
                                  <a
                                    href="#"
                                    className="dropdown-toggle btn btn-icon btn-primary"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-plus" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <a href="#">
                                          <span>Add User</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Add Team</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span>Import User</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div> */}
                          </div>
                          {/* .toggle-wrap */}
                        </div>
                        {/* .nk-block-head-content */}
                      </div>
                      {/* .nk-block-between */}
                    </div>
                    {/* .nk-block-head */}

                    {routes?.data.map((e, i) => {
                      return (
                        <>
                          <Paper elevation={0} className="mb-5">
                            <div className="nk-block">
                              <div className="card card-bordered card-stretch">
                                <div className="card-inner-group">
                                  <div className="card-inner position-relative card-tools-toggle">
                                    <div className="card-title-group">
                                      <h3>{e.date}</h3>
                                    </div>
                                    {/* .card-title-group */}
                                    <div
                                      className="card-search search-wrap"
                                      data-search="search"
                                    >
                                      <div className="card-body">
                                        <div className="search-content">
                                          <a
                                            href="#"
                                            className="search-back btn btn-icon toggle-search"
                                            data-target="search"
                                          >
                                            <em className="icon ni ni-arrow-left" />
                                          </a>
                                          <input
                                            type="text"
                                            className="form-control border-transparent form-focus-none"
                                            placeholder="Search by user or email"
                                          />
                                          <button className="search-submit btn btn-icon">
                                            <em className="icon ni ni-search" />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    {/* .card-search */}
                                  </div>
                                  {/* .card-inner */}
                                  <div className="card-inner p-0">
                                    <div className="nk-tb-list nk-tb-ulist is-compact">
                                      <div className="nk-tb-item nk-tb-head">
                                        <div className="nk-tb-col">
                                          <span className="sub-text">
                                            Data dostarczenia
                                          </span>
                                        </div>
                                        <div className="nk-tb-col">
                                          <span className="sub-text">
                                            Utworzono
                                          </span>
                                        </div>
                                        {/* <div className="nk-tb-col">
                                  <span className="sub-text">ID</span>
                                </div>
                                <div className="nk-tb-col ">
                                  <span className="sub-text">Rola</span>
                                </div> */}
                                        <div className="nk-tb-col ">
                                          <span className="sub-text">
                                            Region
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col">
                                          <span className="sub-text">
                                            Przetworzone punkty
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col">
                                          <span className="sub-text">
                                            Dystans
                                          </span>
                                        </div>
                                        <div className="nk-tb-col "></div>
                                      </div>

                                      {/* <IonReorderGroup onIonItemReorder={(event) => event.detail.complete(true)} disabled={false} style={{ display: "table-row-group" }} > */}

                                      {e.route.map((s) => {
                                        return (
                                          <div className="nk-tb-item">
                                            {/* <div className="nk-tb-col nk-tb-col-check">
    <div className="custom-control custom-control-sm custom-checkbox notext">
      <input
        type="checkbox"
        className="custom-control-input"
        id={"uid" + i}
      />
      <label
        className="custom-control-label"
        htmlFor={"uid" + i}
      />
    </div>
  </div> */}
                                            <div className="nk-tb-col">
                                              <div className="user-card">
                                                <div className="user-name">
                                                  <div
                                                    className="tb-lead"
                                                    style={{
                                                      letterSpacing: "0.5px",
                                                      fontSize: "19px",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontWeight: 700,
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      {s.deliveryDate}
                                                    </span>

                                                    {s.daysDiffrence > 0 ? (
                                                      <span
                                                        style={{
                                                          color:
                                                            "var(--ion-color-medium)",
                                                        }}
                                                      >
                                                        {" "}
                                                        {s.daysDiffrence} dni
                                                        temu
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="nk-tb-col ">
                                              <span>{s.created}</span>
                                            </div>
                                            {/* <div className="nk-tb-col ">
      <span>{e.deliveryDay}</span>
    </div>
    <div className="nk-tb-col ">
      <span>{e.driverId}</span>
    </div> */}
                                            <div className="nk-tb-col ">
                                              <span>{s.region}</span>
                                            </div>

                                            <div className="nk-tb-col">
                                              {s.errorPoints > 0 ? (
                                                <>
                                                  <span className="tb-status text-danger">
                                                    {s.errorPoints}
                                                  </span>
                                                  /
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {s.successPoints > 0 ? (
                                                <span className="tb-status text-success">
                                                  {s.successPoints}
                                                </span>
                                              ) : (
                                                <></>
                                              )}
                                            </div>

                                            <div className="nk-tb-col">
                                              {s.distance}
                                              {" km"}
                                            </div>
                                            {/* <div className="nk-tb-col nk-tb-col-tools">
<ul className="nk-tb-actions gx-2">

<li>
  <div className="drodown">
    <a
      href="#"
      className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
      data-toggle="dropdown"
    >
      <em className="icon ni ni-more-h" />
    </a>

  </div>
</li>
</ul>
</div> */}
                                            {/* <div className="nk-tb-col">
    <IonReorder slot="center" />
  </div> */}
                                            <div className="nk-tb-col ">
                                              {/* <IonButton
        color="medium"
        fill="clear"
        style={{
          height: "unset",
          "--padding-start": 0,
          "--padding-end": 0,
        }}
      >
        <IonIcon
          style={{ fontSize: "30px" }}
          src={ellipsisHorizontal}
        />
      </IonButton> */}

<Button
                                                style={{
                                                  float: "right",
                                                }}
                                                variant="outlined"
                                                color="error"
                                                onClick={() => presentAlert({
                                                  subHeader: 'Czy na pewno chcesz usunąć trasę:',
                                                  message: s.deliveryDate + " | " + s.region,
                                                  buttons: [
                                                    {
                                                      text: 'Anuluj',
                                                      role: 'cancel'
                                                    },
                                                    {
                                                      text: 'Usuń',
                                                      cssClass: 'text-danger text-strong',
                                                      role: 'confirm',
                                                      handler: () => { 

                                                        presentLoading("Usuwanie trasy");
                                                        
                                                        api.delete("routes/remove/" + s.routeId).then(() => {
                                                          
                                                          getData();

                                                        }).catch(() => {

                                                          dismissLoading();

                                                        })

                                                      }
                                                    }
                                                  ],
                                                })}
                                              >
                                                &nbsp;
                                                <IonIcon src={trashOutline} />
                                              </Button>

                                              <Button
                                                style={{
                                                  float: "right",
                                                  marginRight: "15px",
                                                }}
                                                variant="outlined"
                                                onClick={() => {
                                                  history.push(
                                                    match.url + "/" + s.routeId
                                                  );
                                                }}
                                              >
                                                Pokaż całą trasę
                                              </Button>

                                              {s.active ? (
                                                <></>
                                              ) : (
                                                <Button
                                                  style={{
                                                    float: "right",
                                                    marginRight: "15px",
                                                  }}
                                                  color="warning"
                                                  variant="outlined"
                                                  onClick={() => {
                                                    presentLoading();

                                                    api
                                                      .patch(
                                                        "routes/activate/" +
                                                          s.routeId
                                                      )
                                                      .then((response) => {
                                                        getData();
                                                      })
                                                      .catch(() => {
                                                        dismissLoading();
                                                      });
                                                  }}
                                                >
                                                  Zaakceptuj trasę
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}

                                      <div className="nk-tb-item">
                                        <div
                                          className="nk-tb-col "
                                          style={{
                                            fontWeight: 800,
                                            color: "var(--ion-color-primary)",
                                            fontSize: "22px",
                                          }}
                                        >
                                          PODSUMOWANIE
                                        </div>
                                        <div className="nk-tb-col "></div>
                                        <div className="nk-tb-col "></div>
                                        <div
                                          className="nk-tb-col "
                                          style={{
                                            fontWeight: 800,
                                            color: "var(--ion-color-primary)",
                                            fontSize: "22px",
                                          }}
                                        >
                                          {e.points}
                                          {" punktów"}
                                        </div>
                                        <div
                                          className="nk-tb-col "
                                          style={{
                                            fontWeight: 800,
                                            color: "var(--ion-color-primary)",
                                            fontSize: "22px",
                                          }}
                                        >
                                          {e.distance}
                                          {" km"}
                                        </div>
                                      </div>
                                    </div>
                                    {/* .nk-tb-list */}
                                  </div>
                                  {/* .card-inner */}
                                  {/* <div className="card-inner">
                          <ul className="pagination justify-content-center justify-content-md-start">
                            <li className="page-item">
                              <a className="page-link" href="#">
                                Prev
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <span className="page-link">
                                <em className="icon ni ni-more-h" />
                              </span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                6
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                7
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                Next
                              </a>
                            </li>
                          </ul>
                        </div> */}
                                  {/* .card-inner */}
                                </div>
                                {/* .card-inner-group */}
                              </div>
                              {/* .card */}
                            </div>
                          </Paper>
                        </>
                      );
                    })}

                    {/* .nk-block */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
            {/* footer @s */}
            <div className="nk-footer">
              <div className="container-fluid">
                <div className="nk-footer-wrap">
                  <div className="nk-footer-copyright">
                    {" "}
                    © 2022 DashLite. Template by{" "}
                    <a href="https://softnio.com" target="_blank">
                      Softnio
                    </a>
                  </div>
                  <div className="nk-footer-links">
                    <ul className="nav nav-sm">
                      <li className="nav-item dropup">
                        <a
                          className="dropdown-toggle dropdown-indicator has-indicator nav-link"
                          data-toggle="dropdown"
                          data-offset="0,10"
                        >
                          <span>English</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <ul className="language-list">
                            <li>
                              <a href="#" className="language-item">
                                <span className="language-name">English</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <span className="language-name">Español</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <span className="language-name">Français</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <span className="language-name">Türkçe</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#region"
                          className="nav-link"
                        >
                          <em className="icon ni ni-globe" />
                          <span className="ml-1">Select Region</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* footer @e */}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Route;
