import {
  IonCol,
  IonGrid,
  IonRow,
  isPlatform,
  useIonLoading,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  RefObject,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Feature,
  GeoJSON,
  FeatureCollection,
  Geometry,
  Point,
  Position,
  BBox,
} from "geojson";
import mapboxgl, { GeoJSONSource, GeoJSONSourceRaw } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import "./Map.css";
import { Button, Divider, List, ListItem, Paper } from "@mui/material";

import { Storage } from "@capacitor/storage";
import api from "./../services/api";

import MapAutocomplete from "./Map/MapAutocomplete";
import axios from "axios";


type Props = {
  routeId: string[];
  // GetData: (loading: boolean) => Promise<void>;
  region: string;
};

type PointsRequest = {
  Id: number;
  Lng: number;
  Lat: number;
  Type: string;
};

export type MapRefProps = {
  // UpdateGeojson: () => Promise<void>;
  Map: React.MutableRefObject<mapboxgl.Map | null>;
  // geojsonPoints: React.MutableRefObject<any>;
};

const MapRead: ForwardRefRenderFunction<MapRefProps, Props> = (props, ref) => {
  

  const popup = useRef<mapboxgl.Popup | null>(null);

  const mapLayer = useRef<any | null>(null);

  const [showPolygons, setShowPolygons] = useState(false);
  const [editPolygons, setEditPolygons] = useState(false);

  const [_lng, _setLng] = useState(20.7810167);
  const [_lat, _setLat] = useState(52.2326063);

  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const draw = useRef<MapboxDraw | null>(null);
  const [lng, setLng] = useState(20.7810167);
  const [lat, setLat] = useState(52.2326063);
  const [zoom, setZoom] = useState(6);

  const geojsonLines = useRef<any>({});
  const geojsonPoints = useRef<any>({});
  let canvas: HTMLElement;
  let draggedPointId: string;
  const dictionary = useRef<any>({});

  useEffect(() => {
    if (_lng != 20.7810167 && _lng != 52.2326063) {
      map.current?.flyTo({
        center: {
          lat: _lat,
          lng: _lng,
        },
        zoom: 19,
        duration: 1000,
      });
    }
  }, [_lng, _lat]);

  const [presentLoading, dismissLoading] = useIonLoading();

  const Init = () => {
    if (props.routeId.length > 0 && props.region) {
      api
        // .get(
        //   "routes/geojson?" +
        //     props.routeId.map((e) => "ids=" + e + "&").join("")
        // )
        .get("routes/geojson", {
          params: {
            region: props.region,
          },
        })
        .then(async (response) => {
          const accessTokenRequest = await axios.get(
            "https://broccoli.z16.web.core.windows.net/MAPBOX_TOKEN.txt?stamp=" +
              new Date().getTime()
          );
          const accessToken = await accessTokenRequest.data;

          if (!map.current && mapContainer.current) {
            map.current = new mapboxgl.Map({
              accessToken: accessToken,
              fadeDuration: 0,
              container: mapContainer.current,
              style: "mapbox://styles/broccoliapi/cl31ma4fq000h14plphvz5ti9",
              attributionControl: false,
              // style: {
              //   version: 8,
              //   sources: {
              //     osm: {
              //       type: "raster",
              //       tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
              //       tileSize: 256,
              //     },
              //   },
              //   glyphs: "http://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
              //   layers: [
              //     {
              //       id: "osm",
              //       type: "raster",
              //       source: "osm",
              //     },
              //   ],
              // },
              bounds: [
                [response.data.points.sw.lng, response.data.points.sw.lat],
                [response.data.points.ne.lng, response.data.points.ne.lat],
              ],
              zoom: zoom,
            });

            canvas = map.current.getCanvasContainer();

            // let newModes: MapboxDraw.DrawCustomMode<any, any> = {
            //   toDisplayFeatures: () => {},
            //   onMouseMove: (e) => {

            //     console.log(e)

            //   }
            // };

            draw.current = new MapboxDraw({
              displayControlsDefault: false,
              // Select which mapbox-gl-draw control buttons to add to the map.
              controls: {
                polygon: false,
                trash: false,
              },
              userProperties: true,
              styles: [
                {
                  id: "gl-draw-polygon-fill-inactive",
                  type: "fill",
                  filter: [
                    "all",
                    ["==", "active", "false"],
                    ["==", "$type", "Polygon"],
                    ["!=", "mode", "static"],
                  ],
                  paint: {
                    "fill-color": [
                      "case",
                      ["==", ["get", "user_class_id"], 1],
                      "#00ff00",
                      ["==", ["get", "user_class_id"], 2],
                      "#0000ff",
                      "#0080ff",
                    ],
                    "fill-outline-color": "#000000",
                    "fill-opacity": 0.3,
                  },
                },
                {
                  id: "gl-draw-polygon-fill-active",
                  type: "fill",
                  filter: [
                    "all",
                    ["==", "active", "true"],
                    ["==", "$type", "Polygon"],
                  ],
                  paint: {
                    "fill-color": "#fbb03b",
                    "fill-outline-color": "#fbb03b",
                    "fill-opacity": 0.1,
                  },
                },
                {
                  id: "gl-draw-polygon-midpoint",
                  type: "circle",
                  filter: [
                    "all",
                    ["==", "$type", "Point"],
                    ["==", "meta", "midpoint"],
                  ],
                  paint: {
                    "circle-radius": 3,
                    "circle-color": "#fbb03b",
                  },
                },
                {
                  id: "gl-draw-polygon-stroke-inactive",
                  type: "line",
                  filter: [
                    "all",
                    ["==", "active", "false"],
                    ["==", "$type", "Polygon"],
                    ["!=", "mode", "static"],
                  ],
                  layout: {
                    "line-cap": "round",
                    "line-join": "round",
                  },
                  paint: {
                    "line-color": "#000000",
                    "line-width": 2,
                  },
                },
                {
                  id: "gl-draw-polygon-stroke-active",
                  type: "line",
                  filter: [
                    "all",
                    ["==", "active", "true"],
                    ["==", "$type", "Polygon"],
                  ],
                  layout: {
                    "line-cap": "round",
                    "line-join": "round",
                  },
                  paint: {
                    "line-color": "#fbb03b",
                    "line-dasharray": [0.2, 2],
                    "line-width": 2,
                  },
                },
                {
                  id: "gl-draw-line-inactive",
                  type: "line",
                  filter: [
                    "all",
                    ["==", "active", "false"],
                    ["==", "$type", "LineString"],
                    ["!=", "mode", "static"],
                  ],
                  layout: {
                    "line-cap": "round",
                    "line-join": "round",
                  },
                  paint: {
                    "line-color": "#3bb2d0",
                    "line-width": 2,
                  },
                },
                {
                  id: "gl-draw-line-active",
                  type: "line",
                  filter: [
                    "all",
                    ["==", "$type", "LineString"],
                    ["==", "active", "true"],
                  ],
                  layout: {
                    "line-cap": "round",
                    "line-join": "round",
                  },
                  paint: {
                    "line-color": "#fbb03b",
                    "line-dasharray": [0.2, 2],
                    "line-width": 2,
                  },
                },
                {
                  id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
                  type: "circle",
                  filter: [
                    "all",
                    ["==", "meta", "vertex"],
                    ["==", "$type", "Point"],
                    ["!=", "mode", "static"],
                  ],
                  paint: {
                    "circle-radius": 5,
                    "circle-color": "#fff",
                  },
                },
                {
                  id: "gl-draw-polygon-and-line-vertex-inactive",
                  type: "circle",
                  filter: [
                    "all",
                    ["==", "meta", "vertex"],
                    ["==", "$type", "Point"],
                    ["!=", "mode", "static"],
                  ],
                  paint: {
                    "circle-radius": 3,
                    "circle-color": "#fbb03b",
                  },
                },
                {
                  id: "gl-draw-point-point-stroke-inactive",
                  type: "circle",
                  filter: [
                    "all",
                    ["==", "active", "false"],
                    ["==", "$type", "Point"],
                    ["==", "meta", "feature"],
                    ["!=", "mode", "static"],
                  ],
                  paint: {
                    "circle-radius": 5,
                    "circle-opacity": 1,
                    "circle-color": "#fff",
                  },
                },
                {
                  id: "gl-draw-point-inactive",
                  type: "circle",
                  filter: [
                    "all",
                    ["==", "active", "false"],
                    ["==", "$type", "Point"],
                    ["==", "meta", "feature"],
                    ["!=", "mode", "static"],
                  ],
                  paint: {
                    "circle-radius": 3,
                    "circle-color": "#3bb2d0",
                  },
                },
                {
                  id: "gl-draw-point-stroke-active",
                  type: "circle",
                  filter: [
                    "all",
                    ["==", "$type", "Point"],
                    ["==", "active", "true"],
                    ["!=", "meta", "midpoint"],
                  ],
                  paint: {
                    "circle-radius": 7,
                    "circle-color": "#fff",
                  },
                },
                {
                  id: "gl-draw-point-active",
                  type: "circle",
                  filter: [
                    "all",
                    ["==", "$type", "Point"],
                    ["!=", "meta", "midpoint"],
                    ["==", "active", "true"],
                  ],
                  paint: {
                    "circle-radius": 5,
                    "circle-color": "#fbb03b",
                  },
                },
                {
                  id: "gl-draw-polygon-fill-static",
                  type: "fill",
                  filter: [
                    "all",
                    ["==", "mode", "static"],
                    ["==", "$type", "Polygon"],
                  ],
                  paint: {
                    "fill-color": "#404040",
                    "fill-outline-color": "#404040",
                    "fill-opacity": 0.1,
                  },
                },
                {
                  id: "gl-draw-polygon-stroke-static",
                  type: "line",
                  filter: [
                    "all",
                    ["==", "mode", "static"],
                    ["==", "$type", "Polygon"],
                  ],
                  layout: {
                    "line-cap": "round",
                    "line-join": "round",
                  },
                  paint: {
                    "line-color": "#404040",
                    "line-width": 2,
                  },
                },
                {
                  id: "gl-draw-line-static",
                  type: "line",
                  filter: [
                    "all",
                    ["==", "mode", "static"],
                    ["==", "$type", "LineString"],
                  ],
                  layout: {
                    "line-cap": "round",
                    "line-join": "round",
                  },
                  paint: {
                    "line-color": "#404040",
                    "line-width": 2,
                  },
                },
                {
                  id: "gl-draw-point-static",
                  type: "circle",
                  filter: [
                    "all",
                    ["==", "mode", "static"],
                    ["==", "$type", "Point"],
                  ],
                  paint: {
                    "circle-radius": 5,
                    "circle-color": "#404040",
                  },
                },
              ],
            });

            if (draw.current) {
              map.current.addControl(draw.current);

              map.current.on("draw.create", (e) => {
                if (draw.current) {
                  var rejon = prompt("Wpisz nazwę rejonu");

                  draw.current.setFeatureProperty(
                    e.features[0].id,
                    "name",
                    rejon
                  );
                }
              });
              map.current.on("draw.delete", (e) => console.log(e));
              map.current.on("draw.update", (e) => console.log(e));
            }

            // map.current.on('draw.modechange', (e) => {

            //     if(map.current)
            //     {

            //       if(e.mode == "draw_polygon")
            //       {
            //         map.current.setLayoutProperty('maine', 'visibility', 'none');
            //         map.current.setLayoutProperty('maine-outline', 'visibility', 'none');

            //         draw.set(TestGeojson as FeatureCollection);

            //         draw.changeMode("simple_select");

            //         setTimeout(() => {
            //           draw.changeMode("draw_polygon");
            //         }, 10);

            //       }
            //       else if(e.mode == "simple_select")
            //       {
            //         map.current.setLayoutProperty('maine', 'visibility', 'visible');
            //         map.current.setLayoutProperty('maine-outline', 'visibility', 'visible');

            //         (map.current.getSource("maine") as any).setData(TestGeojson as any);
            //         draw.deleteAll();

            //       }

            //     }

            // });

            map.current.on("load", () => {
              if (map.current) {
                // Add a data source containing GeoJSON data.
                map.current.addSource("maine", {
                  type: "geojson",
                });

                // Add a new layer to visualize the polygon.
                map.current.addLayer({
                  id: "maine",
                  type: "fill",
                  source: "maine", // reference the data source
                  layout: {
                    visibility: "none",
                  },
                  paint: {
                    "fill-color": "#0080ff", // blue color fill
                    "fill-opacity": 0.3,
                  },
                });
                // Add a black outline around the polygon.
                map.current.addLayer({
                  id: "maine-outline",
                  type: "line",
                  source: "maine",
                  layout: {
                    visibility: "none",
                  },
                  paint: {
                    "line-color": "#000000",
                    "line-width": 2,
                  },
                });

                map.current.on("mouseenter", "maine", (e) => {
                  if (map.current) {
                    map.current.getCanvas().style.cursor = "pointer";
                  }
                });

                map.current.on("mousemove", "maine", (e) => {
                  if (e.features && map.current) {
                    if (e.features[0].properties) {
                      if (popup.current) {
                        popup.current.setLngLat(e.lngLat);
                      }
                      else
                      {
                        popup.current = new mapboxgl.Popup({
                          closeOnClick: false,
                          closeButton: false,
                        })
                          .setLngLat(e.lngLat)
                          .setHTML(
                            `<div class="popup-hover">${e.features[0].properties.name}</div>`
                          )
                          .addTo(map.current);
                      }
                      
                    }
                  }
                });

                // Change the cursor back to a pointer
                // when it leaves the states layer.
                map.current.on("mouseleave", "maine", () => {
                  if (map.current) {
                    map.current.getCanvas().style.cursor = "";
                    if (popup.current) {
                      popup.current.remove();
                      popup.current = null;
                    }
                  }
                });
              }

              map.current?.loadImage(
                "https://broccolihot.z16.web.core.windows.net/markers/error.png",
                (error, image) => {
                  if (error) throw error;

                  map.current?.addImage(
                    "error-marker",
                    image as HTMLImageElement | ImageBitmap
                  );

                  map.current?.loadImage(
                    "https://broccolihot.z16.web.core.windows.net/markers/start.png",
                    (error, image) => {
                      if (error) throw error;

                      map.current?.addImage(
                        "start-marker",
                        image as HTMLImageElement | ImageBitmap
                      );

                      map.current?.loadImage(
                        "https://broccolihot.z16.web.core.windows.net/markers/end.png",
                        (error, image) => {
                          if (error) throw error;

                          map.current?.addImage(
                            "end-marker",
                            image as HTMLImageElement | ImageBitmap
                          );

                          map.current?.addSource("sourceLines", {
                            type: "geojson",
                            data: response.data.driverRoute.geoJSONMain,
                          });

                          map.current?.addSource("sourcePoints", {
                            type: "geojson",
                            data: response.data.driverRoute.geoJSONStops,
                          });

                          map.current?.addLayer({
                            id: "route",
                            type: "line",
                            source: "sourceLines",
                            layout: {
                              "line-join": "round",
                              "line-cap": "round",
                            },
                            paint: {
                              "line-color": ["get", "color"],
                              "line-width": 5,
                            },
                          });

                          console.log(response.data as any[]);

                          Promise.all(
                            (response.data.details as any[]).map(
                              (img) =>
                                new Promise((resolve, reject) => {
                                  if (img.color) {
                                    map.current?.loadImage(
                                      "https://broccolihot.z16.web.core.windows.net/markers/" +
                                        img.color +
                                        ".png",
                                      function (error, res) {
                                        map.current?.addImage(
                                          img.id,
                                          res as HTMLImageElement | ImageBitmap
                                        );
                                        resolve(null);
                                      }
                                    );
                                  } else {
                                    map.current?.loadImage(
                                      "https://broccolihot.z16.web.core.windows.net/markers/" +
                                        img.Color +
                                        ".png",
                                      function (error, res) {
                                        map.current?.addImage(
                                          img.Id,
                                          res as HTMLImageElement | ImageBitmap
                                        );
                                        resolve(null);
                                      }
                                    );
                                  }
                                })
                            )
                          ).then(() => {
                            // Add a symbol layer
                            map.current?.addLayer({
                              id: "points",
                              type: "symbol",
                              source: "sourcePoints",
                              layout: {
                                "icon-allow-overlap": true,
                                "icon-ignore-placement": true,
                                "icon-offset": [0, -20],
                                "icon-image": ["get", "driver"],
                                // get the title name from the source's "title" property
                                "text-field": ["get", "order"],
                                "text-font": ["Open Sans Bold"],
                                "text-offset": [0, -2],
                                "text-anchor": "top",
                              },
                              filter: ["==", "t", "N"],
                              paint: {
                                "text-color": "rgb(255, 255, 255)",
                              },
                            });

                            map.current?.addLayer({
                              id: "pointsError",
                              type: "symbol",
                              source: "sourcePoints",
                              layout: {
                                "icon-allow-overlap": true,
                                "icon-ignore-placement": true,
                                "icon-offset": [0, -20],
                                "icon-image": "error-marker",
                                // get the title name from the source's "title" property
                                "text-font": ["Open Sans Bold"],
                                "text-offset": [0, -2],
                                "text-anchor": "top",
                              },
                              filter: ["==", "t", "E"],
                              paint: {
                                "text-color": "rgb(255, 255, 255)",
                              },
                            });

                            map.current?.addLayer({
                              id: "start",
                              type: "symbol",
                              source: "sourcePoints",
                              layout: {
                                "icon-allow-overlap": true,
                                "icon-ignore-placement": true,
                                "icon-offset": [0, -20],
                                "icon-image": "start-marker",
                                // get the title name from the source's "title" property
                                "text-font": ["Open Sans Bold"],
                                "text-offset": [0, -2],
                                "text-anchor": "top",
                              },
                              filter: ["==", "t", "S"],
                              paint: {
                                "text-color": "rgb(255, 255, 255)",
                              },
                            });

                            map.current?.addLayer({
                              id: "end",
                              type: "symbol",
                              source: "sourcePoints",
                              layout: {
                                "icon-allow-overlap": true,
                                "icon-ignore-placement": true,
                                "icon-offset": [0, -20],
                                "icon-image": "end-marker",
                                // get the title name from the source's "title" property
                                "text-font": ["Open Sans Bold"],
                                "text-offset": [0, -2],
                                "text-anchor": "top",
                              },
                              filter: ["==", "t", "L"],
                              paint: {
                                "text-color": "rgb(255, 255, 255)",
                              },
                            });
                          });

                          map.current?.on("click", "points", (_e: any) => {
                            const features = _e.features[0];

                            const reponseData = _e.features[0].properties;

                            // Copy coordinates array.
                            const coordinates =
                              features.geometry.coordinates.slice();
                            const description =
                              "<div>" +
                              "<div><strong>Adres</strong></div>" +
                              "<div>" +
                              reponseData.address1 +
                              "</div><div class='mb-1'>" +
                              reponseData.address2 +
                              "</div>" +
                              "<div><strong>Telefon</strong></div>" +
                              "<div class='mb-1'>" +
                              reponseData.phone +
                              "</div>" +
                              "<div><strong>Diety</strong></div>" +
                              "<div>" +
                              reponseData.diets?.split(";").join("</br>") +
                              "</div>" +
                              "</div>";

                            // Ensure that if the map is zoomed out such that multiple
                            // copies of the feature are visible, the popup appears
                            // over the copy being pointed to.
                            while (
                              Math.abs(_e.lngLat.lng - coordinates[0]) > 180
                            ) {
                              coordinates[0] +=
                                _e.lngLat.lng > coordinates[0] ? 360 : -360;
                            }

                            if (map.current) {
                              new mapboxgl.Popup()
                                .setOffset([0, -33])
                                .setLngLat(coordinates)
                                .setHTML(description)
                                .addTo(map.current);
                            }
                          });

                          // map.current?.on("mouseenter", "points", () => {
                          //   if(map.current)
                          //   {
                          //     map.current.getCanvas().style.cursor = "pointer";
                          //   }
                          // });

                          // Change it back to a pointer when it leaves.
                          // map.current?.on("mouseleave", "points", () => {
                          //   if(map.current)
                          //   {
                          //     map.current.getCanvas().style.cursor = "";
                          //   }
                          // });

                          // map.current?.flyTo({
                          //   center: {
                          //     lat: response.data.initPoint.lat,
                          //     lng: response.data.initPoint.lng,
                          //   },
                          //   zoom: 12,
                          // });
                        }
                      );
                    }
                  );
                }
              );
            });
          }
        })
        .catch(() => {
          map.current?.remove();
          map.current = null;
          if (mapContainer.current) {
            mapContainer.current.innerHTML = "";
          }
        })
        .finally(() => {});
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      if (map.current == null) {
        await Init();
      }
    }, 250);
  }, [props.routeId]);

  useIonViewDidLeave(() => {
    setTimeout(() => {
      map.current?.remove();
      map.current = null;
      if (mapContainer.current) {
        mapContainer.current.innerHTML = "";
      }
    }, 750);
  });

  useIonViewWillEnter(() => {
    map.current?.remove();
    map.current = null;
    if (mapContainer.current) {
      mapContainer.current.innerHTML = "";
    }
  });

  useIonViewDidEnter(() => {
    setTimeout(() => {
      if (map.current == null) {
        Init();
      }
    }, 250);
  }, [props.routeId]);

  const _updateGeojson = async () => {
    const res = await api.get("routes/geojson", {
      data: props.routeId,
    });

    const response = await res;

    geojsonLines.current = JSON.parse(response.data.geoJSONMain);
    geojsonPoints.current = JSON.parse(response.data.geoJSONStops);

    (map.current?.getSource("points") as GeoJSONSource).setData(
      geojsonPoints.current
    );

    (map.current?.getSource("lines") as GeoJSONSource).setData(
      geojsonLines.current
    );

    dictionary.current = {};

    const element = document.getElementById("map-list");
    if (element) {
      element.innerHTML = "";
    }
  };

  useImperativeHandle(ref, () => ({
    // UpdateGeojson: _updateGeojson,
    Map: map,
    // geojsonPoints: geojsonPoints
  }));

  return (
    <>
      <div
        ref={mapContainer}
        className="map-container-read"
        style={{ height: "850px" }}
      />

      <div
        style={{
          display: "inline-flex",
          position: "absolute",
          zIndex: 1,
          right: "20px",
          top: "10px",
        }}
      >
        {editPolygons ? (
          <></>
        ) : (
          <Paper
            style={{
              marginRight: "15px",
            }}
            elevation={2}
          >
            <Button
              style={{
                height: "100%",
              }}
              variant={showPolygons ? "outlined" : "contained"}
              onClick={() => {
                if (map.current) {
                  setShowPolygons(!showPolygons);

                  if (
                    map.current.getLayoutProperty("maine", "visibility") ==
                    "none"
                  ) {

                    axios.get("https://broccolihot.z16.web.core.windows.net/map-data/layer.json?stamp=" + new Date().getTime()).then((response) => 
                    {
                      mapLayer.current = response.data;
                      (map.current?.getSource("maine") as GeoJSONSource).setData(mapLayer.current);
                    })

                    map.current.setLayoutProperty(
                      "maine",
                      "visibility",
                      "visible"
                    );
                    map.current.setLayoutProperty(
                      "maine-outline",
                      "visibility",
                      "visible"
                    );
                  } else {
                    map.current.setLayoutProperty(
                      "maine",
                      "visibility",
                      "none"
                    );
                    map.current.setLayoutProperty(
                      "maine-outline",
                      "visibility",
                      "none"
                    );
                  }
                }
              }}
            >
              {showPolygons ? "ukryj obszary" : "pokaż obszary"}
            </Button>
          </Paper>
        )}

        {showPolygons && editPolygons ? (
          <Paper
            style={{
              marginRight: "15px",
            }}
            elevation={2}
          >
            <Button
              style={{
                height: "100%",
              }}
              variant="contained"
              onClick={() => {
                if (map.current && draw.current) {
                  if (draw.current) {
                    if (draw.current.getMode() == "draw_polygon") {
                      draw.current.changeMode("simple_select");
                    } else {
                      draw.current.changeMode("draw_polygon");
                    }
                  }
                }
              }}
            >
              narysuj obszar
            </Button>
          </Paper>
        ) : (
          <></>
        )}

        {showPolygons && editPolygons ? (
          <Paper
            style={{
              marginRight: "50px",
            }}
            elevation={2}
          >
            <Button
              color="error"
              style={{
                height: "100%",
              }}
              variant="contained"
              onClick={() => {
                if (map.current && draw.current) {
                  if (draw.current) {
                    if (draw.current.getSelectedIds().length > 0) {
                      draw.current.delete(draw.current.getSelectedIds()[0]);
                    }
                  }
                }
              }}
            >
              usuń obszar
            </Button>
          </Paper>
        ) : (
          <></>
        )}

        {showPolygons ? (
          <Paper
            style={{
              marginRight: "15px",
            }}
            elevation={2}
          >
            <Button
              style={{
                height: "100%",
              }}
              color={editPolygons ? "success" : "primary"}
              variant="contained"
              onClick={() => {
                if (map.current && draw.current) {
                  if (editPolygons) {

                    presentLoading();

                    api.post("map-data/layer", {
                      data: JSON.stringify(draw.current.getAll())
                    }).then(() => {

                      axios.get("https://broccolihot.z16.web.core.windows.net/map-data/layer.json?stamp=" + new Date().getTime()).then((response) => 
                      {
                        


                        if(map.current && draw.current)
                        {

                          mapLayer.current = response.data;
                          (map.current?.getSource("maine") as GeoJSONSource).setData(mapLayer.current);

                          map.current.setLayoutProperty(
                            "maine",
                            "visibility",
                            "visible"
                          );
                          map.current.setLayoutProperty(
                            "maine-outline",
                            "visibility",
                            "visible"
                          );

                          draw.current.deleteAll();

                        }

                      })

                    }).finally(() => {

                      dismissLoading();

                    })


                  } else {
                    map.current.setLayoutProperty(
                      "maine",
                      "visibility",
                      "none"
                    );
                    map.current.setLayoutProperty(
                      "maine-outline",
                      "visibility",
                      "none"
                    );

                    draw.current.set(mapLayer.current as FeatureCollection);
                  }

                  setEditPolygons(!editPolygons);
                }
              }}
            >
              {editPolygons ? "zakończ edycję" : "edytuj obszary"}
            </Button>
          </Paper>
        ) : (
          <></>
        )}

        {editPolygons ? (
          <Paper
            style={{
              marginRight: "15px",
            }}
            elevation={2}
          >
            <Button
              style={{
                height: "100%",
              }}
              color="error"
              variant="contained"
              onClick={() => {
                if (map.current && draw.current) {
                  map.current.setLayoutProperty(
                    "maine",
                    "visibility",
                    "visible"
                  );
                  map.current.setLayoutProperty(
                    "maine-outline",
                    "visibility",
                    "visible"
                  );

                  draw.current.deleteAll();

                  setEditPolygons(!editPolygons);

                  setTimeout(() => {
                    if (draw.current) {
                      draw.current.changeMode("simple_select");
                    }
                  }, 10);
                }
              }}
            >
              odrzuć edycję
            </Button>
          </Paper>
        ) : (
          <></>
        )}

        <Paper elevation={2}>
          <MapAutocomplete setLng={_setLng} setLat={_setLat} map={map} />
        </Paper>
      </div>
    </>
  );
};

export default forwardRef(MapRead);
