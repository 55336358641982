import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonReorderGroup,
  IonReorder,
  IonModal,
  IonProgressBar,
  useIonLoading,
  useIonAlert,
  IonRow,
  IonCol,
} from "@ionic/react";
import "./Reports.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import { RouteComponentProps, useHistory } from "react-router";

import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";
import Header from "../components/Header";

import { v4 as uuidv4 } from "uuid";
import {
  colorPalette,
  colorPaletteOutline,
  ellipsisHorizontal,
  ellipsisHorizontalCircleOutline,
  lockClosed,
  searchOutline,
  trash,
} from "ionicons/icons";

import { useDropzone } from "react-dropzone";
import api from "./../services/api";

import TextField from "@mui/material/TextField";
import {
  Button,
  Chip,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Select,
  Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NavigationIcon from "@mui/icons-material/Navigation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RegionAutocomplete from "../components/Drivers/RegionAutocomplete";
import MapRead, { MapRefProps } from "../components/MapRead";

import Menu from "@mui/icons-material/Menu";

import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";

import { ChromePicker, SketchPicker } from "react-color";
import CsvList from "../components/CsvList";

import Main from "../components/DriverReports/Main";

type FileCustom = {};

const Drivers: React.FC<RouteComponentProps> = ({ match }) => {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const map = useRef<MapRefProps>(null);

  const [presentAlert] = useIonAlert();

  const [showMap, setShowMap] = useState(false);

  const [region, setRegion] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("Wszystko");

  const [presentLoading, dismissLoading] = useIonLoading();

  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const history = useHistory();

  const [drivers, setDrivers] = useState<Driver[]>([]);

  const [isFilesModalVisible, setIsFilesModalVisible] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [returnedPassword, setReturnedPassword] = useState("");

  useIonViewDidLeave(() => {
    setShowMap(false);
  });

  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: "name",
      headerName: "Użytkownik",
      // width: 200,
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      // valueGetter: (params: GridValueGetterParams) =>
      //   "Pojazd " + params.row.name,
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          <div
            className="user-avatar xs"
            style={{
              height: "37px",
              width: "37px",
              fontSize: "15px",
              paddingTop: "2px",
              background: "#" + params.row.color,
              borderColor: "#" + params.row.colorLight,
              borderWidth: "3px",
              borderStyle: "solid",
              letterSpacing: 0,
              marginRight: "10px",
              fontWeight: "500",
            }}
          >
            <span
              style={{
                maxWidth: "27px",
                overflow: "hidden",
              }}
            >
              {params.row.name.toUpperCase()}
            </span>
          </div>
          Pojazd&nbsp;
          <strong>{params.row.name}</strong>
        </>
      ),
    },
    {
      field: "password",
      headerName: "Hasło",
      // width: 150,
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "id",
      headerName: "ID",
      // width: 150,
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "region",
      headerName: "Region",
      // width: 250,
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Aktualna trasa",
      // width: 250,
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) =>
        params.row.status ? (
          <span className="tb-status text-success">
            Dodano
            {params.row.active ? (
              <></>
            ) : (
              <>
                <span className="text-warning" style={{ fontWeight: 700 }}>
                  {" "}
                  - wersja robocza
                </span>
              </>
            )}
          </span>
        ) : (
          <span className="tb-status text-danger">Brak</span>
        ),
    },
    {
      field: "eye",
      headerName: "Oko",
      // width: 100,
      minWidth: 100,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderHeader: (params: GridColumnHeaderParams) => (
        <IconButton
          color="primary"
          aria-label="pokaż zdjęcia"
          component="span"
          onClick={() => {
            const layers = [
              ["route"],
              ["points", ["==", "t", "N"]],
              ["pointsError", ["==", "t", "E"]],
              ["start", ["==", "t", "S"]],
              ["end", ["==", "t", "L"]],
            ];

            const driversTemp = drivers;

            const hideEverything = !driversTemp[0].hidden;

            for (const n of layers) {
              let tempArray: any = ["all"];
              if (n[1]) {
                tempArray.push(n[1]);
              }

              if (hideEverything) {
                for (const hiddenDriver of drivers) {
                  tempArray.push(["!=", "driver", hiddenDriver.id]);
                }
              }

              map.current?.Map.current?.setFilter(n[0] as string, tempArray);
            }

            let itemIndex = 0;

            let items = [...drivers];
            for (const item of items) {
              item.hidden = hideEverything;
              items[itemIndex] = item;
              itemIndex++;
            }
            setDrivers(items);
          }}
        >
          <VisibilityIcon />
        </IconButton>
      ),
      renderCell: (params: GridRenderCellParams<any>) => (
        <IconButton
          color="primary"
          aria-label="pokaż zdjęcie"
          component="span"
          onClick={() => {
            const layers = [
              ["route"],
              ["points", ["==", "t", "N"]],
              ["pointsError", ["==", "t", "E"]],
              ["start", ["==", "t", "S"]],
              ["end", ["==", "t", "L"]],
            ];

            if (params.row.hidden) {
              for (const n of layers) {
                let tempArray: any = ["all"];
                if (n[1]) {
                  tempArray.push(n[1]);
                }

                for (const hiddenDriver of drivers.filter((s) => s.hidden)) {
                  if (hiddenDriver.id != params.row.id) {
                    tempArray.push(["!=", "driver", hiddenDriver.id]);
                  }
                }

                map.current?.Map.current?.setFilter(n[0] as string, tempArray);
              }
            } else {
              for (const n of layers) {
                let tempArray: any = ["all"];
                if (n[1]) {
                  tempArray.push(n[1]);
                }
                tempArray.push(["!=", "driver", params.row.id]);

                for (const hiddenDriver of drivers.filter((s) => s.hidden)) {
                  tempArray.push(["!=", "driver", hiddenDriver.id]);
                }

                map.current?.Map.current?.setFilter(n[0] as string, tempArray);
              }
            }

            const driverIndex = drivers.findIndex((s) => s.id == params.row.id);
            let items = [...drivers];
            let item = {
              ...drivers[driverIndex],
            };
            item.hidden = !item.hidden;
            items[driverIndex] = item;
            setDrivers(items);
          }}
        >
          {params.row.hidden ? (
            <VisibilityOffIcon color="error" />
          ) : (
            <VisibilityIcon />
          )}
        </IconButton>
      ),
    },
    {
      field: "points",
      headerName: "Przetworzone punkty",
      flex: 1,
      minWidth: 250,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          <span
            className="tb-status text-success"
            style={{
              minWidth: "45px",
              display: "inline-block",
            }}
          >
            {params.row.successPoints}
          </span>

          <span
            className="tb-status text-warning"
            style={{
              minWidth: "40px",
              display: "inline-block",
            }}
          >
            {params.row.warningPoints}
          </span>

          <span
            className="tb-status text-danger"
            style={{
              minWidth: "40px",
              display: "inline-block",
            }}
          >
            {params.row.errorPoints}
          </span>
        </>
      ),
    },
    {
      field: "images",
      headerName: "Zdjęcia",
      flex: 1,
      minWidth: 250,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          <span
            className="tb-status text-primary"
            style={{
              minWidth: "45px",
              display: "inline-block",
              fontWeight: 700,
            }}
          >
            {params.row.donePoints}
          </span>

          <span
            className="tb-status text-primary"
            style={{
              minWidth: "40px",
              display: "inline-block",
            }}
          >
            {params.row.proccessingPoints}
          </span>

          <span
            className="tb-status text-medium"
            style={{
              minWidth: "40px",
              display: "inline-block",
            }}
          >
            {params.row.undonePoints}
          </span>
        </>
      ),
    },
    {
      field: "action",
      headerName: "",
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          <Button
            onClick={() => {
              const newWindow = window.open(
                "/route/" + params.row.id,
                "_blank",
                "noopener,noreferrer"
              );
              if (newWindow) newWindow.opener = null;
            }}
            variant="outlined"
            endIcon={<NavigationIcon />}
          >
            Trasy
          </Button>
          <Button
            color="info"
            style={{
              height: "36.5px",
              minWidth: "45px",
              padding: "0px",
              marginLeft: "15px",
            }}
            sx={{
              ".MuiButton-endIcon": {
                margin: 0,
              },
            }}
            // onClick={openPopover}
            onClick={(e) => {
              setColorDriverName(params.row.name);
              setColorDriverId(params.row.id);
              setColorValue("#" + params.row.color);

              openPopover(e);
            }}
            variant="contained"
            endIcon={<Menu />}
          />
        </>
      ),
    },

    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 110,
    //   editable: true,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    // },
  ];

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let tempFiles = files;

      for (const _file of acceptedFiles) {
        tempFiles.push(_file);
      }

      setFiles([]);
      setFiles(tempFiles);
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  // useEffect(() => {
  //   if (drivers.length <= 0) {
  //     const temp<Drivers> = [];

  //     for (let i = 0; i < 60; i++) {
  //       tempDrivers.push("");
  //     }

  //     setDrivers(tempDrivers);
  //   }
  // }, []);

  const { setState, state } = useGlobalState();

  type DriverPoint = {
    postCode: string;
    city: string;
    street: string;
    houseNumber: string;
  };

  type Driver = {
    id: string;
    role: string;
    name: string;
    routeId: string;
    region: string;
    status: boolean;
    password: string;
    color: string;
    colorLight: string;
    startPoint: DriverPoint;
    endPoint: DriverPoint;
    successPoints: number;
    warningPoints: number;
    errorPoints: number;

    hidden: boolean;

    donePoints: number;
    proccessingPoints: number;
    undonePoints: number;

    active: boolean;
  };

  const getData = async () => {
    presentLoading({
      spinner: "crescent",
      message: "Pobieranie danych...",
      duration: 20000,
    });

    await api
      .get("drivers", {
        params: {
          region: region,
          search: search,
          status: status,
        },
      })
      .then((response) => {
        if (response.data) {
          setDrivers(response.data);
        }
      })
      .finally(() => {
        dismissLoading();
      });
  };

  useEffect(() => {
    getData();
  }, [region, status]);

  const [colorValue, setColorValue] = useState<string>();
  const [colorDriverName, setColorDriverName] = useState<string>("");
  const [colorDriverId, setColorDriverId] = useState<string>("");

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  const memoDataGrid = useMemo(() => {
    return (
      <DataGridPro
        isRowSelectable={(params: GridRowParams) =>
          !!params.row.routeId && !params.row.active
        }
        onRowSelectionModelChange={(newSelectionModel, details) => {
          setSelectionModel(newSelectionModel);
        }}
        rowSelectionModel={selectionModel}
        sx={{
          fontSize: "16px",
        }}
        rows={drivers}
        columns={columns}
        hideFooter
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
        disableVirtualization
        // experimentalFeatures={{ newEditingApi: true }}
      />
    );
  }, [drivers, selectionModel]);

  return (
    <IonPage>
      <IonContent
        id="reports-page-controller"
        fullscreen={true}
        ref={ionContentRef}
        className={"background-lightgrey slide-transition-leave nk-main"}
      >
        <Header />

        <Main />
      </IonContent>
    </IonPage>
  );
};

export default Drivers;
